import {ERRORS_ADD, ERRORS_CLEAR, ERRORS_REMOVE} from './Action.types'
import {ErrorsState} from './Errors.types'

export const initialErrorsState: ErrorsState = {
  errors: []
}

export const errorsReducer = (state = initialErrorsState, action) => {
  switch (action.type) {
    case ERRORS_ADD:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.key]: {
            translationKey: action.translationKey,
            callback: action.callback,
            callbackTranslationKey: action.callbackTranslationKey
          }
        }
      }

    case ERRORS_REMOVE:
      // eslint-disable-next-line no-case-declarations, @typescript-eslint/no-unused-vars
      const {[action.key]: removedError, ...restErrors} = state.errors
      return {
        ...state,
        errors: restErrors
      }

    case ERRORS_CLEAR:
      return {
        ...state,
        errors: {}
      }

    default:
      return state
  }
}
