import {AxiosResponse} from 'axios'
import qs from 'qs'

import {Delivery} from '../../Pages/Finance/Invoices/Delivery.types'
import {Order} from '../../Pages/Order/Order.types'
import {checkIsActionRequired} from '../../util/action.helper'

import {
  DELIVERIES_ORDERS_FETCH_FAILURE,
  DELIVERIES_ORDERS_FETCH_REQUEST,
  DELIVERIES_ORDERS_FETCH_SUCCESS,
  OrderDeliveriesActionTypes
} from './Action.types'

export const fetchDeliveriesRequest = (props: any): OrderDeliveriesActionTypes => ({
  type: DELIVERIES_ORDERS_FETCH_REQUEST,
  ...props
})

export const fetchDeliveriesFailure = (props: any, error: any): OrderDeliveriesActionTypes => ({
  type: DELIVERIES_ORDERS_FETCH_FAILURE,
  payload: error,
  error: true,
  ...props
})

export const fetchDeliveriesSuccess = (
  props: any,
  deliveries: Delivery[]
): OrderDeliveriesActionTypes => ({
  type: DELIVERIES_ORDERS_FETCH_SUCCESS,
  payload: deliveries,
  ...props
})

export const fetchDeliveriesForOrder =
  (
    order: Order,
    customerId: string,
    isGroupedDeliveries,
    isFilteringOutCancelledDeliveries = false,
    isPumpServiceIncluded
  ) =>
  async (dispatch, getState, {api}) => {
    const params = {
      orderId: order.orderId,
      ordersIds: order.orderIds,
      customerId,
      sortedBy: 'deliveryNumber desc',
      ...(isFilteringOutCancelledDeliveries ? {excludeCancelledDeliveries: true} : {}),
      ...(isPumpServiceIncluded ? {includePumpService: true} : {})
    }
    dispatch(fetchDeliveriesRequest(params))

    const endPoint = isGroupedDeliveries ? '/deliveries/forOrders' : '/deliveries'

    try {
      const response: AxiosResponse<Delivery[]> = await api.get(endPoint, {
        params,
        paramsSerializer: (params) => {
          return qs.stringify(params, {arrayFormat: 'repeat'})
        }
      })

      dispatch(fetchDeliveriesSuccess(params, response.data))
    } catch (err: any) {
      err.action = checkIsActionRequired(dispatch, fetchDeliveriesForOrder, err, [order.orderId])
      dispatch(fetchDeliveriesFailure(params, err))
    }
  }

export const fetchDelivery =
  (params: any, trackingEvent: () => void) =>
  async (dispatch, getState, {api}) => {
    dispatch(fetchDeliveriesRequest(params))

    try {
      const response: AxiosResponse<Delivery[]> = await api.get('/deliveries', {params})
      await dispatch(fetchDeliveriesSuccess(params, response.data))
    } catch (err: any) {
      trackingEvent()
      err.action = checkIsActionRequired(dispatch, fetchDelivery, err, [...params])
      await dispatch(fetchDeliveriesFailure(params, err))
    }
  }

export const fetchDeliveryByDeliveryNumber =
  (
    deliveryNumber: string,
    customerId: string,
    isPumpServiceIncluded = false,
    isFilteringOutCancelledDeliveries = false,
    useHaulierView = false,
    trackingEvent: () => void
  ) =>
  async (dispatch) => {
    await dispatch(
      fetchDelivery(
        {
          deliveryNumber,
          ...(useHaulierView ? {haulierId: customerId} : {customerId: customerId}),
          ...(isFilteringOutCancelledDeliveries ? {excludeCancelledDeliveries: true} : {}),
          ...(isPumpServiceIncluded ? {includePumpService: true} : {})
        },
        trackingEvent
      )
    )
  }

export const fetchDeliveryByCustomerDeliveryNumber =
  (
    customerDeliveryNumber: string,
    customerId: string,
    isPumpServiceIncluded = false,
    isFilteringOutCancelledDeliveries = false,
    trackingEvent: () => void
  ) =>
  async (dispatch) => {
    await dispatch(
      fetchDelivery(
        {
          customerDeliveryNumber,
          customerId,
          ...(isFilteringOutCancelledDeliveries ? {excludeCancelledDeliveries: true} : {}),
          ...(isPumpServiceIncluded ? {includePumpService: true} : {})
        },
        trackingEvent
      )
    )
  }
