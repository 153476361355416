import {TFunction} from 'i18next'

import {Step} from '../../Invite.types'

export const getTitleMessage = (t: TFunction, step: Step) => {
  switch (step) {
    case Step.LOADING || Step.INIT:
      return [t('invite.receive.inProgress'), t('invite.receive.giveYouNewPermissions')]
    case Step.SUCCESS:
      return [t('invite.receive.successful'), t('invite.receive.youHaveReceivedNewPermissions')]
    case Step.ERROR:
    // fall through

    case Step.ERROR_NO_CHANGE:
    // fall through

    case Step.ERROR_COUNTRY_MISMATCH:
    // fall through

    case Step.ERROR_EXPIRED:
    // fall through

    case Step.ERROR_DEACTIVATED:
    // fall through

    case Step.ERROR_WRONG_LINK:
    // fall through

    case Step.ERROR_FROM_API:
    // fall through

    case Step.ERROR_LIMIT_REACHED:
    // fall through

    case Step.ERROR_CUSTOMERS_LIST_IS_EMPTY:
      return [t('invite.receive.unsuccessful'), t('invite.receive.giveYouNewPermissions')]

    case Step.ERROR_DATASCOPE_CUSTOMER_IDS_EMPTY:
      return [t('invite.receive.unsuccessful'), t('invite.receive.giveYouNewPermissions')]

    default:
      return [t('invite.receive.inProgress'), t('invite.receive.giveYouNewPermissions')]
  }
}
