import {Customer} from './Customers.types'

export const CUSTOMERS_FETCH_REQUEST = 'CUSTOMERS_FETCH_REQUEST'
export const CUSTOMERS_FETCH_RESPONSE = 'CUSTOMERS_FETCH_RESPONSE'
export const CUSTOMERS_FETCH_FAILURE = 'CUSTOMERS_FETCH_FAILURE'

export const CUSTOMER_BY_CUSTOMER_NUMBER_FETCH_PENDING = 'CUSTOMER_BY_CUSTOMER_NUMBER_FETCH_PENDING'
export const CUSTOMER_BY_CUSTOMER_NUMBER_FETCH_SUCCESS = 'CUSTOMER_BY_CUSTOMER_NUMBER_FETCH_SUCCESS'
export const CUSTOMER_BY_CUSTOMER_NUMBER_FETCH_FAILURE = 'CUSTOMER_BY_CUSTOMER_NUMBER_FETCH_FAILURE'

export const CUSTOMER_SET_SEARCH_STRING = 'CUSTOMER_SET_SEARCH_STRING'
export const CUSTOMER_SET_SEARCH_CUSTOMER = 'CUSTOMER_SET_SEARCH_CUSTOMER'

export const SELECT_CUSTOMER_FILTER = 'SELECT_CUSTOMER_FILTER'
export const SET_CUSTOMER_FILTER = 'SET_CUSTOMER_FILTER'
export const REMOVE_CUSTOMER_FILTER = 'REMOVE_CUSTOMER_FILTER'
export const CUSTOMER_FILTER = 'customer'

export interface FetchCustomersRequestAction {
  type: typeof CUSTOMERS_FETCH_REQUEST
}

export interface FetchCustomersFailureAction {
  type: typeof CUSTOMERS_FETCH_FAILURE
  error: any
}

export interface FetchCustomersSuccessAction {
  type: typeof CUSTOMERS_FETCH_RESPONSE
  response: Customer[]
}

export interface FetchCustomerByCustomerNumberRequestAction {
  type: typeof CUSTOMER_BY_CUSTOMER_NUMBER_FETCH_PENDING
}

export interface FetchCustomerByCustomerNumberFailureAction {
  type: typeof CUSTOMER_BY_CUSTOMER_NUMBER_FETCH_FAILURE
  error: any
}

export interface FetchCustomerByCustomerNumberSuccessAction {
  type: typeof CUSTOMER_BY_CUSTOMER_NUMBER_FETCH_SUCCESS
  response: Customer[]
}

export interface SelectCustomerFilter {
  type: typeof SELECT_CUSTOMER_FILTER
  customerId: string
}

export interface SetCustomerFilter {
  type: typeof SET_CUSTOMER_FILTER
  customer: Customer
}

export interface RemoveCustomerFilter {
  type: typeof REMOVE_CUSTOMER_FILTER
}

export interface SetCustomerSearchString {
  type: typeof CUSTOMER_SET_SEARCH_STRING
  searchString: string
}

export interface SetCustomerSearchCustomer {
  type: typeof CUSTOMER_SET_SEARCH_CUSTOMER
}

export type CustomerActionTypes =
  | FetchCustomersRequestAction
  | FetchCustomersFailureAction
  | FetchCustomersSuccessAction
  | FetchCustomerByCustomerNumberRequestAction
  | FetchCustomerByCustomerNumberFailureAction
  | FetchCustomerByCustomerNumberSuccessAction
  | SelectCustomerFilter
  | SetCustomerFilter
  | RemoveCustomerFilter
  | SetCustomerSearchString
  | SetCustomerSearchCustomer

export interface CustomerStateType {
  customers: Customer[]
  selectedCustomer: Customer | null
  isFetching: boolean
  searchString: string
  customerChoosen: boolean
  error: any
}
