import {trackEventWithBrowserProps} from '@hconnect/common/logging/Analytics'
import React from 'react'

export const handleCopyLink = async (
  e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  setIsSnackbarOpen: (isOpened: boolean) => void,
  loggedInUserId: string,
  link: string,
  roleRequestId?: string
) => {
  e.stopPropagation()
  e.preventDefault()
  if (link) {
    await navigator.clipboard.writeText(link)
    setIsSnackbarOpen(true)

    trackEventWithBrowserProps('inviteCopyLink', {
      product: 'hub',
      date: new Date().toISOString(),
      roleRequestId,
      userId: loggedInUserId
    })
  }
}
