// Generated by https://quicktype.io

export interface Payer {
  /**
   * Unique Identifier for the payer across backend systems
   */
  payerId: string
  /**
   * descriptive name of the payer
   */
  payerName: string
  /**
   * Reference to payer account number
   */
  payerNumber?: null | string
  /**
   * reference to country of payer
   */
  countryId?: null | string
  /**
   * reference to the payer orgUnit
   */
  orgUnitId?: null | string
  /**
   * added by @Igor - NOT FROM API
   */
  parentCustomerId?: string | null
}
