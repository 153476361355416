import {Typography} from '@hconnect/uikit'
import {Box} from '@mui/material'
import {TFunction} from 'i18next'
import React from 'react'

import {Site} from '../types'

const toAddress = (site: Site, linebreak = '\n') =>
  `${site.street ? site.street + linebreak : ''} ${site.city}, ${site.postalCode}`
interface SelectItemType {
  site: Site
  t: TFunction
  onLight?: boolean
}
export const SelectItem: React.FC<SelectItemType> = ({site, t, onLight}) => {
  const {siteNumber, siteId} = site
  const id = siteNumber && siteNumber !== '0' ? siteNumber : undefined
  return (
    <Box aria-label={`Site filter option ${site.siteName}`}>
      <Typography component="div" variant="body1" color={onLight ? 'textPrimary' : 'textSecondary'}>
        {site.siteName}
      </Typography>
      {siteId !== '-1' ? (
        <Typography
          variant="caption"
          color={onLight ? 'textPrimary' : 'textSecondary'}
          component="div"
          dangerouslySetInnerHTML={{
            __html: toAddress(site, '<br />')
          }}
        />
      ) : null}
      {id && (
        <Typography
          component="div"
          variant="caption"
          customColor={onLight ? 'textPrimarySoft' : 'textSecondarySoft'}
        >
          {t('order.orderDropdown.id')} {id}
        </Typography>
      )}
    </Box>
  )
}
