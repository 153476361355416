import {Delivery} from '../../Pages/Finance/Invoices/Delivery.types'
import {AppState} from '../../Root.store'

import {DeliveriesStateType} from './Action.types'

export const selectDeliveries = (state: AppState): DeliveriesStateType => state.finance.deliveries

export const selectDelivery = (state: AppState, deliveryId: string) =>
  selectDeliveries(state).byId[deliveryId]

export const selectAllDeliveries = (state: AppState) =>
  selectDeliveries(state).allIds.map((deliveryId: string) => selectDelivery(state, deliveryId))

export const selectDeliveriesByCustomerDeliveryNumber = (
  state: AppState,
  customerDeliveryNumber: string,
  customerId: string
) => {
  const deliveries = selectAllDeliveries(state)
  return deliveries.filter(
    (delivery: Delivery) =>
      ((delivery.customerDeliveryNumber &&
        delivery.customerDeliveryNumber === customerDeliveryNumber) ||
        delivery.deliveryNumber === customerDeliveryNumber) &&
      delivery.customerId === customerId
  )
}

export const selectDeliveriesByDeliveryNumber = (state: AppState, deliveryNumber: string) => {
  const deliveries = selectAllDeliveries(state)
  return deliveries.filter((delivery: Delivery) => delivery.deliveryNumber === deliveryNumber)
}

export const selectLineItemDocumentByType = (state: AppState, deliveryId: string, type: string) => {
  const delivery = selectDelivery(state, deliveryId)
  if ('links' in delivery && Array.isArray(delivery.links)) {
    return delivery.links.find((link) => link.rel === 'documents' && (link.type as string) === type)
  }
  return undefined
}
