import {Role} from '../../../../Roles/Roles.types'
import {Materials, QCData} from '../hooks'
import {MaterialCertificate} from '../types'

export const getTableItems = (
  selectedPlantId: string,
  materialCertificateCollection?: MaterialCertificate[]
): Materials[] => {
  if (!selectedPlantId || !materialCertificateCollection) {
    return []
  }
  const materialsForPlant = materialCertificateCollection.filter(
    (cer) => cer.plantId === selectedPlantId
  )
  return materialsForPlant || []
}

export const getUniquePlantsWithPermission = (
  rolesState: Role[],
  uniqueCertsByPlantId: MaterialCertificate[]
): MaterialCertificate[] => {
  // Get all plantIds contained in the relevant user roles
  const plantsFromPlantManagerRole = rolesState
    .filter((role) => role.roleType === 'PLANT_MANAGER')
    .map((role) => role.dataScope.orgUnitId)
    .filter((plantId): plantId is string => typeof plantId === 'string')

  const plantsFromQCSupervisorRole = rolesState
    .filter((role) => role.roleType === 'QC_SUPERVISOR')
    .flatMap((role) => role.dataScope.plantIds)
    .filter((plantId): plantId is string => typeof plantId === 'string')

  // Merge all found plantIds
  const plantsUserIsAllowedToChange = [...plantsFromPlantManagerRole, ...plantsFromQCSupervisorRole]

  // Find overlap in the unique available plants
  return uniqueCertsByPlantId
    .filter((cert) => plantsUserIsAllowedToChange.includes(cert.plantId))
    .sort()
}

export const getEmptyMessage = (loading: boolean, t: any) => (loading ? t('loading') : t('noData'))

export const getTextBoxHelperText = (
  isError: boolean,
  isSuccess: boolean,
  t: any
): string | null => {
  if (!isError && !isSuccess) {
    return null
  }
  if (isError) {
    return t('certificate.editCertificate.errorText')
  }

  return t('certificate.editCertificate.successText')
}

export const compareCertificatesByPlantName = (a: QCData, b: QCData) => {
  if (a.plantName < b.plantName) {
    return -1
  }
  if (a.plantName > b.plantName) {
    return 1
  }
  return 0
}
