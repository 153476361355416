/* eslint-disable max-statements */
import {AxiosRequestConfig, AxiosResponse} from 'axios'

import {trackEvent} from '../../common/analytics'
import {errorKeyFromRequestError} from '../../ErrorMap'
import {Order} from '../../Pages/Order/Order.types'
import {RequestCanceller, getErrorMessage} from '../../util/action.helper'
import {errorsAdd, errorsClear} from '../Errors'
import {
  clearDefaultFiltersAbstract,
  clearFiltersAbstract,
  removeDefaultFilterAbstract,
  removeFilterAbstract,
  setDefaultFilterAbstract,
  setFilterAbstract
} from '../Filters'

import {
  ORDERS,
  ORDERS_CLEAR,
  ORDERS_FETCH_FAILURE,
  ORDERS_FETCH_REQUEST,
  ORDERS_FETCH_SUCCESS,
  ORDERS_LOAD_MORE,
  ORDERS_SET_RESULTS_PER_PAGE,
  ORDERS_SET_SORT_KEY,
  OrdersActionTypes
} from './Action.types'

export const setFilter = (filterName, filterValue) =>
  setFilterAbstract(ORDERS, filterName, filterValue)
export const setDefaultFilter = (filterName, filterValue) =>
  setDefaultFilterAbstract(ORDERS, filterName, filterValue)
export const removeFilter = (filterName) => removeFilterAbstract(ORDERS, filterName)
export const removeDefaultFilter = (filterName) => removeDefaultFilterAbstract(ORDERS, filterName)
export const clearFilters = () => clearFiltersAbstract(ORDERS)
export const clearDefaultFilters = () => clearDefaultFiltersAbstract(ORDERS)

const Canceller = RequestCanceller()

export const clear = (): OrdersActionTypes => ({
  type: ORDERS_CLEAR
})

export const setSortKey = (key: string): OrdersActionTypes => ({
  type: ORDERS_SET_SORT_KEY,
  payload: key
})

export const loadMore = (): OrdersActionTypes => ({
  type: ORDERS_LOAD_MORE
})
export const setOrdersResultsPerPage = (amount: number): OrdersActionTypes => ({
  type: ORDERS_SET_RESULTS_PER_PAGE,
  payload: amount
})

export const fetchOrdersRequest = (params: any): OrdersActionTypes => ({
  type: ORDERS_FETCH_REQUEST,
  params
})

export const fetchOrdersSuccess = (
  params: any,
  payload: Order[],
  showLoadMore: boolean
): OrdersActionTypes => ({
  type: ORDERS_FETCH_SUCCESS,
  params,
  payload,
  showLoadMore,
  receivedAt: new Date()
})

export const fetchOrdersFailure = (error: any): OrdersActionTypes => ({
  type: ORDERS_FETCH_FAILURE,
  error: true,
  payload: error
})

// eslint-disable-next-line consistent-return
export const fetchOrders =
  (customFilters = {}, isGroupedDeliveries: boolean, lookup?: any, analyticsId?: string) =>
  async (dispatch, getState, {api}) => {
    dispatch(errorsClear())
    const resolvedEndPoint = isGroupedDeliveries
      ? 'OrdersMiddleware/getgroupedorders'
      : 'order-items'

    // inform UI that we are going to load data now
    const filters: any = {...customFilters}

    // always expand on deliveryProgress and deliveryDocuments
    filters.expand = filters.expand
      ? `${filters.expand},deliveryProgress,deliveryDocuments`
      : 'deliveryProgress,deliveryDocuments'

    dispatch(fetchOrdersRequest(filters))
    if (Object.keys(filters).includes('orderId')) {
      try {
        Canceller.cancel('Request canceled by the next request.')
        const {orderId, ...params} = filters
        const response: AxiosResponse<Order> = await api
          .get(`order-items/${orderId}`, {
            // params: {...filters, customerId, expand}
            params
          })
          .catch((thrown: any) => {
            if (!Canceller.isCancel(thrown)) {
              throw thrown
            }
          })
        if (response) {
          const {data} = response
          dispatch(fetchOrdersSuccess(filters, [data], false))
          return {...response, data}
        }
      } catch (err) {
        const errorKey = errorKeyFromRequestError(err)
        dispatch(errorsAdd(getErrorMessage('order', errorKey, dispatch, fetchOrders, filters)))
        dispatch(fetchOrdersFailure(err))
      }
    } else {
      try {
        const params = filters
        Canceller.cancel('Request canceled by the next request.')
        const requestConfig: AxiosRequestConfig = {
          params,
          cancelToken: Canceller.getToken()
        }
        const response: AxiosResponse<Order[]> = await api
          .get(resolvedEndPoint, requestConfig)
          .catch((thrown: any) => {
            if (!Canceller.isCancel(thrown)) {
              throw thrown
            }
          })

        if (response) {
          const {data, headers} = response
          const totalCount = headers['total-count'] ? +headers['total-count'] : 0
          const limit = getState().order.orders.limit
          const payload = data.slice(0, limit)
          const showLoadMore = data.length > payload.length
          if (lookup && totalCount === 0) {
            trackEvent('hubLookupFailed', {
              product: 'hub',
              lookupCategory: lookup?.entity,
              lookupValue: lookup?.value,
              lookupSource: 'orders',
              customerId: filters?.customerId,
              analyticsId
            })
          }
          dispatch(fetchOrdersSuccess(params, payload, showLoadMore))
          return response
        }
      } catch (err) {
        if (lookup) {
          trackEvent('hubLookupFailed', {
            product: 'hub',
            lookupCategory: lookup?.entity,
            lookupValue: lookup?.value,
            lookupSource: 'orders',
            customerId: filters?.customerId,
            analyticsId
          })
        }
        const errorKey = errorKeyFromRequestError(err)
        dispatch(errorsAdd(getErrorMessage('order', errorKey, dispatch, fetchOrders, filters)))
        dispatch(fetchOrdersFailure(err))
      }
    }
  }
