import {Typography} from '@hconnect/uikit'
import {Popper, TextField, MenuItem} from '@material-ui/core'
import {Box, CircularProgress, MenuList} from '@mui/material'
import {UseQueryResult} from '@tanstack/react-query'
import React from 'react'

import {useStylesV4, useStylesV5} from '../styles'
import {isT} from '../utils'

export interface UseTypeaheadType<T = any, S = any> {
  fetchHook: (args: S) => UseQueryResult<Array<T>, unknown>
  args: S
  allOptions?: string
  optionName: string
  optionId: string
}
export const useTypeahead = <T, S>({
  fetchHook,
  args,
  allOptions,
  optionName,
  optionId
}: UseTypeaheadType<T, S>) => {
  const {data, isFetching, isError} = fetchHook(args)
  const options = allOptions
    ? [
        {
          label: allOptions,
          id: -1,
          [optionName]: allOptions,
          [optionId]: '-1'
        }
      ]
    : []

  return {
    options: data ? [...options, ...data] : options,
    isFetching,
    isError
  }
}
interface TypeaheadType<T = any, S = any> extends UseTypeaheadType<T, S> {
  label: string
  value?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  selectedItem?: T
  renderOption?: (option: T) => React.ReactNode
  onChangeDropdown: (option: T | undefined) => void
  searchAgain: (searchTerm?: string) => void
  dataTestId?: string
}

export const Typeahead = <T, S>({
  label,
  value,
  onChange,
  onChangeDropdown,
  optionId,
  optionName,
  selectedItem,
  renderOption,
  fetchHook,
  args,
  allOptions,
  searchAgain,
  dataTestId
}: TypeaheadType<T, S>) => {
  const [focused, setFocused] = React.useState<boolean>(false)
  const anchorRef = React.useRef<HTMLInputElement | null | undefined>()
  const dropdownRef = React.useRef<HTMLDivElement>(null)
  const {options, isFetching} = useTypeahead<T, S>({
    fetchHook,
    args,
    allOptions,
    optionName,
    optionId
  })
  const {classes} = useStylesV5()
  const {textfield, root, popoverOnLight} = useStylesV4()

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        anchorRef.current &&
        !anchorRef.current.contains(event.target as Node)
      ) {
        setFocused(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [dropdownRef])

  return (
    <Box data-test-id={dataTestId} className={root}>
      <TextField
        id="typeahead"
        className={textfield}
        inputRef={anchorRef}
        label={label}
        value={selectedItem && selectedItem[optionId] !== '-1' ? selectedItem[optionName] : value}
        onChange={onChange}
        onFocus={() => setFocused(true)}
        onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
          const {key} = event
          if (key === 'Backspace' || key === 'Delete') {
            searchAgain && searchAgain(value)
          }
        }}
      />
      {options ? (
        <Popper
          open={focused}
          ref={dropdownRef}
          anchorEl={anchorRef.current}
          placement="bottom-start"
          className={popoverOnLight}
          data-test-id="typeahead-popover"
        >
          <MenuList>
            {isFetching ? (
              <CircularProgress />
            ) : (
              options.map((option) => (
                <MenuItem
                  divider
                  key={option[optionId]}
                  classes={{
                    selected: classes.selectedOnLight
                  }}
                  data-test-id={`typeahead-option-${option[optionId]}`}
                  onClick={(e) => {
                    e.stopPropagation()
                    onChangeDropdown(isT<T>(option, optionId) ? option : undefined)
                    setFocused(false)
                  }}
                  selected={selectedItem === option}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    overflowX: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    component="span"
                    data-test-id="lookup-dropdown-text"
                  >
                    {renderOption && isT<T>(option, optionId) && renderOption(option)}
                  </Typography>
                </MenuItem>
              ))
            )}
          </MenuList>
        </Popper>
      ) : null}
    </Box>
  )
}
