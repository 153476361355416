export const ERRORS_ADD = 'ERRORS/ADD'
export const ERRORS_REMOVE = 'ERRORS/REMOVE'
export const ERRORS_CLEAR = 'ERRORS/CLEAR'

export interface AddErrors {
  type: typeof ERRORS_ADD
  key: string
  translationKey: string
  callback?: () => void
  callbackTranslationKey?: string
}

export interface RemoveErrors {
  type: typeof ERRORS_REMOVE
  key: string
}

export interface ClearErrors {
  type: typeof ERRORS_CLEAR
}

export type ErrorsActionTypes = AddErrors | RemoveErrors | ClearErrors
