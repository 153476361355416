/*
For documentation see Filters/index.js
 */

/*
Filters (will fallback to defaultFilters if unset)
 */
import {
  ClearFiltersAction,
  DEFAULTFILTERS_CLEAR_ALL,
  DEFAULTFILTERS_REMOVE,
  DEFAULTFILTERS_SET,
  FILTERS_CLEAR_ALL,
  FILTERS_REMOVE,
  FILTERS_SET,
  RemoveFilterAction,
  SetFilterAction
} from './Action.types'

export const setFilterAbstract = (
  context: string,
  filterName: string,
  filterValue: unknown
): SetFilterAction => ({
  type: FILTERS_SET,
  context,
  filterName,
  filterValue
})

export const removeFilterAbstract = (context: string, filterName: string): RemoveFilterAction => ({
  type: FILTERS_REMOVE,
  context,
  filterName
})

export const clearFiltersAbstract = (context: string): ClearFiltersAction => ({
  type: FILTERS_CLEAR_ALL,
  context
})

/*
DefaultFilters
 */
export const setDefaultFilterAbstract = (
  context: string,
  filterName: string,
  filterValue: unknown
): SetFilterAction => ({
  type: DEFAULTFILTERS_SET,
  context,
  filterName,
  filterValue
})

export const removeDefaultFilterAbstract = (
  context: string,
  filterName: string
): RemoveFilterAction => ({
  type: DEFAULTFILTERS_REMOVE,
  context,
  filterName
})

export const clearDefaultFiltersAbstract = (context: string): ClearFiltersAction => ({
  type: DEFAULTFILTERS_CLEAR_ALL,
  context
})
