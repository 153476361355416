import {HCThemeType, Typography} from '@hconnect/uikit'
import {Box, makeStyles} from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import currencyFormatter from 'currency-formatter'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {CashInfoItemTypes} from '../CashBalance.enum'
import {getBoxTitleAndStyling} from '../Cashbalance.utils'

const useStyles: any = makeStyles((theme: HCThemeType) => ({
  unPaidBoxBorder: {
    background: 'linear-gradient(0deg, rgb(8, 117, 185) 0%, rgb(78, 185, 255) 100%)'
  },
  notInvoicedBoxBorder: {
    background: 'linear-gradient(0deg, rgb(4, 151, 66) 0%, rgb(26, 196, 99) 100%)'
  },
  notInvoicedNotYetDispatchedBoxBorder: {
    background: 'linear-gradient(0deg, rgb(109, 29, 130) 0%, rgb(133, 113, 212) 100%)'
  },
  totalBoxBorder: {
    background: '#002230'
  },
  overDraftBracket: {
    borderLeft: `1px solid ${theme.palette.error.main}`,
    borderTop: `1px solid ${theme.palette.error.main}`,
    borderRight: `1px solid ${theme.palette.error.main}`
  },
  cashBracket: {
    borderLeft: `1px solid ${theme.palette.text.primarySoft}`,
    borderTop: `1px solid ${theme.palette.text.primarySoft}`,
    borderRight: `1px solid ${theme.palette.text.primarySoft}`
  }
}))

interface CashInfoBoxProps {
  variant: CashInfoItemTypes
  amount: number
  currency: string
  isOverDrawn?: boolean
  overdraft?: number
  hasDeposit?: boolean
  minified?: boolean
  subtitle?: string | null
  dataTestId?: string
  dataTestIdSubtitle?: string
}

// eslint-disable-next-line complexity
export const CashInfoBox: React.FC<CashInfoBoxProps> = ({
  amount,
  currency,
  variant,
  isOverDrawn,
  overdraft = 0,
  hasDeposit,
  minified = false,
  subtitle = null,
  dataTestId,
  dataTestIdSubtitle
}) => {
  const {title, styleClass, isTotalAmount} = getBoxTitleAndStyling(variant, hasDeposit)
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const exceededAmount = currencyFormatter.format(overdraft, {
    code: currency,
    locale: language
  })
  const classes = useStyles()
  const amountVariant = minified ? 'body2' : isTotalAmount ? 'h2' : 'h3'
  const isExceeded = isOverDrawn && overdraft && overdraft >= 0
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent={'flex-start'}
      maxWidth="180px"
      data-test-id={dataTestId}
    >
      <Box display="flex">
        <Box p={0.25} className={classes[styleClass]} />
        <Box p={0.5} />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Box display="flex" alignItems="flex-start">
            <Typography
              customVariant="formLabel"
              customColor={isTotalAmount ? 'textSecondary' : 'textSecondarySoft'}
            >
              {t(title)}
            </Typography>
          </Box>
          <Box height={minified ? 18 : 'auto'} mb={minified ? 0.25 : 0}>
            {isOverDrawn ? (
              <Typography
                variant={minified ? 'body2' : 'h2'}
                customColor="textError"
                component="div"
                data-test-id="cashbox-overdrawn"
              >
                {currencyFormatter.format(0, {
                  code: currency,
                  locale: language
                })}
              </Typography>
            ) : (
              <Typography variant={amountVariant} customColor="textSecondary" component="div">
                {currencyFormatter.format(amount, {
                  code: currency,
                  locale: language
                })}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      {subtitle?.length ? (
        <Box display="flex">
          <Box p={0.75} />
          <Typography
            customVariant="formLabel"
            customColor="textSecondary"
            data-test-id={dataTestIdSubtitle}
          >
            {subtitle}
          </Typography>
        </Box>
      ) : null}
      {isExceeded ? (
        <Box display="flex">
          <Box p={0.6125} />
          <InfoIcon color="error" />
          <Box p={0.5} />
          <Typography variant="caption" customColor="textError">
            {t('overview.cashBalance.exceededCreditBy', {exceededAmount})}
          </Typography>
        </Box>
      ) : null}
    </Box>
  )
}
