// Generated by https://quicktype.io

export interface Site {
  /**
   * address informationn of the site
   */
  city?: null | string
  /**
   * address informationn of the site
   */
  country?: null | string
  /**
   * Reference to the customer
   */
  customerId: string
  /**
   * Latitude of the site
   */
  lat?: null | string
  /**
   * Longitute of the site
   */
  lng?: null | string
  /**
   * address informationn of the site
   */
  postalCode?: null | string
  /**
   * Unique identifier to establish relationship to project
   */
  projectId?: null | string
  /**
   * Unique identifier of the site
   */
  siteId: string
  /**
   * Textual description of the project that helps to clearly identify a given site
   */
  siteName?: null | string
  /**
   * buiness key of the site
   */
  siteNumber?: null | string
  /**
   * address informationn of the site
   */
  state?: null | string
  /**
   * Street address informationn of the site
   */
  street?: null | string
}
