import {useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'

import {api} from '../../../App.global'
import {CashBalance} from '../CashBalance.types'

export interface UseCreditManagementType {
  payerId?: string
  enabled?: boolean
}
export const useCreditManagement = ({payerId, enabled}: UseCreditManagementType) => {
  return useQuery(
    [
      'creditmanagement',
      {
        payerId
      }
    ],
    async () => {
      const params = {
        payerId
      }
      const res: AxiosResponse<CashBalance[]> = await api.get('/creditmanagement', {
        params
      })
      return res.data
    },
    {
      enabled: Boolean(payerId && enabled)
    }
  )
}
