import {RoleRequest as RoleRequestQueryInfo} from '@hconnect/apiclient'
import {TableColumnType, Typography, useTranslation} from '@hconnect/uikit'
import {Box} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import React from 'react'

import {getRoleRequestStates} from '../../utils'

import {InvitationListActionsCell} from './invitationsListCells/InvitationListActionsCell'
import {InvitationsListCreationDateCell} from './invitationsListCells/InvitationsListCreationDateCell'
import {InvitationsListCustomerNameCell} from './invitationsListCells/InvitationsListCustomerNameCell'
import {InvitationsListValidationCell} from './invitationsListCells/InvitationsListValidationCell'
import {RoleRequestStateTag} from './RoleRequestStateTag'

const SUMMARY_HEIGHT = 100

const useStyles = makeStyles({
  info: {
    color: 'grey'
  },
  accordionSummary: {
    height: SUMMARY_HEIGHT,
    '& .MuiAccordionSummary-expandIcon': {
      order: -1
    },
    '& .MuiAccordionSummary-content': {
      justifyContent: 'space-between'
    }
  },
  borderLineProgess: {
    height: 5,
    borderRadius: 10,
    width: 150,
    backgroundColor: 'lightgrey',
    '& .MuiLinearProgress-barColorPrimary': {
      borderRadius: 10,
      backgroundColor: '#53C069',
      color: 'red'
    }
  },
  listElement: {
    borderBottom: '1px solid lightgrey',
    paddingBottom: '10px',
    paddingTop: '10px'
  },
  selectedListElement: {
    backgroundColor: 'rgba(1, 106, 212, 0.08)'
  }
})

type InvitationsListRowsProps = {
  setIsSnackbarOpen: (isOpened: boolean) => void
  setToBeDeactivatedLink: (roleRequestId: string) => void
  setSelectedRoleRequestId: (roleRequestId: string) => void
}

export const InvitationsListRows = ({
  setIsSnackbarOpen,
  setToBeDeactivatedLink,
  setSelectedRoleRequestId
}: InvitationsListRowsProps) => {
  const {t} = useTranslation()
  const styles = useStyles()

  const rowsPool: {[key: string]: TableColumnType} = {
    creationDate: {
      field: 'creationDate',
      mobileRowHeader: true,
      headerName: t('invitations.creationDate'),
      headerAlign: 'left',
      renderCell: (roleRequest: RoleRequestQueryInfo) => (
        <InvitationsListCreationDateCell roleRequest={roleRequest} />
      )
    },
    customerName: {
      field: 'customerName',
      headerName: t('invitations.customerName'),
      headerAlign: 'left',
      renderCell: (roleRequest: RoleRequestQueryInfo) => (
        <InvitationsListCustomerNameCell
          roleRequest={roleRequest}
          data-test-id={`${roleRequest.id}-mainBox`}
        />
      )
    },
    permissionLevel: {
      field: 'permissionLevel',
      headerName: t('invitations.permissionLevel'),
      headerAlign: 'left',
      renderCell: (roleRequest: RoleRequestQueryInfo) => {
        const linkStates = getRoleRequestStates(roleRequest)
        const role = roleRequest.roles[0]

        return (
          <>
            <Box data-test-id={`${roleRequest.id}-labels`}>
              {linkStates.map((state, i) => (
                <RoleRequestStateTag state={state} key={i} />
              ))}
            </Box>
            <Box data-test-id={`${roleRequest.id}-info`}>
              <Typography
                variant="subtitle2"
                className={styles.info}
                style={{whiteSpace: 'pre-line'}}
              >
                {t(`invitations.roleTypesSubtitles.${role.roleType}`, {
                  defaultValue: ' '
                })}
              </Typography>
            </Box>
          </>
        )
      }
    },
    validation: {
      field: 'validation',
      headerName: t('invitations.validation'),
      headerAlign: 'left',
      renderCell: (roleRequest: RoleRequestQueryInfo) => (
        <InvitationsListValidationCell roleRequest={roleRequest} />
      )
    },
    actions: {
      field: 'actions',
      headerName: '',
      headerAlign: 'left',
      renderCell: (roleRequest: RoleRequestQueryInfo) => (
        <InvitationListActionsCell
          roleRequest={roleRequest}
          setIsSnackbarOpen={setIsSnackbarOpen}
          setToBeDeactivatedLink={setToBeDeactivatedLink}
          setSelectedRoleRequestId={setSelectedRoleRequestId}
        />
      )
    }
  }

  const rowOrder = (rowsPool: {[key: string]: TableColumnType}) => [
    rowsPool.creationDate,
    rowsPool.customerName,
    rowsPool.permissionLevel,
    rowsPool.validation,
    rowsPool.actions
  ]
  return rowOrder(rowsPool)
}
