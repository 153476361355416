import {Box, Checkbox, TableCell, TableRow} from '@material-ui/core'
import React from 'react'

import {useMediaQueryBreakpoints} from '../../../../../common/useMediaQueryBreakpoints'

import {useStyles} from './styles'

type ProjectsSubSitesRowProps = {
  id: string
  address: string
  name: string
  disabled: boolean
  checked: boolean
  onCheckboxClick: (event, id: string) => void
  nested?: boolean
}

export const ProjectsSubSitesRow = ({
  checked,
  disabled,
  onCheckboxClick,
  id,
  address,
  name,
  nested
}: ProjectsSubSitesRowProps) => {
  const classes = useStyles()

  const {sm: isMobile} = useMediaQueryBreakpoints()

  return isMobile ? (
    <TableRow className={classes.tableRow}>
      <TableCell className={classes.tableCell}>
        <Box style={{display: 'flex', alignItems: 'center', paddingLeft: nested ? '24px' : 0}}>
          <Checkbox
            onClick={(event) => onCheckboxClick(event, id)}
            checked={checked}
            className={classes.tableCheckbox}
            disableRipple
            disabled={disabled}
            data-test-id={`projectAndSitesRowCheckbox-${id}`}
          />
          <Box style={{display: 'flex', flexDirection: 'column'}}>
            <span>{name}</span>
            <span>{address}</span>
            <span style={{fontWeight: 500}}>{id}</span>
          </Box>
        </Box>
      </TableCell>
    </TableRow>
  ) : (
    <TableRow className={classes.tableRow}>
      <TableCell className={classes.tableCell}>
        <Box style={{display: 'flex', alignItems: 'center', paddingLeft: nested ? '24px' : 0}}>
          <Checkbox
            onClick={(event) => onCheckboxClick(event, id)}
            checked={checked}
            className={classes.tableCheckbox}
            disableRipple
            disabled={disabled}
            data-test-id={`projectAndSitesRowCheckbox-${id}`}
          />
          {name}
        </Box>
      </TableCell>
      <TableCell className={classes.tableCell}>{address}</TableCell>
      <TableCell className={classes.tableCell} style={{fontWeight: 500}}>
        {id}
      </TableCell>
    </TableRow>
  )
}
