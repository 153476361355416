import {MaterialTest} from './MaterialTest.types'

export const mapRowToTestType = (test: MaterialTest): MaterialTest[] => {
  const groupedTestsByType: MaterialTest[] = []

  const specimensGroupedByType = test.specimens?.reduce((agg, curr) => {
    if (!agg[curr.type]) {
      agg[curr.type] = {...curr, testAge: [+curr.testAge]}
    }
    if (agg[curr.type]) {
      const testAge = curr.testAge as number
      agg[curr.type] = {
        ...agg[curr.type],
        testAge: [...agg[curr.type].testAge, +testAge]
      }
    }
    return agg
  }, {})

  if (specimensGroupedByType) {
    Object.keys(specimensGroupedByType).map((key: string) =>
      groupedTestsByType.push({...test, specimens: [specimensGroupedByType[key]]})
    )
  }
  return groupedTestsByType
}
