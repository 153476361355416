import {Box, makeStyles} from '@material-ui/core'
import React from 'react'

import {CashInfoItemTypes} from '../Cashbalance/CashBalance.enum'
import {getPercentageAsString} from '../Cashbalance/Cashbalance.utils'

interface ProgressBarStyle {
  variant: string
  gradientColorStart: string
  gradientColorStop: string
  style: any
}

const progressBarStyles: ProgressBarStyle[] = [
  {
    variant: 'unpaid',
    style: makeStyles(() => ({
      style: {
        background: 'linear-gradient(90deg, rgb(8, 117, 185) 0%, rgb(78, 185, 255) 100%)'
      }
    })),
    gradientColorStart: 'rgb(8, 117, 185)',
    gradientColorStop: 'rgb(78, 185, 255)'
  },
  {
    variant: 'notYetInvoiced',
    style: makeStyles(() => ({
      style: {
        background: 'linear-gradient(-90deg, rgb(26, 196, 99) 0%, rgb(4, 151, 66) 100%)'
      }
    })),
    gradientColorStart: 'rgb(26, 196, 99)',
    gradientColorStop: 'rgb(4, 151, 66)'
  },
  {
    variant: 'notYetInvoicedNotYetDispatched',
    style: makeStyles(() => ({
      style: {
        background: 'linear-gradient(-90deg, rgb(133, 113, 212) 0%, rgb(109, 29, 130) 100%)'
      }
    })),
    gradientColorStart: 'rgb(133, 113, 212)',
    gradientColorStop: 'rgb(8, 117, 185)'
  }
]

export const CashBalanceProgressBarContainer = ({
  minified = false,
  children
}: {
  minified?: boolean
  children?: React.ReactNode
}) => (
  <Box
    display="flex"
    flexDirection="row"
    justifyContent="flex-start"
    height={minified ? 8 : 12}
    bgcolor="#000000"
    width="100%"
    borderRadius={5}
    overflow="hidden"
  >
    {children}
  </Box>
)

interface CashBalanceProgressBarItemProps {
  variant: CashInfoItemTypes
  totalAmount: number
  amount: number
  rounded?: boolean
  minified?: boolean
  dataTestId?: string
}

export const CashBalanceProgressBarItem: React.FC<CashBalanceProgressBarItemProps> = ({
  variant,
  totalAmount,
  amount,
  rounded,
  minified,
  dataTestId
}) => {
  const width = getPercentageAsString(amount, totalAmount, 2)
  if (amount <= 0) {
    return null
  }
  const progressBarStyle = progressBarStyles.find((x) => x.variant === variant)
  return (
    <>
      {rounded ? (
        <Box
          bgcolor={progressBarStyle?.gradientColorStart}
          marginRight={-0.75}
          marginLeft={-0.75}
          p={minified ? 0 : 0.75}
          pl={minified ? 0.75 : 0}
          pr={minified ? 0.75 : 0}
          borderRadius={6}
          height={minified ? 8 : 12}
        />
      ) : null}
      <Box
        data-test-id={dataTestId}
        className={progressBarStyle?.style().style}
        width={`${width}%`}
        height={minified ? 8 : 12}
      />
      {rounded ? (
        <Box
          bgcolor={progressBarStyle?.gradientColorStop}
          marginLeft={-0.75}
          p={minified ? 0 : 0.75}
          pl={minified ? 0.75 : 0}
          pr={minified ? 0.75 : 0}
          borderRadius={6}
          height={minified ? 8 : 12}
        />
      ) : null}
    </>
  )
}
