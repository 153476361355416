/*
For documentation see Filters/index.js
 */

import {
  DEFAULTFILTERS_CLEAR_ALL,
  DEFAULTFILTERS_REMOVE,
  DEFAULTFILTERS_SET,
  FILTERS_CLEAR_ALL,
  FILTERS_REMOVE,
  FILTERS_SET,
  FilterActionType,
  FiltersStateType
} from './Action.types'

export const initialFiltersState: FiltersStateType = {
  filters: {},
  defaultFilters: {}
}

export const createFiltersReducer =
  (CONTEXT: string) =>
  (state = initialFiltersState, action: FilterActionType) => {
    if (action.context !== CONTEXT) {
      return state
    }

    switch (action.type) {
      case FILTERS_SET:
        return {
          ...state,
          filters: {
            ...state.filters,
            [action.filterName]: action.filterValue
          }
        }

      case FILTERS_REMOVE: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const {[action.filterName]: removedFilter, ...restContextFilters} = state.filters
        return {
          ...state,
          filters: {
            ...restContextFilters
          }
        }
      }

      case FILTERS_CLEAR_ALL:
        return {
          ...state,
          filters: {}
        }

      case DEFAULTFILTERS_SET:
        return {
          ...state,
          defaultFilters: {
            ...state.defaultFilters,
            [action.filterName]: action.filterValue
          }
        }

      case DEFAULTFILTERS_REMOVE: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const {[action.filterName]: removedDefaultFilter, ...restDefaultContextFilters} =
          state.defaultFilters
        return {
          ...state,
          defaultFilters: {
            ...restDefaultContextFilters
          }
        }
      }

      case DEFAULTFILTERS_CLEAR_ALL:
        return {
          ...state,
          defaultFilters: {}
        }

      default:
        return state
    }
  }
