import {Theme, makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    display: 'grid',
    gridTemplateRows: 'auto',
    outline: 'none'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 16px'
  },
  heading: {
    lineHeight: '38px'
  },
  selectionGrid: {
    display: 'grid',
    gridTemplateRows: '48px 70px auto'
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  switchStyle: {
    marginLeft: 0
  },
  selectedCustomer: {
    backgroundColor: '#ffffff !important'
  },
  customer: {
    padding: `${theme.spacing(2)}px`,
    outline: 'none',
    cursor: 'pointer',
    width: '100%',
    background: 'none',
    border: 'none',
    borderBottom: '1px solid #ffffff'
  },
  projectRow: {
    height: 50,
    display: 'flex',
    flexDirection: 'column',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.grey[100]
  },
  checkbox: {
    display: 'flex',
    alignSelf: 'center',
    justifySelf: 'center',
    margin: `0 ${theme.spacing(1)}px`
  },
  projectItem: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  projectItemCollapsable: {
    '& .MuiAccordionSummary-content': {
      margin: 0
    },

    '& .MuiAccordionSummary-expandIcon': {
      border: 'none',
      boxShadow: 'none',
      padding: 0
    },

    '& .MuiAccordionSummary-root': {
      padding: 0,
      paddingRight: 16,
      minHeight: 0
    },

    margin: '0 !important'
  },
  projectItemCollapsableDetails: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 5px'
  },
  projectItemLeft: {
    display: 'flex'
  },
  projectRowSiteItem: {
    marginLeft: '15px'
  },
  projectInfoContainer: {
    display: 'flex'
  },
  projectInfo: {
    fontWeight: 'bold'
  },
  siteLocationIcon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(0.5)
  },
  siteRowNoBorders: {
    display: 'flex',
    alignItems: 'center'
  },
  siteRow: {
    height: 35,
    display: 'flex',
    alignItems: 'center',
    borderBottomWidth: '2px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.grey[100]
  },
  allSitesSelectedPlaceholderContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1
  },
  allSitesSelectedPlaceholderInfoText: {
    whiteSpace: 'pre-wrap',
    textAlign: 'center'
  },
  projectSitesColumnGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'auto 1fr',
    height: '100%',
    width: '100%',
    gridColumnGap: '30px'
  },
  projectSitesColumHeader: {
    padding: '16px 8px',
    borderBottom: '1px solid #e7e7e7'
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#efefef',
    padding: '0 16px'
  },
  searchInput: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%'
    },
    maxWidth: '250px',
    padding: '11px 14px',
    border: '1.3125px solid rgba(0, 39, 77, 0.15)',
    boxShadow: 'inset 0px 3.5px 3.5px rgba(31, 73, 94, 0.08)',
    borderRadius: '3.5px',
    '& .MuiInputBase-input': {
      padding: 0
    }
  },
  searchIconButton: {background: 'none', border: 'none'},
  searchIcon: {background: 'none', color: 'black'},
  tabContainer: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    minHeight: 0,
    borderRadius: '6px',
    border: '1px solid rgba(0, 39, 77, 0.15)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  firstTab: {
    textTransform: 'none',
    padding: '7.5px 19px',
    margin: '3.5px',
    minHeight: 0,
    height: '35px',
    letterSpacing: 'normal',
    [theme.breakpoints.down('sm')]: {
      width: '49%'
    }
  },
  secondTab: {
    textTransform: 'none',
    padding: '7.5px 33px',
    margin: '3.5px',
    minHeight: 0,
    height: '35px',
    letterSpacing: 'normal',
    [theme.breakpoints.down('sm')]: {
      width: '49%'
    }
  },
  tabSelected: {
    background: '#016AD4',
    color: 'white',
    borderRadius: '4px',
    fontSize: '14px',
    lineHeight: '19px',
    fontWeight: 600
  },
  infoContainer: {
    backgroundColor: '#FFFAF3',
    borderLeft: '4px solid #EE8B00',
    borderRight: '1px solid #EAEDF0',
    borderTop: '1px solid #EAEDF0',
    borderBottom: '1px solid #EAEDF0',
    borderRadius: '4px',
    padding: '12px 16px',
    lineHeight: '16px',
    boxShadow: theme.shadows[9],
    marginTop: '14px'
  },
  tableCell: {
    fontWeight: 600,
    borderBottom: '1px solid #EAEDF0'
  },
  tableCheckbox: {
    color: 'rgba(0, 39, 77, 0.15)',
    borderRadius: '3px',
    '&:hover': {
      boxShadow: 'none !important'
    },
    '&.Mui-checked': {
      color: '#016AD4 !important'
    }
  },
  tableRow: {
    color: 'red'
  },
  checkboxIcon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5'
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)'
    }
  }
}))
