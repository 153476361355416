import {trackEvent} from '@hconnect/common/logging/Analytics'
import fileDownload from 'js-file-download'

import {addJob, deleteJob, updateJob} from '../../AsyncJobQueue/AsyncJobQueue.action'
import {JobEndpoint} from '../../AsyncJobQueue/AsyncJobQueue.types'
import {errorKeyFromRequestError} from '../../ErrorMap'
import {errorsAdd} from '../Errors'

import {
  DOCUMENT_DOWNLOAD_FAILURE,
  DOCUMENT_DOWNLOAD_REQUEST,
  DOCUMENT_DOWNLOAD_SUCCESS,
  DocumentActionTypes
} from './Action.types'

export const downloadDocumentRequest = (data: any): DocumentActionTypes => ({
  type: DOCUMENT_DOWNLOAD_REQUEST,
  data
})

export const downloadDocumentSuccess = (data: any): DocumentActionTypes => ({
  type: DOCUMENT_DOWNLOAD_SUCCESS,
  data
})

export const downloadDocumentFailure = (payload: any): DocumentActionTypes => ({
  type: DOCUMENT_DOWNLOAD_FAILURE,
  payload,
  receivedAt: new Date().toISOString()
})

// FIXME: move this file to a global/common folder. It is not specific to invoices
export const downloadDoc = async (api, href, fileName) => {
  const response = await api.get(href, {
    responseType: 'blob'
  })
  const blob = new Blob([response.data], {
    type: response.headers['content-type']
  })
  fileDownload(blob, fileName)
  return blob.size
}

export const downloadDocument =
  (jobId: string, documentId?: string, fileName?: string) =>
  async (dispatch, getState, {api}) => {
    const name = fileName || 'document'
    const job = {id: documentId, name, type: JobEndpoint.Document}
    dispatch(downloadDocumentRequest(documentId))
    dispatch(addJob(job))

    try {
      const fileSize = await downloadDoc(api, `/documents/${documentId}`, fileName)
      trackEvent('hubDownloadSingleSuccess', {
        product: 'hub',
        fileSize,
        jobId: jobId
      })
      dispatch(downloadDocumentSuccess(documentId))
      if (documentId) {
        dispatch(deleteJob(documentId))
      }
    } catch (err) {
      const errorKey = errorKeyFromRequestError(err)
      dispatch(
        errorsAdd({
          key: 'documents',
          translationKey: `errorMessages.${errorKey}`,
          callback: () => dispatch(downloadDocument(jobId, documentId, fileName)),
          callbackTranslationKey: 'error.action.retry'
        })
      )

      dispatch(downloadDocumentFailure(err))
      dispatch(updateJob({...job, name: 'downloadFailed'}))
    }
  }
