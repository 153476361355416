import {HCThemeType} from '@hconnect/uikit'
import {alpha, makeStyles} from '@material-ui/core'

const MAX_WIDTH = 38 // (35 x 8px = 280px)
const MAX_HEIGHT = 38

export const useStylesV4 = makeStyles((theme: HCThemeType) => ({
  root: {
    minWidth: '220px',
    height: '100%',
    minHeight: '57px',
    background: theme.palette.grey[50],
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 4,
    '&:hover': {
      background: theme.palette.grey[100],
      borderColor: theme.palette.grey[200]
    },
    '&>*': {
      borderLeft: `1px solid ${theme.palette.grey[100]}`,
      '&:hover': {
        borderLeftColor: theme.palette.grey[200]
      }
    },
    '& input': {
      fontSize: 16,
      color: alpha(theme.palette.secondary.dark, 0.96),
      fontWeight: 500,
      lineHeight: '19px',
      marginTop: 6
    }
  },
  textfield: {
    margin: 0,
    '& .MuiInputLabel-formControl': {
      paddingLeft: theme.spacing(2.5),
      color: alpha(theme.palette.secondary.dark, 0.6)
    },
    '& .MuiInputLabel-formControl.MuiInputLabel-shrink': {
      paddingTop: theme.spacing()
    },
    '& .MuiInputBase-root': {
      marginLeft: theme.spacing(2.5),
      marginTop: theme.spacing(2.25),
      marginBottom: theme.spacing(1),
      fontSize: 16,
      alignItems: 'flex-end',
      '& .MuiInputBase-input': {
        padding: 0
      }
    },
    '& .MuiInput-underline, & .MuiInput-underline:hover': {
      '&::after,&::before': {
        border: 'none'
      }
    }
  },
  popoverOnLight: {
    backgroundColor: '#f7f7f7',
    color: 'rgb(0, 55, 77)',
    marginLeft: theme.spacing(-3),
    marginTop: theme.spacing(2),
    width: theme.spacing(MAX_WIDTH),
    maxHeight: theme.spacing(MAX_HEIGHT),
    overflowY: 'scroll',
    zIndex: 1000
  }
}))
