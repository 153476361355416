import {AxiosRequestConfig, AxiosResponse} from 'axios'
import get from 'lodash/get'
import {batch} from 'react-redux'

import {RequestCanceller} from '../../util/action.helper'
import {createSelectFilter, removeFilterAbstract, setFilterAbstract} from '../Filters'
import {INVOICES} from '../Invoices'
import {MATERIALTESTS} from '../Materialtest'
import {ORDERS} from '../Orders'
import {Site, fetchSites, selectSites} from '../Sites'

import {
  PROJECTS_FETCH_FAILURE,
  PROJECTS_FETCH_REQUEST,
  PROJECTS_FETCH_SUCCESS,
  PROJECTS_WITH_SITES,
  ProjectsActionTypes,
  REMOVE_PROJECT_FILTER,
  REMOVE_SITE_FILTER,
  SET_PROJECT_FILTER,
  SET_SITE_FILTER
} from './Action.types'
import {Project} from './Projects.types'

const Canceller = RequestCanceller()

export const fetchProjectsRequest = (): ProjectsActionTypes => ({
  type: PROJECTS_FETCH_REQUEST
})

export const fetchProjectsFailure = (error: any): ProjectsActionTypes => ({
  type: PROJECTS_FETCH_FAILURE,
  error
})

export const fetchProjectsSuccess = (projects: Project[]): ProjectsActionTypes => ({
  type: PROJECTS_FETCH_SUCCESS,
  projects
})

export const fetchProjects =
  (customerId: string) =>
  async (dispatch, getState, {api}) => {
    dispatch(fetchProjectsRequest())

    try {
      const requestConfig: AxiosRequestConfig = {
        params: {customerId},
        cancelToken: Canceller.getToken()
      }
      const response: AxiosResponse<Project[]> = await api
        .get('/projects', requestConfig)
        .catch((thrown: any) => (Canceller.isCancel(thrown) ? undefined : thrown))

      response && dispatch(fetchProjectsSuccess(response.data))
    } catch (e) {
      dispatch(fetchProjectsFailure(e))
    }
  }

export const getProjectsWithSites = (customerId: string) => async (dispatch, getState) => {
  await dispatch(fetchProjects(customerId))
  await dispatch(fetchSites(customerId))

  const state = getState()
  const sites = get(selectSites(state), 'sites')

  dispatch({
    type: PROJECTS_WITH_SITES,
    sites
  })
}

export const setProjectFilter = (project: Project): ProjectsActionTypes => ({
  type: SET_PROJECT_FILTER,
  project
})
export const removeProjectFilter = (): ProjectsActionTypes => ({
  type: REMOVE_PROJECT_FILTER
})

export const setSiteFilter = (site: Site): ProjectsActionTypes => ({
  type: SET_SITE_FILTER,
  site
})
export const removeSiteFilter = (): ProjectsActionTypes => ({
  type: REMOVE_SITE_FILTER
})

const PROJECTFILTER = 'project'
export const selectProjectFilterAbstract = createSelectFilter(PROJECTFILTER)

const SITEFILTER = 'site'
export const selectSiteFilterAbstract = createSelectFilter(SITEFILTER)

const setOrdersProjectFilter = (project: Project) =>
  setFilterAbstract(ORDERS, PROJECTFILTER, {...project})
const setInvoicesProjectFilter = (project: Project) =>
  setFilterAbstract(INVOICES, PROJECTFILTER, {...project})
const setRMCMaterialTestsProjectFilter = (project: Project) =>
  setFilterAbstract(MATERIALTESTS, PROJECTFILTER, {...project})
export const setGlobalProjectFilter = (project: Project) => (dispatch) => {
  if (project && project.projectId) {
    batch(() => {
      dispatch(setOrdersProjectFilter(project))
      dispatch(setInvoicesProjectFilter(project))
      dispatch(setRMCMaterialTestsProjectFilter(project))
      dispatch(setProjectFilter(project))
    })
  }
}

const setOrdersSiteFilter = (site: Site) => setFilterAbstract(ORDERS, SITEFILTER, {...site})
const setInvoicesSiteFilter = (site: Site) => setFilterAbstract(INVOICES, SITEFILTER, {...site})
const setRMCMaterialTestsSiteFilter = (site: Site) =>
  setFilterAbstract(MATERIALTESTS, SITEFILTER, {...site})
export const setGlobalSiteFilter = (site: Site) => (dispatch) => {
  if (site && site.siteId) {
    batch(() => {
      dispatch(setOrdersSiteFilter(site))
      dispatch(setInvoicesSiteFilter(site))
      dispatch(setRMCMaterialTestsSiteFilter(site))
      dispatch(setSiteFilter(site))
    })
  }
}

const removeOrdersProjectFilter = removeFilterAbstract(ORDERS, PROJECTFILTER)
const removeInvoicesProjectFilter = removeFilterAbstract(INVOICES, PROJECTFILTER)
const removeRMCMaterialTestsProjectFilter = removeFilterAbstract(MATERIALTESTS, PROJECTFILTER)
const removeOrdersSiteFilter = removeFilterAbstract(ORDERS, SITEFILTER)
const removeInvoicesSiteFilter = removeFilterAbstract(INVOICES, SITEFILTER)
const removeRMCMaterialTestsSiteFilter = removeFilterAbstract(MATERIALTESTS, SITEFILTER)
export const removeGlobalProjectFilter = () => (dispatch) =>
  batch(() => {
    dispatch(removeOrdersProjectFilter)
    dispatch(removeInvoicesProjectFilter)
    dispatch(removeRMCMaterialTestsProjectFilter)
    dispatch(removeProjectFilter())
  })

export const removeGlobalSiteFilter = () => (dispatch) =>
  batch(() => {
    dispatch(removeOrdersSiteFilter)
    dispatch(removeInvoicesSiteFilter)
    dispatch(removeRMCMaterialTestsSiteFilter)
    dispatch(removeSiteFilter())
  })
