import {RoleRequest} from '@hconnect/apiclient'
import {dateFormatter, useBreakPoints} from '@hconnect/uikit'
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Typography
} from '@material-ui/core'
import BlockIcon from '@material-ui/icons/Block'
import Brightness1Icon from '@material-ui/icons/Brightness1'
import Close from '@material-ui/icons/Close'
import FilterNoneIcon from '@material-ui/icons/FilterNone'
import PersonIcon from '@material-ui/icons/Person'
import ReplyIcon from '@material-ui/icons/Reply'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'

import createInvitationLink from '../../assets/create_invitation_link.svg'
import {useOrgUnitConfiguration} from '../../hooks/useConfigurations'
import {useCustomerById} from '../../hooks/useCustomers'
import {usePayerById} from '../../hooks/usePayers'
import {getRoleRequestStates, isRoleRequestExpired} from '../../utils'
import {handleCopyLink} from '../utils/handleCopyLink'
import {handleMobileShare} from '../utils/handleMobileShare'

import {checkIfUsedByIsNewUser} from './RoleRequest'
import {RoleRequestStateTag} from './RoleRequestStateTag'

type InvitationListDetailsProps = {
  selectedRoleRequestId: string
  setToBeRevokedUserId: (userId: string | null) => void
  setIsSnackbarOpened: (isOpened: boolean) => void
  setToBeDeactivatedLink: (roleRequestId: string | null) => void
  loggedInUserId: string
  selectedRoleRequest?: RoleRequest
  setSelectedRow?: (row?: RoleRequest) => void
}

const NEW_USER_BUBBLE_FONT_SIZE = 12

const useStyles = makeStyles(() => ({
  ListTextPrimary: {
    color: 'black'
  },
  ListTextSecondary: {
    color: 'black'
  },
  deactivateLinkButton: {
    border: 'none',
    outline: 'none',
    color: '#CA605F',
    textTransform: 'unset',
    whiteSpace: 'nowrap',
    padding: '10px 16px',
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none'
    }
  },
  copyLinkButton: {
    textTransform: 'unset',
    border: 'none',
    whiteSpace: 'nowrap',
    padding: '10px 16px',
    outline: 'none',
    color: '#29aaff',
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none'
    }
  }
}))

// eslint-disable-next-line complexity
export const InvitationListDetails = ({
  selectedRoleRequest,
  setSelectedRow,
  selectedRoleRequestId,
  setIsSnackbarOpened,
  setToBeRevokedUserId,
  setToBeDeactivatedLink,
  loggedInUserId
}: InvitationListDetailsProps) => {
  const {t, i18n} = useTranslation()
  const classes = useStyles()
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)
  const isTablet = ['xs', 'sm', 'md'].includes(screenSizes)

  const roleRequestId = selectedRoleRequest?.id

  const role = selectedRoleRequest?.roles[0]

  const dataScope = selectedRoleRequest?.roles[0].dataScope
  const customerId = dataScope?.customerIds?.length ? dataScope.customerIds[0] : undefined
  const payerId = dataScope?.payerIds?.length ? dataScope.payerIds[0] : undefined
  const orgUnitConfigurationQueryInfo = useOrgUnitConfiguration(
    dataScope?.businessLine || '',
    dataScope?.countryId || ''
  )
  const orgUnitConfiguration = orgUnitConfigurationQueryInfo.data?.find(
    ({orgUnitId}) => orgUnitId === dataScope?.orgUnitId
  )
  const link = `${window.location.origin}/receiveInvite/${dataScope?.countryId}/${roleRequestId}`
  const countryBusinessLineAndOrgUnit = !role
    ? ''
    : `${dataScope?.countryId}/${dataScope?.businessLine}/${
        orgUnitConfiguration?.orgUnitName ?? ''
      }`

  const customerDetailsQueryInfo = useCustomerById(customerId)
  const payerDetailsQueryInfo = usePayerById(payerId)

  const customerName =
    customerDetailsQueryInfo.data?.customerName || payerDetailsQueryInfo.data?.payerName

  const valid =
    selectedRoleRequest &&
    !isRoleRequestExpired(selectedRoleRequest) &&
    !selectedRoleRequest.deactivated &&
    selectedRoleRequest.usageLeft > 0

  const CloseButton = ({onClose, disabled = false}) => {
    return (
      <Box display="flex" justifyContent="flex-end" alignItems="center" height={80}>
        <div>
          <IconButton
            onClick={onClose}
            style={{border: 'none', boxShadow: 'none'}}
            data-test-id="modal-close-button"
            disabled={disabled}
          >
            <Close fontSize="medium" />
          </IconButton>
        </div>
      </Box>
    )
  }

  const countProjectAndSites = useMemo(() => {
    const projectIds = role?.dataScope?.projectIds ?? []
    const siteIds = role?.dataScope?.siteIds ?? []

    return `${projectIds?.length ? projectIds?.length : t('invitations.all')} ${t(
      'invitations.projects'
    )} ${siteIds?.length ? siteIds?.length : t('invitations.all')} ${t('invitations.sites')}`
  }, [t, role])
  const limit =
    selectedRoleRequest && selectedRoleRequest.usageLimit < 0
      ? t('invitations.unlimited')
      : selectedRoleRequest?.usageLimit

  return (
    <Box
      style={{
        paddingLeft: isMobile ? '0' : '20px',
        paddingTop: isMobile ? '0' : '20px',
        paddingBottom: isMobile ? '0' : '20px',
        width: isTablet ? '100%' : '35%'
      }}
    >
      {!isMobile && isTablet && (
        <CloseButton onClose={() => setSelectedRow && setSelectedRow(undefined)} />
      )}
      {valid ? (
        <Box>
          {!isMobile && (
            <Typography
              variant="h4"
              style={{
                color: 'black',
                marginTop: isTablet ? 0 : '20px',
                marginBottom: '40px',
                fontSize: '18px',
                fontWeight: 500
              }}
            >
              {t('invitations.details')}
            </Typography>
          )}
          <Card style={{border: '1px solid lightgrey', padding: '10px'}}>
            <CardContent>
              <Box>
                <Box style={{display: 'flex', gap: '16px'}}>
                  {selectedRoleRequest &&
                    getRoleRequestStates(selectedRoleRequest).map((state, i) => (
                      <RoleRequestStateTag state={state} key={i} />
                    ))}
                  <Typography variant="subtitle2">
                    {`${dateFormatter(
                      selectedRoleRequest.createdOn,
                      i18n.language
                    )} - ${dateFormatter(selectedRoleRequest.expiryDate, i18n.language)} `}
                  </Typography>
                </Box>
              </Box>
              <Typography
                variant="subtitle2"
                style={{marginTop: '25px'}}
              >{`${countryBusinessLineAndOrgUnit} | ${countProjectAndSites}`}</Typography>
              <Typography variant="h3" style={{marginTop: '10px'}}>
                {customerName}
              </Typography>
              <Typography variant="body1" style={{whiteSpace: 'pre-line'}}>
                {t(`invitations.roleTypesSubtitles.${selectedRoleRequest.roles[0].roleType}`, {
                  defaultValue: ' '
                })}
              </Typography>
              <Typography variant="subtitle2" style={{marginTop: '4px'}}>
                {selectedRoleRequest?.usageCount}/{limit} {t('invitations.used')}
              </Typography>
            </CardContent>
            <CardActions style={{display: 'flex'}}>
              {!isMobile && (
                <Button
                  startIcon={<FilterNoneIcon />}
                  onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                    handleCopyLink(event, setIsSnackbarOpened, loggedInUserId, link, roleRequestId)
                  }
                  href={link}
                  data-test-id={`${roleRequestId}-copyLink`}
                  className={classes.copyLinkButton}
                >
                  {t('invitations.copyLink')}
                </Button>
              )}
              {isMobile && (
                <Button
                  startIcon={<ReplyIcon style={{transform: 'scaleX(-1)'}} />}
                  onClick={() =>
                    handleMobileShare(setIsSnackbarOpened, loggedInUserId, link, t, roleRequestId)
                  }
                  data-test-id={`${roleRequestId}-shareLink`}
                  className={classes.copyLinkButton}
                >
                  {t('invitations.shareLink')}
                </Button>
              )}
              <Button
                className={classes.deactivateLinkButton}
                startIcon={<BlockIcon />}
                onClick={() => setToBeDeactivatedLink(roleRequestId ?? null)}
                data-test-id={`${roleRequestId}-deactivateLink`}
              >
                {t('invitations.deactivate')}
              </Button>
            </CardActions>
          </Card>
          {selectedRoleRequest.usedBy.length > 0 ? (
            <Box pt={3}>
              <Typography variant="h4" style={{color: 'grey', fontWeight: 600}}>
                {t('invitations.redeemedBy', {
                  numberOfUsers: selectedRoleRequest.usedBy.length
                })}
              </Typography>
              <Box>
                <List>
                  {selectedRoleRequest.usedBy.map((user, index) => (
                    <Box key={index}>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar style={{backgroundColor: '#EBEBEB', color: '#FFFFFF'}}>
                            <PersonIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <>
                              <Brightness1Icon
                                data-test-id={`${user.userId}-usedOn-newUser`}
                                style={{
                                  fontSize: NEW_USER_BUBBLE_FONT_SIZE,
                                  color: checkIfUsedByIsNewUser(user) ? '#53C069' : 'transparent'
                                }}
                              />

                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Typography style={{fontWeight: 600}}>
                                  {user.userInformation.name}
                                </Typography>
                                <Button
                                  data-test-id={`rrid-${roleRequestId}-uid-${user.userId}-revokeRoleRequest`}
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    setToBeRevokedUserId(user?.userId)
                                  }}
                                  variant="text"
                                  style={{
                                    backgroundColor: 'none',
                                    color: user.revoked ? '#AFAFAF' : '#CA605F',
                                    textTransform: 'none'
                                  }}
                                  disabled={user.revoked}
                                >
                                  {user.revoked
                                    ? t('invitations.revoked')
                                    : t('invitations.revoke')}
                                </Button>
                              </Box>
                            </>
                          }
                          secondary={
                            <>
                              <Typography>{user.userInformation.eMail}</Typography>
                            </>
                          }
                          classes={{
                            primary: classes.ListTextPrimary,
                            secondary: classes.ListTextSecondary
                          }}
                        />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                    </Box>
                  ))}
                </List>
              </Box>
            </Box>
          ) : null}
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          style={{height: '100%', marginTop: isMobile ? '20px' : '-10px'}}
        >
          {!selectedRoleRequest ? (
            <>
              <img src={createInvitationLink} alt="create invitation link" />
              <Box
                style={{
                  fontSize: '16px',
                  color: '#AAAAAA',
                  marginTop: '33px',
                  marginBottom: '8px'
                }}
              >
                {t('invitations.chooseRecord')}
              </Box>
              <Box>
                <span style={{fontSize: '16px', color: '#AAAAAA'}}>{t('invitations.or')}</span>{' '}
                <Link style={{textDecoration: 'none', fontSize: '16px'}} to={'createInvite'}>
                  {t('invitations.createNewPermissionLink')}
                </Link>
              </Box>
            </>
          ) : (
            <Box data-test-id={`${selectedRoleRequestId}-deactivated`} style={{fontSize: '16px'}}>
              {t('invitations.deactivated')}
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
}
