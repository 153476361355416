import {
  CustomerActionTypes,
  CustomerStateType,
  REMOVE_CUSTOMER_FILTER,
  SET_CUSTOMER_FILTER,
  CUSTOMERS_FETCH_FAILURE,
  CUSTOMERS_FETCH_REQUEST,
  CUSTOMERS_FETCH_RESPONSE,
  CUSTOMER_BY_CUSTOMER_NUMBER_FETCH_FAILURE,
  CUSTOMER_BY_CUSTOMER_NUMBER_FETCH_PENDING,
  CUSTOMER_BY_CUSTOMER_NUMBER_FETCH_SUCCESS,
  CUSTOMER_SET_SEARCH_STRING,
  CUSTOMER_SET_SEARCH_CUSTOMER
} from './Action.types'

export const initialState: CustomerStateType = {
  customers: [],
  selectedCustomer: null,
  searchString: '',
  customerChoosen: false,
  isFetching: false,
  error: null
}

export const customers = (state = initialState, action: CustomerActionTypes) => {
  switch (action.type) {
    case CUSTOMERS_FETCH_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: initialState.error
      }

    case CUSTOMERS_FETCH_RESPONSE:
      return {
        ...state,
        isFetching: false,
        error: null,
        customers: action.response
      }

    case CUSTOMERS_FETCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        customers: [],
        error: action.error
      }

    case CUSTOMER_BY_CUSTOMER_NUMBER_FETCH_PENDING:
      return {
        ...state,
        isFetching: true,
        error: initialState.error
      }

    case CUSTOMER_BY_CUSTOMER_NUMBER_FETCH_SUCCESS:
      return {
        ...state,
        customerChoosen: action.response && action.response.length === 1,
        isFetching: false,
        error: null,
        customers: action.response
      }

    case CUSTOMER_BY_CUSTOMER_NUMBER_FETCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        customers: [],
        error: action.error
      }

    case SET_CUSTOMER_FILTER:
      return {
        ...state,
        selectedCustomer: {...action.customer}
      }

    case REMOVE_CUSTOMER_FILTER:
      return {
        ...state,
        selectedCustomer: null
      }

    case CUSTOMER_SET_SEARCH_STRING:
      return {
        ...state,
        customerChoosen: false,
        searchString: action.searchString
      }

    case CUSTOMER_SET_SEARCH_CUSTOMER:
      return {
        ...state,
        customerChoosen: true
      }

    default:
      return state
  }
}
