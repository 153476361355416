import {RoleRequest as RoleRequestQueryInfo} from '@hconnect/apiclient'
import {dateFormatter, Typography, useBreakPoints, useTranslation} from '@hconnect/uikit'
import {Box} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import React from 'react'

import {useAuth} from '../../../Create/components/authentication/context'

type InvitationsListCreationDateCellProps = {
  roleRequest: RoleRequestQueryInfo
}

const useStyles = makeStyles((theme) => ({
  date: {
    fontSize: 18,
    fontWeight: 400,
    color: '#002E4D',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      fontWeight: 600,
      color: '#54708C'
    }
  },
  validDate: {
    fontSize: 12,
    fontWeight: 400,
    color: '#002E4D',
    marginTop: '4px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      fontWeight: 500,
      color: '#54708C',
      marginTop: '0'
    }
  }
}))

export const InvitationsListCreationDateCell = ({
  roleRequest
}: InvitationsListCreationDateCellProps) => {
  const {t, i18n} = useTranslation()
  const styles = useStyles()
  const {decodedToken} = useAuth()
  const loggedInUserId = decodedToken?.user_id || ''
  const isCreatedByMe = loggedInUserId === roleRequest.createdBy
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: isMobile ? 'row' : 'column',
        alignItems: isMobile ? 'center' : 'stretch',
        gap: isMobile ? '20px' : 0
      }}
      data-test-id={`${roleRequest.id}-dateBox`}
    >
      {!isMobile && (
        <Typography variant="subtitle2" className={styles.validDate}>
          {isCreatedByMe ? 'My link' : ''}
        </Typography>
      )}
      <Typography className={styles.date}>
        {dateFormatter(roleRequest.createdOn, i18n.language)}
      </Typography>
      <Typography variant="subtitle2" className={styles.validDate}>
        {`${t('invitations.validUntil')} ${dateFormatter(roleRequest.expiryDate, i18n.language)}`}
      </Typography>
    </Box>
  )
}
