import {RoleConfiguration} from '@hconnect/apiclient'
import {isEqual} from 'lodash'

import {
  ActionBasedPermissions,
  CustomerViewRoleMapping,
  PayerViewRoleMapping,
  RoleEnum,
  SearchTypeKeys
} from './Invite.types'

export const customerActionBasedPermissionsInitialValue = {
  canSeeOrderAndDeliveries: true,
  canCreateAndChangeOrders: false,
  canSeeInvoices: false,
  canSeeTestsAndCertificates: false,
  isTheLegalContactOfTheCustomerAndCanDoEverything: false
}

export const customerViewRoleMapping: CustomerViewRoleMapping[] = [
  {
    role: RoleEnum.FINISHER,
    actionBasedPermissions: {
      canSeeOrderAndDeliveries: true,
      canCreateAndChangeOrders: false,
      canSeeInvoices: false,
      canSeeTestsAndCertificates: false,
      isTheLegalContactOfTheCustomerAndCanDoEverything: false
    }
  },
  {
    role: RoleEnum.ORDER_PLACER,
    actionBasedPermissions: {
      canSeeOrderAndDeliveries: true,
      canCreateAndChangeOrders: true,
      canSeeInvoices: false,
      canSeeTestsAndCertificates: false,
      isTheLegalContactOfTheCustomerAndCanDoEverything: false
    }
  },
  {
    role: RoleEnum.ORDER_PLACER_FINANCE,
    actionBasedPermissions: {
      canSeeOrderAndDeliveries: true,
      canCreateAndChangeOrders: true,
      canSeeInvoices: true,
      canSeeTestsAndCertificates: false,
      isTheLegalContactOfTheCustomerAndCanDoEverything: false
    }
  },
  {
    role: RoleEnum.PLANT_MANAGER,
    actionBasedPermissions: {
      canSeeOrderAndDeliveries: true,
      canCreateAndChangeOrders: true,
      canSeeInvoices: true,
      canSeeTestsAndCertificates: true,
      isTheLegalContactOfTheCustomerAndCanDoEverything: false
    }
  },
  {
    role: RoleEnum.BUSINESS_OWNER,
    actionBasedPermissions: {
      canSeeOrderAndDeliveries: true,
      canCreateAndChangeOrders: true,
      canSeeInvoices: true,
      canSeeTestsAndCertificates: true,
      isTheLegalContactOfTheCustomerAndCanDoEverything: true
    }
  },
  {
    role: RoleEnum.ACCOUNTANT,
    actionBasedPermissions: {
      canSeeOrderAndDeliveries: true,
      canCreateAndChangeOrders: false,
      canSeeInvoices: true,
      canSeeTestsAndCertificates: true,
      isTheLegalContactOfTheCustomerAndCanDoEverything: false
    }
  },
  {
    role: RoleEnum.ACCOUNTANT_NOTESTS,
    actionBasedPermissions: {
      canSeeOrderAndDeliveries: true,
      canCreateAndChangeOrders: false,
      canSeeInvoices: true,
      canSeeTestsAndCertificates: false,
      isTheLegalContactOfTheCustomerAndCanDoEverything: false
    }
  },
  {
    role: RoleEnum.QA_ENGINEER,
    actionBasedPermissions: {
      canSeeOrderAndDeliveries: true,
      canCreateAndChangeOrders: false,
      canSeeInvoices: false,
      canSeeTestsAndCertificates: true,
      isTheLegalContactOfTheCustomerAndCanDoEverything: false
    }
  },
  {
    role: RoleEnum.SUPERINTEDENT,
    actionBasedPermissions: {
      canSeeOrderAndDeliveries: true,
      canCreateAndChangeOrders: true,
      canSeeInvoices: false,
      canSeeTestsAndCertificates: true,
      isTheLegalContactOfTheCustomerAndCanDoEverything: false
    }
  }
]

export const payerActionBasedPermissionsInitialValue = {
  canSeeInvoices: true,
  canSeeCreditLimitInformationAndMakePayments: false,
  isTheLegalContactOfTheCustomerAndCanDoEverything: false
}

export const payerViewRoleMapping: PayerViewRoleMapping[] = [
  {
    role: RoleEnum.BUSINESS_OWNER,
    actionBasedPermissions: {
      canSeeInvoices: true,
      canSeeCreditLimitInformationAndMakePayments: true,
      isTheLegalContactOfTheCustomerAndCanDoEverything: true
    }
  },
  {
    role: RoleEnum.ACCOUNTANT,
    actionBasedPermissions: {
      canSeeInvoices: true,
      canSeeCreditLimitInformationAndMakePayments: false,
      isTheLegalContactOfTheCustomerAndCanDoEverything: false
    }
  },
  {
    role: RoleEnum.CONTROLLER,
    actionBasedPermissions: {
      canSeeInvoices: true,
      canSeeCreditLimitInformationAndMakePayments: true,
      isTheLegalContactOfTheCustomerAndCanDoEverything: false
    }
  }
]

export const filterRoleFromViewMapping = (
  actionBasedPermissions: ActionBasedPermissions,
  searchType?: SearchTypeKeys
) => {
  const foundRole =
    searchType === SearchTypeKeys.customer
      ? customerViewRoleMapping.find((mapping) =>
          isEqual(mapping.actionBasedPermissions, actionBasedPermissions)
        )
      : payerViewRoleMapping.find((mapping) =>
          isEqual(mapping.actionBasedPermissions, actionBasedPermissions)
        )
  if (!foundRole) {
    return RoleEnum.NO_ROLE
  } else {
    return foundRole.role
  }
}

export const getPermissionsFromViewMapping = (role: RoleEnum, searchType: SearchTypeKeys) => {
  const foundRole =
    searchType === SearchTypeKeys.customer
      ? customerViewRoleMapping.find((mapping) => mapping.role === role)
      : payerViewRoleMapping.find((mapping) => mapping.role === role)
  return foundRole?.actionBasedPermissions
}

export const useRoleMapping = (
  toggles: ActionBasedPermissions,
  searchType: SearchTypeKeys | undefined,
  roleConfigurationList: RoleConfiguration[]
) => {
  const roleType = filterRoleFromViewMapping(toggles, searchType)
  const roleConfig = roleConfigurationList.find((roleConfig) => roleConfig.roleType === roleType)
  const hasProjectsEnabled = !!roleConfig?.dataScopes.find(
    (scope) => scope.dataScope === 'projectIds'
  )
  const hasSitesEnabled = !!roleConfig?.dataScopes.find((scope) => scope.dataScope === 'siteIds')

  return {
    hasProjectsEnabled,
    hasSitesEnabled,
    roleType
  }
}
