import {TFunction} from 'i18next'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {SelectItem, Typeahead} from './components'
import {useSites} from './hooks'
import {Site, SiteSearchParams} from './types'
import {useTrackFilterEvents} from '../../TrackEvents/hubFilterEvents'
import {PageNames} from '../../common/constants'

interface SitesFilterType {
  customerId: string
  limit?: number
  site?: Site
  page: PageNames
  setSite: React.Dispatch<React.SetStateAction<Site | undefined>>
}
export const SitesFilter: React.FC<SitesFilterType> = ({
  customerId,
  limit,
  site,
  setSite,
  page
}) => {
  const {t} = useTranslation()
  const [searchterm, setSearchterm] = React.useState<string | undefined>(undefined)
  const {trackSiteFilterEvent} = useTrackFilterEvents()

  const updateValue = (newValue: string) => {
    setSite(undefined)
    setSearchterm(newValue)
    trackSiteFilterEvent(page, undefined)
  }

  const handleSearchAgain = (searchTerm?: string) => {
    if (searchTerm) {
      const trimmedSearchTerm = searchTerm.trim()
      if (trimmedSearchTerm !== '') {
        setSearchterm(trimmedSearchTerm)
      }
    }
  }

  const handleChangeDropdown = (selectedSite?: Site) => {
    if (selectedSite && selectedSite.siteId !== '-1') {
      setSite(selectedSite)
      trackSiteFilterEvent(page, selectedSite?.siteId)
    } else {
      setSearchterm(undefined)
      setSite(undefined)
      trackSiteFilterEvent(page, undefined)
    }
  }

  React.useEffect(() => {
    if (site) {
      setSearchterm(site.siteName)
    }
  }, [site])

  return (
    <SitesTypeahead
      t={t}
      updateValue={updateValue}
      handleChangeDropdown={handleChangeDropdown}
      searchterm={searchterm}
      args={{customerId, searchterm, limit}}
      searchAgain={handleSearchAgain}
    />
  )
}

interface SitesTypeaheadType {
  t: TFunction
  searchterm?: string
  updateValue: (value: string) => void
  handleChangeDropdown: (site?: Site) => void
  args: SiteSearchParams
  searchAgain: (value?: string) => void
}
export const SitesTypeahead: React.FC<SitesTypeaheadType> = ({
  t,
  searchterm,
  updateValue,
  handleChangeDropdown,
  args,
  searchAgain
}) => {
  return (
    <Typeahead
      dataTestId="sites-typeahead"
      label={t('destinationFilter.label')}
      value={searchterm || ''}
      onChange={(e) => updateValue(e.target.value)}
      optionId="siteId"
      optionName="siteName"
      renderOption={(option: Site) => <SelectItem site={option} onLight t={t} />}
      onChangeDropdown={handleChangeDropdown}
      fetchHook={useSites}
      args={args}
      allOptions={t('order.orderDropdown.noSelectionLabel')}
      searchAgain={searchAgain}
    />
  )
}
