import {CheckboxIndetermiateType} from '../../Molecules/CheckboxDropdown'
import {Delivery} from '../../Pages/Finance/Invoices/Delivery.types'
import {Invoice} from '../../Pages/Finance/Invoices/Invoice.types'
import {FilterActionType} from '../Filters'

export const INVOICES = 'INVOICES'
export const INVOICES_CLEAR = 'INVOICES/CLEAR'
export const INVOICES_FILTERS = 'INVOICES/FILTERS'
export const INVOICES_LOAD_MORE = 'INVOICES/LOAD_MORE'
export const INVOICES_SET_SORT_KEY = 'INVOICES/SET_SORT_KEY'
export const INVOICES_SET_EXPANDED = 'INVOICES/SET_EXPANDED'
export const INVOICES_FETCH_REQUEST = 'INVOICES/FETCH_REQUEST'
export const INVOICES_FETCH_SUCCESS = 'INVOICES/FETCH_SUCCESS'
export const INVOICES_FETCH_FAILURE = 'INVOICES/FETCH_FAILURE'
export const INVOICES_MULTI_DOWNLOAD_TOGGLE_INDETERMINATE =
  'INVOICES/MULTI_DOWNLOAD_TOGGLE_INDETERMINATE'
export const INVOICES_MULTI_DOWNLOAD_TOGGLE_SWITCH_MULTIPLE_DOWNLOAD =
  'INVOICES/MULTI_DOWNLOAD_TOGGLE_IS_MULTIPLE_DOWNLOAD_ACTIVE'
export const INVOICES_MULTI_DOWNLOAD_TOGGLE_CHECKBOX_ALL =
  'INVOICES/MULTI_DOWNLOAD_TOGGLE_CHECKBOX_ALL'
export const INVOICES_MULTI_DOWNLOAD_TOGGLE_CHECKBOX_INVOICE =
  'INVOICES/MULTI_DOWNLOAD_TOGGLE_CHECKBOX_INVOICE'
export const INVOICES_MULTI_DOWNLOAD_TOGGLE_CHECKBOX_TABLEROW =
  'INVOICES/MULTI_DOWNLOAD_TOGGLE_CHECKBOX_TABLEROW'
export const INVOICES_MULTI_DOWNLOAD_TOGGLE_CHECKBOX_DELIVERY =
  'INVOICES/MULTI_DOWNLOAD_TOGGLE_CHECKBOX_DELIVERY'
export const INVOICES_MULTI_DOWNLOAD_CHECK_ALL_INVOICES =
  'INVOICES/MULTI_DOWNLOAD_CHECK_ALL_INVOICES'
export const INVOICES_MULTI_DOWNLOAD_CHECK_ALL_DELIVERIES =
  'INVOICES/MULTI_DOWNLOAD_CHECK_ALL_DELIVERIES'
export const INVOICES_MULTI_DOWNLOAD_ADD_DELIVERY = 'INVOICES/MULTI_DOWNLOAD_ADD_DELIVERY'

export const DELIVERIES_INVOICES_FETCH_REQUEST = 'INVOICES/DELIVERIES/FETCH_REQUEST'
export const DELIVERIES_INVOICES_FETCH_SUCCESS = 'INVOICES/DELIVERIES/FETCH_SUCCESS'
export const DELIVERIES_INVOICES_FETCH_FAILURE = 'INVOICES/DELIVERIES/FETCH_FAILURE'
export const INVOICES_SET_RESULTS_PER_PAGE = 'INVOICES_SET_RESULTS_PER_PAGE'

export interface ToggleIsMultipleDownloadActive {
  type: typeof INVOICES_MULTI_DOWNLOAD_TOGGLE_SWITCH_MULTIPLE_DOWNLOAD
}

export interface ToggleIndeterminate {
  type: typeof INVOICES_MULTI_DOWNLOAD_TOGGLE_INDETERMINATE
}

export interface AddInvoiceDelivery {
  type: typeof INVOICES_MULTI_DOWNLOAD_ADD_DELIVERY
  deliveryId: string
}

export interface ToggleAllChecked {
  type: typeof INVOICES_MULTI_DOWNLOAD_TOGGLE_CHECKBOX_ALL
  checked: CheckboxIndetermiateType
}

export interface CheckAllInvoices {
  type: typeof INVOICES_MULTI_DOWNLOAD_CHECK_ALL_INVOICES
}
interface SetResultsPerPage {
  type: typeof INVOICES_SET_RESULTS_PER_PAGE
  payload: number
}
export interface CheckAllDeliveries {
  type: typeof INVOICES_MULTI_DOWNLOAD_CHECK_ALL_DELIVERIES
}

export interface ToggleTableRowChecked {
  type: typeof INVOICES_MULTI_DOWNLOAD_TOGGLE_CHECKBOX_TABLEROW
  rowId: string
  checked: CheckboxIndetermiateType
}
export interface ToggleInvoiceChecked {
  type: typeof INVOICES_MULTI_DOWNLOAD_TOGGLE_CHECKBOX_INVOICE
  invoiceId: string
  checked: boolean
}

export interface ToggleDeliveryChecked {
  type: typeof INVOICES_MULTI_DOWNLOAD_TOGGLE_CHECKBOX_DELIVERY
  deliveryId: string
  invoiceId: string
  checked: boolean
}

export interface InvoiceAction {
  type: string
  payload?: any
  params?: any
  invoiceId?: string
  expanded?: boolean
  receivedAt?: Date
}

export interface FetchDeliveriesInvoiceRequestAction {
  type: typeof DELIVERIES_INVOICES_FETCH_REQUEST
  invoiceId?: string
  customerId?: string
  deliveryNumber?: string
  customerDeliveryNumber?: string
}

export interface FetchDeliveriesInvoiceSuccessAction {
  type: typeof DELIVERIES_INVOICES_FETCH_SUCCESS
  invoiceId?: string
  customerId?: string
  deliveryNumber?: string
  customerDeliveryNumber?: string
  payload: Delivery[]
}

export interface FetchDeliveriesInvoiceFailureAction {
  type: typeof DELIVERIES_INVOICES_FETCH_FAILURE
  invoiceId: string
  payload: any
  error: any
}

export type DeliveriesInvoiceActionTypes =
  | FetchDeliveriesInvoiceRequestAction
  | FetchDeliveriesInvoiceSuccessAction
  | FetchDeliveriesInvoiceFailureAction

export interface DeliveriesStateType {
  loading: boolean
  byId: {[key: string]: Delivery}
  allIds: string[]
  error: any
}

export interface ClearAction extends InvoiceAction {
  type: typeof INVOICES_CLEAR
}

export interface SetSortKeyAction extends InvoiceAction {
  type: typeof INVOICES_SET_SORT_KEY
  // TODO: add type to payload
  payload: string
}

export interface LoadMoreAction extends InvoiceAction {
  type: typeof INVOICES_LOAD_MORE
}

export interface SetExpandedAction extends InvoiceAction {
  type: typeof INVOICES_SET_EXPANDED
  invoiceId: string
  expanded: boolean
}

export interface FetchInvoicesRequestAction extends InvoiceAction {
  type: typeof INVOICES_FETCH_REQUEST
  // TODO: Add type to params
  params: any
}

export interface FetchInvoicesSuccessAction extends InvoiceAction {
  type: typeof INVOICES_FETCH_SUCCESS
  params: any
  payload: Invoice[]
  showLoadMore: boolean
  totalCount: number
  totalGrossValue: number
  receivedAt: Date
}

export interface FetchInvoicesFailureAction extends InvoiceAction {
  type: typeof INVOICES_FETCH_FAILURE
}

export type InvoicesActionTypes =
  | ClearAction
  | SetSortKeyAction
  | LoadMoreAction
  | SetExpandedAction
  | FetchInvoicesRequestAction
  | FetchInvoicesSuccessAction
  | FetchInvoicesFailureAction
  | FilterActionType
  | ToggleIsMultipleDownloadActive
  | ToggleAllChecked
  | CheckAllInvoices
  | CheckAllDeliveries
  | ToggleInvoiceChecked
  | ToggleTableRowChecked
  | ToggleDeliveryChecked
  | DeliveriesInvoiceActionTypes
  | SetResultsPerPage

export interface NormalizedInvoiceStoreType {
  [key: string]: any
}

export interface InvoicesStateType {
  byId: NormalizedInvoiceStoreType
  allIds: string[]
  expandedIds: string[]
  checkedInvoiceTableRows: string[]
  checkedInvoiceIds: string[]
  uncheckedDeliveryIds: string[]
  isMultipleDownloadActive: boolean
  sortOrder: {key: string; asc: boolean}
  skip: number
  limit: number
  showLoadMore: boolean
  isFetching: boolean
  // used for multiple download because of backend limitations (deliveries only available on expand)
  isOnlyInvoices: boolean
  isOnlyDeliveries: boolean
  // TODO: Add type to filters
  filters: any
  defaultFilters: any
  totalCount?: number
  totalGrossValue?: number
}
