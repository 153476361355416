import {ErrorPaper, SelectDropdown, Typography} from '@hconnect/uikit'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {makeStyles} from 'tss-react/mui'

import {Plant} from './Plants.types'

const useStyles = makeStyles()((theme) => ({
  selectDropdown: {
    [theme.breakpoints.down('md')]: {
      marginTop: '10px',
      width: '100%',

      '& button': {
        width: '100%',
        maxWidth: 'unset'
      }
    }
  }
}))

interface SelectItemType {
  plantName: string
  street: string | undefined | null
  postalCode: string | undefined | null
  city: string | undefined | null
  onLight?: boolean
}
const SelectItem: React.FC<SelectItemType> = ({plantName, street, postalCode, city, onLight}) => (
  <>
    <Typography component="div" variant="body1" color={onLight ? 'textPrimary' : 'textSecondary'}>
      {plantName}
    </Typography>
    <Typography
      variant="caption"
      customColor={onLight ? 'textPrimarySoft' : 'textSecondarySoft'}
      dangerouslySetInnerHTML={{
        __html: `${street}<br>${postalCode} ${city}`
      }}
    />
  </>
)

interface PlantsDropdownType {
  error: any
  plants: Plant[]
  selectedPlantId?: string
  props?: any
  label?: string
  onChange?: any
  noSelectionLabel?: string
  onLight?: boolean
}
export const PlantsDropdown: React.FC<PlantsDropdownType> = ({
  error,
  plants,
  selectedPlantId,
  onLight,
  onChange,
  ...props
}) => {
  const {t} = useTranslation()
  const {classes} = useStyles()

  const selectedItem = plants.find((site: Plant) => site.plantId === selectedPlantId)
  return (
    <div className={classes.selectDropdown}>
      <SelectDropdown
        label={t('certificate.plantsDropdown.label')}
        noSelectionLabel={t('certificate.plantsDropdown.noSelectionLabel')}
        showError={!!error}
        errorRender={() => <ErrorPaper variant="primaryDark" />}
        data-test-id="certificates-filter-plant-dropdown"
        options={plants}
        onChange={onChange}
        renderItem={(item: Plant) => (
          <SelectItem
            plantName={item.plantName}
            postalCode={item.postalCode}
            street={item.street}
            city={item.city}
            onLight={onLight}
          />
        )}
        stringifyItem={(item: Plant) => item.plantName}
        selectedItem={selectedItem}
        onLight={onLight}
        {...props}
      />
    </div>
  )
}
