import {TFunction} from 'i18next'

import {LinkMedia, LinkMediaType} from '../../Pages/Finance/Invoices/Delivery.types'

export enum Entity {
  invoice = 'invoice',
  order = 'order',
  orderConfirmation = 'orderConfirmation',
  delivery = 'delivery',
  millTestReports = 'millTestReports',
  statement = 'statement',
  materialTest = 'materialTest'
}

export type EntityType = keyof typeof Entity

export enum EntityPage {
  invoices = 'invoices',
  orders = 'orders',
  testsCertificates = 'testsCertificates',
  statements = 'statements',
  rmc = 'rmc'
}

export type EntityPageType = keyof typeof EntityPage

export enum FileExtension {
  pdf = 'pdf',
  tif = 'tif',
  jpg = 'jpg'
}

export type FileExtensionType = keyof typeof FileExtension

/**
 * Function returning extension for files based on standard media type
 *
 * @param linkMedia - standard media type: application/pdf, application/octet-stream, etc
 *
 * @returns pdf | tif | jpg
 */
export const getFileTypeExt = (linkMedia?: LinkMediaType | null): FileExtensionType => {
  if (!linkMedia) {
    // fallback to pdf
    return FileExtension.pdf
  }

  switch (linkMedia) {
    case LinkMedia.Pdf:
      return FileExtension.pdf

    case LinkMedia.OctetStream:
      return FileExtension.tif

    case LinkMedia.Tif:
      return FileExtension.tif

    case LinkMedia.JPEG:
      return FileExtension.jpg

    default:
      return FileExtension.pdf
  }
}

interface Specifics {
  plantName?: string
  materialDescription?: string
  date?: string
  entityNumber?: string | null
}
/**
 * Function for creating file name
 *
 * @param page - page from which you are downloading files (invoice, order, millTestReports)
 * @param entity - what you are downloading (invoice, delivery, etc)
 * @param media - type of media response (pdf, octet-stream, etc)
 * @param t - i18next translator function
 * @param specifics - entityNumber, plant name, material and date
 *
 * @returns string
 */

type MockFunction = () => string
export const getDocumentFileName = (
  page: EntityPageType,
  entity: EntityType,
  media: LinkMediaType | null | undefined,
  t: TFunction | MockFunction,
  specifics?: Specifics
  // eslint-disable-next-line max-params
): string => {
  const divider = '_'
  const fileType = getFileTypeExt(media)
  const fileSuffix = `documents.${page}.${entity}`
  const entityNumber = specifics?.entityNumber
  const fileName = `${entityNumber}${divider}${t(fileSuffix)}.${fileType}`

  switch (entity) {
    case Entity.invoice:
      return fileName

    case Entity.delivery:
      return fileName

    case Entity.statement:
      return fileName

    case Entity.millTestReports: {
      if (!specifics) {
        return `${t(fileSuffix)}.${fileType}}`
      }
      const {plantName, materialDescription, date} = specifics
      return `${t(
        fileSuffix
      )}${divider}${plantName}${divider}${materialDescription}${divider}${date}.${fileType}`
    }

    default:
      return fileName
  }
}
