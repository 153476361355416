import {Branding} from '@hconnect/common/branding/Branding.types'
import {getBranding, getBrandIdentifier} from '@hconnect/common/branding/Branding.utils'

import {BrandingActionTypes, SET_BRANDING} from './Action.types'

export const setBranding = (): BrandingActionTypes => {
  const brandingIdentifier: string = getBrandIdentifier()

  const data: Branding = getBranding(brandingIdentifier)

  return {
    type: SET_BRANDING,
    data
  }
}
