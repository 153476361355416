import {trackEventWithBrowserProps} from '@hconnect/common/logging/Analytics'
import {TFunction} from 'i18next'

// import {trackEventWithBrowserProps} from '../../../logging/Analytics'

export const handleMobileShare = async (
  setIsSnackbarOpened: (isOpened: boolean) => void,
  loggedInUserId: string,
  link: string,
  t: TFunction<'translation'>,
  roleRequestId?: string
) => {
  const shareLinkData = {
    title: t('invitations.shareTitle'),
    description: t('invitations.shareDescription'),
    url: link
  }
  if (navigator.share) {
    try {
      await navigator.share(shareLinkData)
    } catch (error) {
      console.log('Something went wrong.')
    }
  } else if (link) {
    await navigator.clipboard.writeText(link)
    setIsSnackbarOpened(true)

    trackEventWithBrowserProps('inviteCopyLink', {
      product: 'hub',
      date: new Date().toISOString(),
      roleRequestId,
      userId: loggedInUserId
    })
  }
}
