import {ErrorPaper, Page, Typography} from '@hconnect/uikit'
import {Box, Button, LinearProgress} from '@material-ui/core'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useInviteReceive} from '../hooks/useInviteReceive'
import {Step} from '../Invite.types'

import {InviteReceiveInfo} from './components/InviteReceiveInfo'
import {getErrorMessage} from './errors/getErrorMessage'
import {getTitleMessage} from './errors/getTitleMessage'

interface ReceiveInvitePageProperties {
  onFetchPermissions: (userId: string) => void
  onFetchCustomers: () => void
  userId: string
  userCountryId: string
}

export const ReceiveInvite: React.FC<ReceiveInvitePageProperties> = ({
  onFetchPermissions,
  onFetchCustomers,
  userId,
  userCountryId
}) => {
  const {t} = useTranslation()

  const {data, isLoading, mutate, step, error} = useInviteReceive(
    userCountryId,
    userId,
    onFetchPermissions,
    onFetchCustomers
  )

  const goToOverview = () => {
    onFetchPermissions(userId)
    window.location.href = window.location.origin
  }

  const [renderMessage, subTitleMessage] = getTitleMessage(t, step)
  const errorMessage = getErrorMessage(t, step, error)

  const succeeded = step === Step.SUCCESS

  return (
    <>
      <Page
        data-test-id="invitation-page"
        title={
          <>
            <div>{renderMessage}</div>
            {subTitleMessage}
          </>
        }
      >
        {succeeded &&
          data?.map((roleRequest) => (
            <InviteReceiveInfo key={roleRequest.id} roleRequest={roleRequest} />
          ))}
        {errorMessage && (
          <Box mt={2}>
            <ErrorPaper
              data-test-id="errorMessage"
              errorText={errorMessage}
              showRetry={true /* tep === Step.ERROR*/}
              onRetry={() => mutate()}
            />
          </Box>
        )}

        {(succeeded || step === Step.ERROR_NO_CHANGE) && (
          <Box mt={2}>
            <Button color="primary" onClick={() => goToOverview()}>
              {t('invite.receive.next')}
            </Button>
          </Box>
        )}

        {isLoading && (
          <Box width={200} mt={2}>
            <Typography customColor="textSecondarySoft">
              {t('invite.receive.updatingProfile')}
            </Typography>
            <LinearProgress data-test-id="invite-receive-loader" />
          </Box>
        )}
      </Page>
    </>
  )
}
