import {OrgUnit, RoleConfiguration} from '@hconnect/apiclient'
import {useQuery} from 'react-query'

import {getRequestPathData} from './utils'

const fetchOrgUnitConfiguration = ({queryKey}) => {
  const [endpoint, {businessLine, countryId}] = queryKey

  const queryParams = new URLSearchParams({
    businessLine,
    country: countryId
  })

  return getRequestPathData<OrgUnit[]>(`${endpoint}?${queryParams.toString()}`)
}

const fetchRolesConfiguration = ({queryKey}) => {
  const [endpoint, {userId}] = queryKey

  const queryParams = new URLSearchParams({
    userId
  })

  return getRequestPathData<RoleConfiguration[]>(`${endpoint}?${queryParams.toString()}`)
}

export const useRoleConfiguration = (userId: string) => {
  return useQuery(['configuration/roles', {userId}], fetchRolesConfiguration)
}

export const useOrgUnitConfiguration = (businessLine: string, countryId: string) => {
  return useQuery(
    ['configurations/orgUnits', {businessLine, countryId}],
    fetchOrgUnitConfiguration,
    {
      retry: false
    }
  )
}
