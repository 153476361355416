/* eslint-disable complexity */
import {ErrorPaper, lastThirtyDays, MOMENTJS_DATE_FORMAT} from '@hconnect/uikit'
import {Box, LinearProgress} from '@material-ui/core'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {connect} from 'react-redux'

import {Widget} from '../../Molecules/Widget/Widget'
import {Payment, usePayments} from '../../Pages/Finance/Payments'
import {AppState} from '../../Root.store'
import {Customer} from '../Customers/Customers.types'
import {useFeaturesState} from '../Features'
import {selectPayers, selectSelectedPayerFilter, Payer} from '../Payers'

import {CashBalance as CashBalanceComponent} from './Cashbalance'
import {CashBalance} from './CashBalance.types'
import {MinifiedCashBalanceWidget} from './components'
import {useCreditManagement} from './hooks'

interface CashBalanceWidgetProps {
  isPayersFetching: boolean
  isCustomersFetching: boolean
  payer: Payer | null
  customer: Customer | null
  minified?: boolean
  onRetry?: (payerId?: string) => void
  smallScreen?: boolean
  collapse?: boolean
  onDark?: boolean
}

const CashBalanceWidget: React.FC<CashBalanceWidgetProps> = ({
  isPayersFetching,
  isCustomersFetching,
  payer,
  minified = false,
  customer,
  smallScreen = false,
  onDark = false,
  collapse
}) => {
  const {getFeature} = useFeaturesState()
  const [startDate, endDate] = lastThirtyDays()
  const isPaymentsEnabled = getFeature('Payments')
  const enabledPayments = Boolean(isPaymentsEnabled && payer)
  const {
    data: cashBalanceItems,
    isFetching,
    refetch
  } = useCreditManagement({payerId: payer?.payerId, enabled: true})
  const {data: payments, isFetching: isPaymentsFetching} = usePayments(
    payer?.payerId,
    startDate.format(MOMENTJS_DATE_FORMAT),
    endDate.format(MOMENTJS_DATE_FORMAT),
    10,
    'postingDate desc',
    enabledPayments
  )
  const {t} = useTranslation()
  const isLoading = isFetching || isPayersFetching || isPaymentsFetching || isCustomersFetching
  if (minified) {
    return (
      <MinifiedCashBalanceWidget
        cashBalances={cashBalanceItems}
        loading={isLoading}
        payerName={payer?.payerName}
        smallScreen={smallScreen}
        collapse={collapse}
      />
    )
  }
  if (isLoading && !minified) {
    return (
      <Widget
        title={t('overview.widgetTitles.accountBalance', {payerNumber: '', payerName: ''})}
        data-test-id="widget-cash-balance"
      >
        <LinearProgress data-test-id="widget-cash-balance-loader" />
      </Widget>
    )
  }

  if (!payer && !isLoading && !minified) {
    return (
      <Widget title={t('overview.widgetTitles.noPayer')} data-test-id="widget-cash-balance">
        <ErrorPaper data-test-id="cash-balance-widget-error" showRetry={false} />
      </Widget>
    )
  }

  if (!minified && cashBalanceItems && cashBalanceItems.length > 0) {
    const payerNumber = payer?.payerNumber || ''
    const customerName = customer?.customerName || ''
    const payerName = payer?.payerName || ''
    const isMultipleCompanies = cashBalanceItems.length > 1
    const payerPayments: Payment[] =
      payer && payments?.payerPayments?.length ? payments.payerPayments : []
    return (
      <>
        {cashBalanceItems.map((item: CashBalance) => {
          const {
            currency,
            creditLimit,
            receivables,
            salesValue,
            creditExposure,
            deposit,
            remainingCredit,
            total,
            exceededLimit,
            company,
            deliveredNotBilled = 0,
            orderedNotDelivered = 0
          } = item
          const subTitle = minified
            ? payerNumber
            : isMultipleCompanies
            ? t('overview.widgetTitles.withCompany', {companies: company?.companyName})
            : null
          const {companyCode} = company ?? {}
          return (
            <Box
              data-test-id="widget-cash-balance-wrapper"
              key={`widget-cash-balance-${item.payerId}`}
            >
              <Widget
                key={`${payer?.payerId || ''}-${companyCode}`}
                title={
                  minified
                    ? t('overview.widgetTitles.accountBalance', {payerName, payerNumber: ''})
                    : t('overview.widgetTitles.accountBalance', {payerName, payerNumber})
                }
                subTitle={subTitle}
                data-test-id="widget-cash-balance"
                tooltipInfo={t('overview.cashBalance.payerTooltip', {customerName, payerName})}
                minified={minified}
                loading={isLoading}
              >
                {smallScreen ? (
                  <MinifiedCashBalanceWidget
                    cashBalances={[item]}
                    loading={isLoading}
                    payerName={payer?.payerName}
                    smallScreen={smallScreen}
                    onDark={onDark}
                    collapse={collapse}
                  />
                ) : (
                  <CashBalanceComponent
                    creditLimit={creditLimit}
                    deposit={deposit}
                    notYetInvoicedAmount={salesValue}
                    deliveredNotBilled={deliveredNotBilled}
                    orderedNotDelivered={orderedNotDelivered}
                    openAmount={receivables}
                    currency={currency}
                    creditExposure={creditExposure}
                    remainingCredit={remainingCredit}
                    payments={payerPayments}
                    company={companyCode || ''}
                    total={total || 0}
                    exceededLimit={exceededLimit || 0}
                    minified={minified}
                  />
                )}
              </Widget>
            </Box>
          )
        })}
      </>
    )
  }
  return (
    <Widget title={t('overview.widgetTitles.accountBalance')} data-test-id="widget-cash-balance">
      <ErrorPaper data-test-id="cash-balance-widget-error" onRetry={() => refetch()} />
    </Widget>
  )
}

const mapStateToProps = (state: AppState) => ({
  isPayersFetching: selectPayers(state).isFetching,
  payer: selectSelectedPayerFilter(state)
})

// eslint-disable-next-line import/no-default-export
export default connect(mapStateToProps)(CashBalanceWidget)
