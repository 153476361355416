import {DeliveryStatus} from '../../Pages/Finance/Invoices/Delivery.types'

export enum DeliveryStatusAggregate {
  Open = 'open',
  WaitingForLoad = 'waitingforload',
  Loading = 'loading',
  Loaded = 'loaded',
  InTransit = 'intransit',
  WaitingForUnload = 'waitingforunload',
  Unloading = 'unloading',
  Completed = 'completed',
  Cancelled = 'cancelled',
  Returned = 'returned',
  Invoiced = 'invoiced'
}

export const DefaultDeliveryStatusAggregate = DeliveryStatusAggregate.Open

// eslint-disable-next-line complexity
export const statusFromDeliveryStatus = (
  deliveryStatus: DeliveryStatus,
  hasInvoicePermission: boolean
) => {
  switch (deliveryStatus) {
    case DeliveryStatus.Open:
      return DeliveryStatusAggregate.Open

    case DeliveryStatus.WaitingForLoad:
      return DeliveryStatusAggregate.WaitingForLoad

    case DeliveryStatus.Loading:
      return DeliveryStatusAggregate.Loading

    case DeliveryStatus.Loaded:
      return DeliveryStatusAggregate.Loaded

    case DeliveryStatus.InTransit:
      return DeliveryStatusAggregate.InTransit

    case DeliveryStatus.WaitingForUnload:
      return DeliveryStatusAggregate.WaitingForUnload

    case DeliveryStatus.Unloading:
      return DeliveryStatusAggregate.Unloading

    case DeliveryStatus.Completed:
    // fall through

    case DeliveryStatus.Unloaded:
      return DeliveryStatusAggregate.Completed

    case DeliveryStatus.Cancelled:
      return DeliveryStatusAggregate.Cancelled

    case DeliveryStatus.Invoiced:
      return hasInvoicePermission
        ? DeliveryStatusAggregate.Invoiced
        : DeliveryStatusAggregate.Completed

    case DeliveryStatus.Returned:
      return DeliveryStatusAggregate.Returned

    default:
      return DefaultDeliveryStatusAggregate
  }
}
