import {
  PrimaryCell,
  SecondaryCell,
  TextButton,
  dateFormatter,
  isoDateFormatter
} from '@hconnect/uikit'
import EditIcon from '@material-ui/icons/Edit'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {MaterialCertificate} from '../types'
interface ManageCertificateColumnnsType {
  handleEditClick: (item: MaterialCertificate) => void
}
export const ManageCertificateColumnns = ({handleEditClick}: ManageCertificateColumnnsType) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()

  return [
    {
      key: 'materialName',
      label: t('certificate.columns.cementType'),
      sortable: false,
      flex: 3,
      customTemplate: (item: MaterialCertificate) => (
        <SecondaryCell>{item.materialNumber}</SecondaryCell>
      )
    },

    {
      key: 'certificationDate',
      label: t('certificate.columns.lastUpdate'),
      sortable: false,
      flex: 2,
      customTemplate: (item: MaterialCertificate) => (
        <PrimaryCell>
          {dateFormatter(
            isoDateFormatter(item.materialCertifications[0].certificationDate),
            language
          )}
        </PrimaryCell>
      )
    },
    {
      key: 'certificationLetter',
      label: t('certificate.columns.editCertification'),
      sortable: false,
      align: 'right',
      flex: 2,
      customTemplate: (item: MaterialCertificate) => (
        <PrimaryCell>
          <TextButton
            variant="text"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.preventDefault()
              event.stopPropagation()
              handleEditClick(item)
            }}
          >
            <EditIcon />
          </TextButton>
        </PrimaryCell>
      )
    }
  ].filter(Boolean)
}
