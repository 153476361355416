import {Typography, Grid, dateFormatter} from '@hconnect/uikit'
import {Box, Divider, makeStyles} from '@material-ui/core'
import moment from 'moment'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {DefaultSettings} from '../../Invite.types'

import {NumberInput} from './NumberInput'

type Props = {
  linkValidDays?: number
  setLinkValidDays: (limit?: number) => void
  linkUsageLimit?: number
  setLinkUsageLimit: (limit?: number) => void
  defaultSettings: DefaultSettings
  isLinkUsageLimitValid: boolean
  isLinkValidDaysValid: boolean
}

const useStyles = makeStyles(() => ({
  smallText: {
    fontSize: '0.75em'
  },
  fullHeight: {
    height: '100%'
  }
}))

export const RedemptionSettings: React.FC<Props> = ({
  linkValidDays,
  setLinkValidDays,
  linkUsageLimit,
  setLinkUsageLimit,
  defaultSettings,
  isLinkUsageLimitValid,
  isLinkValidDaysValid
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const classes = useStyles()

  return (
    <Box>
      <Typography gutterBottom>{t('invite.create.linkHeader')}</Typography>
      <Divider color="soft" />
      <Box my={5}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={10}>
                <Typography variant="body1" component="p">
                  {t('invite.create.inTheNext')} ({t('invite.create.days')})
                </Typography>
                <Typography className={classes.smallText} component="p">
                  {dateFormatter(moment().add(linkValidDays, 'days'), language)}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <NumberInput
                  error={!isLinkValidDaysValid}
                  value={linkValidDays}
                  min={1}
                  max={defaultSettings.maxExpireDays}
                  onChange={setLinkValidDays}
                  data-test-id="redemption-settings-maxExpireDays"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={10}>
                <Typography variant="body1" component="p">
                  {t('invite.create.linkCanBeUsed')}
                </Typography>
                <Typography className={classes.smallText} component="p">
                  {t('invite.create.max', {max: defaultSettings.maxUsageLimit})}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <NumberInput
                  error={!isLinkUsageLimitValid}
                  value={linkUsageLimit}
                  min={1}
                  max={defaultSettings.maxUsageLimit}
                  onChange={setLinkUsageLimit}
                  data-test-id="redemption-settings-maxUsageLimit"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
