export interface Site {
  siteId: string
  siteNumber: string
  customerId: string
  siteName: string
  street: string
  postalCode: string
  city: string
  state: string
  country: string
  orgUnitId: string
  lat?: string
  lng?: string
}

export interface SiteSearchParams {
  customerId: string
  searchterm?: string
  limit?: number
}

export interface AllOptionsType {
  label: string
  id: number
}
