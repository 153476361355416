import {Site, Project} from '@hconnect/apiclient'
import {CircularProgress} from '@material-ui/core'
import React from 'react'

import {ProjectAndSitesTableProps, ProjectsSubSitesSelection} from './ProjectsSubSitesSelection'

type Props = {
  projectsIds: string[]
  sitesIds: string[]
  loading: boolean
  hasSitesEnabled: boolean
  hasProjectsEnabled: boolean
  applyProjectsFilter: (availableProjects: Project[]) => Project[]
  applySitesFilter: (availableSites: Site[]) => Site[]
  calcProjects: () => Project[]
  calcSites: () => Site[]
  selectedProjectsAndSites: string[]
  setSelectedProjectsAndSites: (rows: string[]) => void
  rows: ProjectAndSitesTableProps[]
}

export const ProjectsSitesSelection: React.FC<Props> = ({
  projectsIds,
  sitesIds,
  loading,
  hasSitesEnabled = false,
  hasProjectsEnabled = false,
  applyProjectsFilter,
  applySitesFilter,
  calcProjects,
  calcSites,
  selectedProjectsAndSites,
  setSelectedProjectsAndSites,
  rows
}) => {
  if (loading) {
    return (
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <div style={{overflowY: 'auto', overflowX: 'hidden'}}>
      <ProjectsSubSitesSelection
        projects={applyProjectsFilter(calcProjects())}
        sites={applySitesFilter(calcSites())}
        projectsIds={projectsIds}
        sitesIds={sitesIds}
        hasSitesEnabled={hasSitesEnabled}
        hasProjectsEnabled={hasProjectsEnabled}
        selectedProjectsAndSites={selectedProjectsAndSites}
        setSelectedProjectsAndSites={setSelectedProjectsAndSites}
        rows={rows}
      />
    </div>
  )
}
