import NotificationsIcon from '@mui/icons-material/Notifications'
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined'
import {TFunction} from 'i18next'

export const SubscriptionStatus = ({t, isSubscribed}: {t: TFunction; isSubscribed: boolean}) => {
  return (
    <div style={{display: 'flex', alignItems: 'center'}}>
      {isSubscribed ? (
        <NotificationsIcon color="secondary" />
      ) : (
        <NotificationsNoneOutlinedIcon color="secondary" />
      )}
      <span>{isSubscribed ? t('certificate.subscribed') : t('certificate.unsubscribed')}</span>
    </div>
  )
}
