import {isEmpty, some} from 'lodash'
import React, {useContext, useState, useReducer, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {useLocation} from 'react-router'

import {useGetSlotConfigurations} from '../../common/react-query/hooks/queries/useGetSlotConfigurations'
import {ShippingType} from '../../OrderIntake/declarations/OrderIntake.enums'
import {
  BulkCementOrderIntakeStateType,
  OrderRequest,
  OrderRequestResponse,
  SlotConfiguration
} from '../../OrderIntake/declarations/types'
import {evaluateFeatureFlag} from '../../OrderIntake/FeatureFlags'
import {useOptionsBase} from '../../OrderIntake/Options'
import {usePermissions} from '../../Permissions'
import {PermissionTypes} from '../../Permissions/permissions.types'
import {useRolesByCustomerId} from '../../Roles'
import {AppState} from '../../Root.store'
import {selectSelectedCustomerFilter} from '../Customers/Customers.selector'
import {useFeaturesState} from '../Features'

import {initialState, orderIntake} from './BulkCementOrderIntake.reducer'
import {
  resetOrderIntake,
  setCollections,
  setDeliveries,
  toggleResetOrderIntake,
  setOrderSummary
} from './OrderIntake.action'

export interface BulkCementOrderIntakeContextInterface extends BulkCementOrderIntakeStateType {
  isCollect: boolean
  isDelivery: boolean
  isFetching: boolean
  isError: boolean
  isNoAvailableContracts: boolean
  slotConfiguration?: SlotConfiguration[]
  resetOrderIntake: () => void
  toggleResetOrderIntake: () => void
  setDeliveries: (payload: OrderRequest[]) => void
  setCollections: (payload: OrderRequest[]) => void
  setOrderSummary: (payload: OrderRequestResponse) => void
}

const BulkCementOrderIntakeContext = React.createContext<BulkCementOrderIntakeContextInterface>({
  isCollect: false,
  isDelivery: false,
  isFetching: false,
  isError: false,
  isNoAvailableContracts: false,
  slotConfiguration: [],
  resetOrderIntake,
  toggleResetOrderIntake,
  setDeliveries,
  setCollections,
  setOrderSummary,
  ...initialState
})

BulkCementOrderIntakeContext.displayName = 'BulkCementOrderIntake'

export const useBulkCementOrderIntake = () => useContext(BulkCementOrderIntakeContext)

export const BulkCementOrderIntakeProvider = ({children}: {children: React.ReactNode}) => {
  const {pathname} = useLocation()
  const isOrderIntake =
    pathname.startsWith('/bulkCementDelivery') ||
    pathname.startsWith('/bulkCementCollection') ||
    pathname.startsWith('/overview') ||
    pathname.startsWith('/orders')
  if (!isOrderIntake) return <>{children}</>
  return <BulkCementOrderIntakeBase>{children}</BulkCementOrderIntakeBase>
}

const BulkCementOrderIntakeBase = ({children}) => {
  const [isCollect, setIsCollect] = useState<boolean>(false)
  const [isDelivery, setIsDelivery] = useState<boolean>(false)
  const customerId = useSelector<AppState, string | undefined>(
    (state) => selectSelectedCustomerFilter(state)?.customerId
  )

  const [slotConfiguration, setSlotConfiguration] = useState<SlotConfiguration[]>([])

  const {normalized, getFeature} = useFeaturesState()
  const applyMaterialEnteredDescription = getFeature('OrderIntakeMaterialEnteredDescription')
  const applyContractItemDescription = getFeature('OrderIntakeContractItemDescription')

  const {data: rolesBySelectedCustomer} = useRolesByCustomerId(customerId)
  const {hasPermission} = usePermissions()

  const orderIntakeFeatureFlag = normalized['OrderIntake']
  const isOrderIntakeAvailableForCurrentCustomer = evaluateFeatureFlag(
    rolesBySelectedCustomer,
    orderIntakeFeatureFlag?.constraints ?? [],
    customerId ?? ''
  )

  const hasOrderIntake =
    hasPermission(PermissionTypes.CHANGE_ORDERS) && isOrderIntakeAvailableForCurrentCustomer
  const customerIdToQuery = hasOrderIntake ? customerId : undefined
  const {
    data: orderRequestOptions,
    isFetching,
    isError
  } = useOptionsBase(
    applyMaterialEnteredDescription,
    applyContractItemDescription,
    customerIdToQuery
  )
  const {data: slotConfigurationData, isLoading: isSlotConfigurationLoading} =
    useGetSlotConfigurations()

  const [state, dispatch] = useReducer(orderIntake, initialState)

  useEffect(() => {
    if (!orderRequestOptions || isFetching) return

    setIsCollect(some(orderRequestOptions, {shippingType: ShippingType.COLLECT}))
    setIsDelivery(some(orderRequestOptions, {shippingType: ShippingType.DELIVER}))
  }, [orderRequestOptions, isFetching])

  useEffect(() => {
    if (!slotConfigurationData || isSlotConfigurationLoading) return

    setSlotConfiguration(slotConfigurationData)
  }, [slotConfigurationData, isSlotConfigurationLoading])

  return (
    <BulkCementOrderIntakeContext.Provider
      value={{
        isCollect,
        isDelivery,
        isFetching,
        isError,
        isNoAvailableContracts: isEmpty(orderRequestOptions),
        slotConfiguration,
        resetOrderIntake: () => dispatch(resetOrderIntake()),
        toggleResetOrderIntake: () => dispatch(toggleResetOrderIntake()),
        setDeliveries: (deliveries) => dispatch(setDeliveries(deliveries)),
        setCollections: (collections) => dispatch(setCollections(collections)),
        setOrderSummary: (orderRequest) => dispatch(setOrderSummary(orderRequest)),
        ...state
      }}
    >
      {children}
    </BulkCementOrderIntakeContext.Provider>
  )
}
