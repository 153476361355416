import {makeStyles} from '@material-ui/core'

export const useInviteStyles = makeStyles((theme) => ({
  trackSwitchBase: {
    height: 22,
    borderRadius: 20,
    backgroundColor: 'white!important'
  },
  enabledTrack: {
    backgroundColor: '#45A7F5!important'
  },
  disabledTrack: {
    backgroundColor: 'grey!important'
  },
  thumb: {
    boxShadow: '4px 0px 4px rgba(0, 0, 0, 0.1)',
    width: 16,
    height: 16,
    margin: 2
  },
  switchRoot: {
    width: '68px'
  },
  enabledSwitchBase: {
    top: 4,
    left: 4
  },
  disabledSwitchBase: {
    top: 4,
    left: 4
  },
  expandIconButton: {
    boxShadow: 'none',
    border: 'none',
    '&:hover': {
      boxShadow: 'none'
    }
  },
  accordionDetails: {
    display: 'block'
  },
  summaryRoot: {
    padding: '0'
  },
  spaceBottom: {
    marginBottom: '80px'
  },
  divider: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  radio: {
    boxShadow: 'none',
    border: 'none',
    '&:hover': {
      boxShadow: 'none',
      border: 'none'
    }
  },
  header: {
    color: 'rgba(0,0,0,0.8)'
  },
  linkButton: {
    flex: 1,
    textTransform: 'none',
    fontWeight: 600,
    background: '#016AD4',
    outline: 'none',
    borderRadius: '6px',
    border: '1px solid #016AD4',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.08)',
    color: '#FFFFFF',
    '&:hover': {
      opacity: 0.6,
      backgroundColor: '#016AD4',
      color: '#FFFFFF'
    },
    maxWidth: '338px',
    margin: '20px 32px 42px 32px'
  },
  linkDetailsButton: {
    flex: 1,
    textTransform: 'none',
    fontWeight: 500,
    background: '#FFFFFF',
    outline: 'none',
    borderRadius: '6px',
    border: '1px solid rgba(0, 39, 77, 0.1)',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.08)',
    color: '#00274D',
    '&:hover': {
      opacity: 0.6,
      backgroundColor: '#016AD4',
      color: '#FFFFFF'
    },
    maxWidth: '338px',
    margin: '20px 32px 42px 32px'
  },
  dropdownStyle: {
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
    width: '340px'
  },
  popperStyle: {
    [theme.breakpoints.down('xs')]: {
      width: '75%'
    },
    width: '340px'
  },
  selectDropdown: {
    backgroundColor: '#FBFBFC',
    border: '1.5px solid rgba(0, 39, 77, 0.15)',
    borderRadius: '4px',
    boxShadow: 'inset 0px 4px 4px rgba(31, 73, 94, 0.08)',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
    width: '340px',
    maxWidth: '340px'
  }
}))
