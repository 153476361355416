import {find} from 'lodash'

import {AppState} from '../../Root.store'
import {Site} from '../Sites'

import {Project} from './Projects.types'

export const selectProjects = (state: AppState) => state.projects

export const selectProject = (state: AppState, projectId: string) =>
  find(selectProjects(state).projects, (project) => project.projectId === projectId)

export const selectSelectedProjectFilter = (state: AppState): Project | null =>
  selectProjects(state).selectedProject

export const selectSelectedSiteFilter = (state: AppState): Site | null =>
  selectProjects(state).selectedSite
