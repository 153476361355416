import {AppState} from '../../Root.store'

import {selectDelivery} from './Deliveries.selector'

export const selectOrders = (state: AppState) => state.order.orders

export const selectOrder = (state: AppState, orderId: string) => selectOrders(state).byId[orderId]

export const selectAllOrders = (state: AppState) =>
  selectOrders(state).allIds.map((orderId: string) => selectOrder(state, orderId))

export const selectOrderLineItems = (state: AppState, orderId: string) =>
  selectOrder(state, orderId).lineItems

export const selectOrderDeliveries = (state: AppState, orderId: string) => {
  const order = selectOrder(state, orderId)
  return order.deliveries
    ? order.deliveries.map((deliveryId: string) => selectDelivery(state, deliveryId))
    : order.deliveries
}
