export const FILTERS = 'FILTERS'
export const FILTERS_SET = 'FILTERS_SET'
export const FILTERS_REMOVE = 'FILTERS_REMOVE'
export const FILTERS_CLEAR_ALL = 'FILTERS_CLEAR_ALL'
export const DEFAULTFILTERS_SET = 'FILTERS_DEFAULT_SET'
export const DEFAULTFILTERS_REMOVE = 'FILTERS_DEFAULT_REMOVE'
export const DEFAULTFILTERS_CLEAR_ALL = 'FILTERS_DEFAULT_CLEAR_ALL'

export interface SetFilterAction {
  type: typeof FILTERS_SET | typeof DEFAULTFILTERS_SET
  context: string
  filterName: string
  filterValue: unknown
}
export interface RemoveFilterAction {
  type: typeof FILTERS_REMOVE | typeof DEFAULTFILTERS_REMOVE
  context: string
  filterName: string
}
export interface ClearFiltersAction {
  type: typeof FILTERS_CLEAR_ALL | typeof DEFAULTFILTERS_CLEAR_ALL
  context: string
}

export type FilterActionType = SetFilterAction | RemoveFilterAction | ClearFiltersAction

export interface FiltersStateType {
  filters: {[key: string]: unknown}
  defaultFilters: {[key: string]: unknown}
}
