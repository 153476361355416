import {api} from '../Api/Api'

import {useFeaturesCheckFetcher} from '@hconnect/common/components/FeaturesCheck'

export const useFeaturesForInvitation = () => {
  const {data, isLoading} = useFeaturesCheckFetcher(api)

  const canSeeInvoices =
    data?.find((feature) => feature.name === 'Finance' && feature.enabled) ?? false
  const canSeeTestsAndCertificates =
    data?.find(
      (feature) =>
        (feature.name === 'RMCTestReports' || feature.name === 'TestReportsMillCertificates') &&
        feature.enabled
    ) ?? false
  const isLegalContactAndCanDoEverythingForCustomer =
    data?.find((feature) => feature.name === 'customerViewShowLegalContact' && feature.enabled) ??
    false
  const isLegalContactAndCanDoEverythingForPayer =
    data?.find((feature) => feature.name === 'payerViewShowLegalContact' && feature.enabled) ??
    false
  const canSeeCreditLimit =
    data?.find((feature) => feature.name === 'CashBalance' && feature.enabled) ?? false

  const isCustomersByNameSearch =
    data?.find((feature) => feature.name === 'CustomersByName' && feature.enabled) ?? false

  return {
    canSeeInvoices,
    canSeeTestsAndCertificates,
    isLegalContactAndCanDoEverythingForCustomer,
    isLegalContactAndCanDoEverythingForPayer,
    canSeeCreditLimit,
    isCustomersByNameSearch,
    isLoading
  }
}
