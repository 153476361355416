import {RoleRequest as RoleRequestQueryInfo} from '@hconnect/apiclient'
import {useBreakPoints} from '@hconnect/uikit'
import {Box, IconButton} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import BlockIcon from '@material-ui/icons/Block'
import ReplyIcon from '@material-ui/icons/Reply'
import React from 'react'
import {useTranslation} from 'react-i18next'

import copyInvitationLink from '../../../assets/copy_invitation_link.svg'
import {useAuth} from '../../../Create/components/authentication/context'
import {getRoleRequestStates, RoleRequestState} from '../../../utils'
import {handleCopyLink} from '../../utils/handleCopyLink'
import {handleMobileShare} from '../../utils/handleMobileShare'

type InvitationsListActionsCellProps = {
  setIsSnackbarOpen: (isOpened: boolean) => void
  setToBeDeactivatedLink: (roleRequestId: string) => void
  roleRequest: RoleRequestQueryInfo
  setSelectedRoleRequestId: (roleRequestId: string) => void
}

const useStyles = makeStyles({
  icon: {
    display: 'inline-block',
    border: 'none',
    boxShadow: 'none',
    padding: 2,
    '&:hover': {
      background: 'transparent'
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1.5rem'
    }
  }
})

export const InvitationListActionsCell = ({
  roleRequest,
  setIsSnackbarOpen,
  setToBeDeactivatedLink,
  setSelectedRoleRequestId
}: InvitationsListActionsCellProps) => {
  const linkStates = getRoleRequestStates(roleRequest)
  const {decodedToken} = useAuth()
  const loggedInUserId = decodedToken?.user_id || ''
  const styles = useStyles()
  const dataScope = roleRequest?.roles[0].dataScope

  const link = `${window.location.origin}/receiveInvite/${dataScope?.countryId}/${roleRequest.id}`
  const {t} = useTranslation()
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)

  const handleDeactivateLink = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    event.preventDefault()
    setSelectedRoleRequestId(roleRequest.id ?? null)
    setToBeDeactivatedLink(roleRequest.id ?? null)
  }
  if (linkStates.includes(RoleRequestState.VALID)) {
    return (
      <Box style={{display: 'flex', justifyContent: 'space-evenly', gap: '6px'}}>
        {!isMobile && (
          <IconButton
            className={styles.icon}
            onClick={(event) =>
              handleCopyLink(event, setIsSnackbarOpen, loggedInUserId, link, roleRequest.id)
            }
          >
            <img src={copyInvitationLink} alt="copy invitation link" />
          </IconButton>
        )}
        {isMobile && (
          <IconButton
            className={styles.icon}
            onClick={() =>
              handleMobileShare(setIsSnackbarOpen, loggedInUserId, link, t, roleRequest.id)
            }
          >
            <ReplyIcon fontSize="medium" style={{color: '#28AAFF', transform: 'scaleX(-1)'}} />
          </IconButton>
        )}
        <IconButton
          className={styles.icon}
          data-test-id={`${roleRequest.id}-action-deactivateLink`}
          onClick={(event) => handleDeactivateLink(event)}
        >
          <BlockIcon fontSize="medium" style={{color: '#CA605F'}} />
        </IconButton>
      </Box>
    )
  }
  return null
}
