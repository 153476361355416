import {Box, Button} from '@material-ui/core'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {ReactComponent as FolderIconSvg} from '../../../Assets/certificateIcons/folder-icon.svg'
import {ReactComponent as RightTickSvg} from '../../../Assets/right-tick.svg'
import {Features} from '../../../Organisms/Features'
import {PermissionTypes, usePermissions} from '../../../Permissions'

import {GenerateCertificateRoute} from './LettersOfCertification/GenerateCertificate'
import {ManageCertificatesRoute} from './LettersOfCertification/ManageCertificates/ManageCertificates'
import {useCementStyles} from './styles'

interface CertificateGenerateButtonsProp {
  materialCertificatesFetching: boolean
}

export const CertificateGenerateButtons: React.FC<CertificateGenerateButtonsProp> = ({
  materialCertificatesFetching
}) => {
  const {t} = useTranslation()
  const classesCement = useCementStyles()
  const history = useHistory()
  const {grantedPermissionTypes} = usePermissions()

  return (
    <Box display="flex" p={1}>
      {grantedPermissionTypes.has(PermissionTypes.CHANGE_MATERIALCERTIFICATES_SNIPPETS) ? (
        <Features name="LetterOfCertification">
          <Button
            data-test-id="update-qc-data"
            color="inherit"
            disabled={materialCertificatesFetching}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation()
              history.push(ManageCertificatesRoute)
            }}
            className={classesCement.buttonItemConatiner}
          >
            <RightTickSvg className={classesCement.svgIcon} />
            {t('certificate.updateQCData')}
          </Button>
          <Box p={1} />
        </Features>
      ) : null}
      <Features name="LetterOfCertification">
        <Button
          color="inherit"
          disabled={materialCertificatesFetching}
          onClick={() => {
            history.push(GenerateCertificateRoute)
          }}
          className={classesCement.buttonItemConatiner}
        >
          <FolderIconSvg className={classesCement.svgIcon} />
          {t('certificate.columns.certificationLetterButtonHeader')}
        </Button>
      </Features>
    </Box>
  )
}
