import {Permission} from '@hconnect/apiclient/src/response/Permission'
import {useQuery} from 'react-query'

import {getRequestPathData} from './utils'

const fetchPermissions = ({queryKey}) => {
  const [endpoint, {userId}] = queryKey

  const queryParams = new URLSearchParams({})

  if (userId) {
    queryParams.append('userId', userId)
  }

  return getRequestPathData<Permission[]>(`/${endpoint}?${queryParams.toString()}`)
}

export const usePermissions = (userId: string) => {
  const permissionsQueryInfo = useQuery(['permissions', {userId}], fetchPermissions)
  const permissionsList = permissionsQueryInfo.data

  const displayCustomerSimpleLookup = permissionsList?.some((permission) => {
    const dataScope = JSON.parse(permission.dataScope)
    return (
      permission.permissionType === 'VIEW_CUSTOMERS' && !(dataScope.customerId || dataScope.payerId)
    )
  })

  return {
    ...permissionsQueryInfo,
    displayCustomerSimpleLookup
  }
}
