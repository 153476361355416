/* eslint-disable @typescript-eslint/no-unused-vars */
import {ERRORS_ADD, ERRORS_CLEAR, ERRORS_REMOVE, ErrorsActionTypes} from './Action.types'

export interface ErrorsAddType {
  key: string
  statusKey?: string
  translationKey: string
  callback?: () => void
  callbackTranslationKey?: string
}

export const errorsAdd =
  (
    {key = '', translationKey, callback, callbackTranslationKey = ''}: ErrorsAddType,
    url?: string,
    status?: string
  ): any =>
  async (dispatch, getState) => {
    dispatch({type: ERRORS_ADD, key, translationKey, callback, callbackTranslationKey})
  }

export const errorsRemove = (key): ErrorsActionTypes => ({type: ERRORS_REMOVE, key})

export const errorsClear = (): ErrorsActionTypes => ({type: ERRORS_CLEAR})
