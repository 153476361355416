import {useMutation, useQueryClient} from 'react-query'

import {api} from '../Api/Api'

export interface RoleRequestJSON {
  appId: string
  validDays: number
  usageLimit: number
  source: number
  roles: Role[]
}

export interface Role {
  roleType: string
  dataScope: DataScope
}

export interface DataScope {
  countryId: string
  orgUnitId: string
  businessLine: string
  customerIds?: string[]
  payerIds?: string[]
  projectIds?: string[]
  projectId?: string
  siteIds?: string[]
  siteId?: string
  orderId?: string
}

export interface RoleRequestResponseJSON {
  roleRequestId: string
}

const createRoleRequest = async ({
  validDays,
  usageLimit,
  roles
}: {
  roles: Role[]
  usageLimit: number
  validDays: number
}): Promise<RoleRequestResponseJSON> => {
  const response = await api.post<RoleRequestResponseJSON>('/roleRequests', {
    validDays,
    usageLimit,
    roles,
    source: 3
  })

  return response?.data
}

const deleteRoleRequest = async (id: string) => {
  await api.delete(`/roleRequests/${id}`)
}

export const useDeleteRoleRequestMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(deleteRoleRequest, {
    onSuccess: () => {
      void queryClient.invalidateQueries('roleRequests')
    }
  })
}

export const useCreateRoleRequestMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(createRoleRequest, {
    onSuccess: () => {
      void queryClient.invalidateQueries('roleRequests')
    }
  })
}

const revokeRoleRequest = async ({
  roleRequestId,
  userId
}: {
  roleRequestId: string
  userId: string
}) => {
  await api.put(`/roleRequests/revoke/${roleRequestId}?userId=${userId}`)
}

export const useRevokeRoleRequestMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(revokeRoleRequest, {
    onSuccess: () => {
      void queryClient.invalidateQueries('roleRequests')
    }
  })
}
