import {FilterBar, FilterBarProps} from '@hconnect/uikit'
import {find} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import {usePermissions} from '../../Permissions'
import {AppState} from '../../Root.store'
import {selectCustomers} from '../Customers'
import {CustomerStateType} from '../Customers/Action.types'
import {Customer} from '../Customers/Customers.types'

interface GenericFilterBarError {
  key: string
  label: string
}

interface Props {
  children: any
  customerSearchByNameEnabled?: boolean
  errors?: GenericFilterBarError[]
}

export const GenericFilterBar: React.FC<FilterBarProps & Props> = ({
  children,
  customerSearchByNameEnabled = false,
  errors = [],
  toolbarProps,
  ...props
}) => {
  const {t} = useTranslation()
  const {displayCustomerSimpleLookup} = usePermissions()

  const customersState: CustomerStateType = useSelector<AppState, CustomerStateType>(
    (state: AppState) => selectCustomers(state)
  )
  const {customers, selectedCustomer, searchString} = customersState

  const isFetching = useSelector<AppState, boolean>((state) => selectCustomers(state).isFetching)

  // FIXME: add type for error
  const customerNumberFetchError = useSelector<AppState, any>(
    (state) => selectCustomers(state).error
  )

  const customerSearchString = useSelector<AppState, string>(
    (state) => selectCustomers(state).searchString
  )

  const customer: Customer | undefined = selectedCustomer
    ? find(customers, {customerId: selectedCustomer.customerId})
    : customers[0]

  const filterBarErrors: GenericFilterBarError[] = [...errors]
  if (!customer && displayCustomerSimpleLookup && !isFetching) {
    if (customerNumberFetchError === null && !searchString) {
      customerSearchByNameEnabled && customers?.length === 0
        ? filterBarErrors.push({
            key: 'errorNoSearchCustomerSelected',
            label: t('filterBar.errorNoSearchCustomerSelected')
          })
        : filterBarErrors.push({
            key: 'errorNoCustomerSelected',
            label: t('filterBar.errorNoCustomerSelected')
          })
    } else if (
      customerSearchByNameEnabled &&
      customers &&
      customers.length === 0 &&
      !!searchString
    ) {
      filterBarErrors.push({
        key: 'errorCustomerResponseEmpty',
        label: t('filterBar.errorCustomerResponseEmpty')
      })
    } else {
      filterBarErrors.push({
        key: 'errorCustomerNotFound',
        label: t('filterBar.errorCustomerNotFound', {CUSTOMER_NUMBER: customerSearchString})
      })
    }
  }
  return (
    <FilterBar
      isLoading={isFetching}
      errors={filterBarErrors}
      toolbarProps={{color: 'transparent', ...toolbarProps}}
      {...props}
    >
      {children}
    </FilterBar>
  )
}
