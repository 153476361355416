export interface DataScope {
  countryId: string
  businessLine?: string
  orgUnitId?: string
  customerIds?: string[]
  payerIds?: string[]
  plantIds?: string[]
}

export enum SearchTypeKeys {
  customer = 'customer',
  payer = 'payer'
}

export enum ActionBasedPermissionKeys {
  canSeeOrderAndDeliveries = 'canSeeOrderAndDeliveries',
  canCreateAndChangeOrders = 'canCreateAndChangeOrders',
  canSeeInvoices = 'canSeeInvoices',
  canSeeTestsAndCertificates = 'canSeeTestsAndCertificates',
  isTheLegalContactOfTheCustomerAndCanDoEverything = 'isTheLegalContactOfTheCustomerAndCanDoEverything',
  canSeeCreditLimitInformationAndMakePayments = 'canSeeCreditLimitInformationAndMakePayments'
}

export interface ActionBasedPermissions {
  [ActionBasedPermissionKeys.canSeeOrderAndDeliveries]?: boolean
  [ActionBasedPermissionKeys.canCreateAndChangeOrders]?: boolean
  [ActionBasedPermissionKeys.canSeeInvoices]?: boolean
  [ActionBasedPermissionKeys.canSeeTestsAndCertificates]?: boolean
  [ActionBasedPermissionKeys.isTheLegalContactOfTheCustomerAndCanDoEverything]?: boolean
  [ActionBasedPermissionKeys.canSeeCreditLimitInformationAndMakePayments]?: boolean
}

export interface CustomerViewRoleMapping {
  role: RoleEnum
  actionBasedPermissions: {
    [ActionBasedPermissionKeys.canSeeOrderAndDeliveries]: boolean
    [ActionBasedPermissionKeys.canCreateAndChangeOrders]: boolean
    [ActionBasedPermissionKeys.canSeeInvoices]: boolean
    [ActionBasedPermissionKeys.canSeeTestsAndCertificates]: boolean
    [ActionBasedPermissionKeys.isTheLegalContactOfTheCustomerAndCanDoEverything]: boolean
  }
}

export interface PayerViewRoleMapping {
  role: RoleEnum
  actionBasedPermissions: {
    [ActionBasedPermissionKeys.canSeeInvoices]: boolean
    [ActionBasedPermissionKeys.canSeeCreditLimitInformationAndMakePayments]: boolean
    [ActionBasedPermissionKeys.isTheLegalContactOfTheCustomerAndCanDoEverything]: boolean
  }
}

export enum Step {
  INIT = 'INIT',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  ERROR_COUNTRY_MISMATCH = 'ERROR_COUNTRY_MISMATCH',
  ERROR_DATASCOPE_CUSTOMER_IDS_EMPTY = 'ERROR_DATASCOPE_CUSTOMER_IDS_EMPTY',
  ERROR_CUSTOMERS_LIST_IS_EMPTY = 'ERROR_CUSTOMERS_LIST_IS_EMPTY',
  ERROR_COULD_NOT_FIND_CUSTOMER_BY_CUSTOMER_ID = 'ERROR_COULD_NOT_FIND_CUSTOMER_BY_CUSTOMER_ID',
  ERROR_NO_CHANGE = 'ERROR_NO_CHANGE',
  ERROR_EXPIRED = 'ERROR_EXPIRED',
  ERROR_LIMIT_REACHED = 'ERROR_LIMIT_REACHED',
  ERROR_DEACTIVATED = 'ERROR_DEACTIVATED',
  ERROR_PERMISSION_MISMATCH = 'ERROR_PERMISSION_MISMATCH',
  ERROR_WRONG_LINK = 'ERROR_WRONG_LINK',
  ERROR_FROM_API = 'ERROR_FROM_API',
  ERROR_NO_SELECTED_CUSTOMER = 'ERROR_NO_SELECTED_CUSTOMER',
  ERROR_MULTIPLE_BUSINESS_LINE_ORG_UNIT = 'ERROR_MULTIPLE_BUSINESS_LINE_ORG_UNIT',
  INVITE_EXPIRED = 'INVITE_EXPIRED',
  INVITE_DEACTIVATED = 'INVITE_DEACTIVATED',
  INVITE_MAX_USAGE_REACHED = 'INVITE_MAX_USAGE_REACHED',
  INVITE_NOT_FOUND = 'INVITE_NOT_FOUND'
}

export enum RoleEnum {
  FINISHER = 'FINISHER',
  ORDER_PLACER = 'ORDER_PLACER',
  ORDER_PLACER_FINANCE = 'ORDER_PLACER_FINANCE',
  SUPERINTEDENT = 'SUPERINTEDENT',
  QA_ENGINEER = 'QA_ENGINEER',
  ACCOUNTANT = 'ACCOUNTANT',
  ACCOUNTANT_NOTESTS = 'ACCOUNTANT_NOTESTS',
  BUSINESS_OWNER = 'BUSINESS_OWNER',
  PLANT_MANAGER = 'PLANT_MANAGER',
  SALES_AGENT = 'SALES_AGENT',
  CONTROLLER = 'CONTROLLER',
  NO_ROLE = ''
}

export interface DefaultSettings {
  defaultExpireDays: number
  defaultExpireDaysLimitedUsage: number
  defaultUsageLimit: number
  maxExpireDays: number
  maxUsageLimit: number
}

export interface RoleRequestRedeem {
  id: number
  userId: string
  expiryDate: string
  autoGenerate: boolean
  roleType: RoleEnum
  dataScope: DataScope
}

export type RoleRequestRedeemResponse = RoleRequestRedeem[]
export interface RoleRequest {
  roleRequestId: string
  countryId: string
}
