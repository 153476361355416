/*
For documentation see Filters/index.js
 */

import {FiltersStateType} from './Action.types'

export const createSelectFilter =
  (filterName: string): any =>
  (state: FiltersStateType) =>
    state.filters[filterName] || state.defaultFilters[filterName]
