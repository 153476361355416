export enum MaterialTestType {
  compressiveStrength = 'compressiveStrength',
  flexuralStrength = 'flexuralStrength',
  indirectTensileStrength = 'indirectTensileStrength',
  spread = 'spread',
  slump = 'slump',
  airContent = 'airContent',
  chloride = 'chloride',
  density = 'density',
  other = 'other'
}

export type MaterialTestTypes = keyof typeof MaterialTestType
export interface MaterialTest {
  testId: string
  orderNumber: string
  deliveryNumber: string
  reportNumber: string
  shippingDate: string
  loadedDate: string
  unloadedDate: string
  customerId: string
  contactInformation: ContactInformation
  materialDescription: string
  customerMaterialDescription: string
  type: MaterialTestTypes
  shippingAddress: ShippingAddress
  sampleTime?: string
  specimens?: MaterialTestSpecimen[] | null
  links?: Link[] | null
}

export interface MaterialTestPreview {
  shippingDate: string | Date
  shippingAddress: ShippingAddress
  contactInformation: ContactInformation
  orderNumber: string
  available: boolean
  items: MaterialTest[]
}

export interface ContactInformation {
  siteContactName: string
  siteContactNumber: string
}
export interface ShippingAddress {
  siteId: string
  siteNumber: string
  customerId: string
  lat: string
  lng: string
  siteName: string
  street: string
  postalCode: string
  city: string
  state: string
  country: string
}

// TODO: consolidate Link for RMC testsx
export interface Link {
  href: string
  media: string
  type: string
  rel: string
}

export interface MaterialTestSpecimen {
  specimenId: string
  testExecutionTime: string
  testAge: number | number[]
  type: MaterialTestTypes
}

export interface MaterialTestShippingAddress {
  siteId: string
  siteNumber: string
  customerId: string
  lat: string
  lng: string
  siteName: string
  street: string
  postalCode: string
  city: string
  state: string
  country: string
}
