import {AxiosRequestConfig, AxiosResponse} from 'axios'

import {RequestCanceller} from '../../util/action.helper'

import {
  SITES_FETCH_FAILURE,
  SITES_FETCH_REQUEST,
  SITES_FETCH_RESPONSE,
  SitesActionTypes
} from './Action.types'
import {Site} from './Sites.types'

const Canceller = RequestCanceller()

const fetchSitesRequest = (): SitesActionTypes => ({
  type: SITES_FETCH_REQUEST
})

const fetchSitesFailure = (error: any): SitesActionTypes => ({
  type: SITES_FETCH_FAILURE,
  error
})

const fetchSitesResponse = (response: Site[]): SitesActionTypes => ({
  type: SITES_FETCH_RESPONSE,
  response
})

export const fetchSites =
  (customerId: string) =>
  async (dispatch, getState, {api}) => {
    dispatch(fetchSitesRequest())

    try {
      const requestConfig: AxiosRequestConfig = {
        params: {customerId},
        cancelToken: Canceller.getToken()
      }
      const response: AxiosResponse<Site[]> = await api
        .get('/sites', requestConfig)
        .catch((thrown: any) => (Canceller.isCancel(thrown) ? undefined : thrown))

      response && dispatch(fetchSitesResponse(response.data))
    } catch (e) {
      dispatch(fetchSitesFailure(e))
    }
  }
