import {Site} from '../Sites'

import {Project, ProjectSitesDropdownSiteItem, ProjectWithSites} from './Projects.types'

export const PROJECTS_FETCH_REQUEST = 'PROJECTS_FETCH_REQUEST'
export const PROJECTS_FETCH_SUCCESS = 'PROJECTS_FETCH_SUCCESS'
export const PROJECTS_FETCH_FAILURE = 'PROJECTS_FETCH_FAILURE'
export const PROJECTS_WITH_SITES = 'PROJECTS_WITH_SITES'
export const SET_PROJECT_FILTER = 'SET_PROJECT_FILTER'
export const SET_SITE_FILTER = 'SET_SITE_FILTER'
export const REMOVE_PROJECT_FILTER = 'REMOVE_PROJECT_FILTER'
export const REMOVE_SITE_FILTER = 'REMOVE_SITE_FILTER'

interface FetchProjectsRequest {
  type: typeof PROJECTS_FETCH_REQUEST
}

interface FetchProjectsFailure {
  type: typeof PROJECTS_FETCH_FAILURE
  error: any
}

interface FetchProjectsSuccess {
  type: typeof PROJECTS_FETCH_SUCCESS
  projects: Project[]
}

interface ProjectsWithSites {
  type: typeof PROJECTS_WITH_SITES
  sites: Site[]
}

interface SetProjectFilter {
  type: typeof SET_PROJECT_FILTER
  project: Project
}

interface SetSiteFilter {
  type: typeof SET_SITE_FILTER
  site: Site
}

interface RemoveProjectFilter {
  type: typeof REMOVE_PROJECT_FILTER
}
interface RemoveSiteFilter {
  type: typeof REMOVE_SITE_FILTER
}

export type ProjectsActionTypes =
  | FetchProjectsRequest
  | FetchProjectsFailure
  | FetchProjectsSuccess
  | ProjectsWithSites
  | SetProjectFilter
  | SetSiteFilter
  | RemoveProjectFilter
  | RemoveSiteFilter

export interface ProjectsStateType {
  projects: Project[]
  selectedProject: Project | null
  selectedSite: Site | null
  projectsWithSites: ProjectWithSites[] | ProjectSitesDropdownSiteItem[]
  isFetching: boolean
  error: any
}
