import {catchPromise__deprecated} from '@hconnect/common/catchPromise'
import {get} from 'lodash'
import {AnyAction} from 'redux'
import {ThunkAction} from 'redux-thunk'

import {publicApi} from '../../App.global'

import {
  COUNTRIES_GET_COUNTRY_FAILURE,
  COUNTRIES_GET_COUNTRY_REQUEST,
  COUNTRIES_GET_COUNTRY_SUCCESS,
  CountriesActionType
} from './Action.types'
import {Country} from './Countries.types'

export const getCountryRequest = (): CountriesActionType => ({
  type: COUNTRIES_GET_COUNTRY_REQUEST
})

export const getCountryFailure = (error): CountriesActionType => ({
  type: COUNTRIES_GET_COUNTRY_FAILURE,
  error
})

export const getCountrySuccess = (response: Country) => ({
  type: COUNTRIES_GET_COUNTRY_SUCCESS,
  payload: response
})

export const getCountry =
  (): ThunkAction<Promise<Country>, {}, {api: any}, AnyAction> => async (dispatch, getState) => {
    dispatch(getCountryRequest())
    const user = get(getState(), ['userProfile', 'userProfile'])

    const [err, result] = await catchPromise__deprecated(
      publicApi.get(`/configurations/countries/${user.country}`)
    )

    if (err) {
      dispatch(getCountryFailure(err))
      throw err
    }

    dispatch(getCountrySuccess(result.data))
    return result
  }
