import React from 'react'

import {useFeaturesState} from './Features.context'
import {DataScopeConstraintsType} from './Features.types'

type Environments = 'localhost' | 'qa' | 'e2e' | 'uat' | 'uatr' | 'prod'

const checkIfFeatureEnabled = (
  names: string[],
  name: string | undefined,
  checkFn: (name: string) => boolean
) => {
  if (name) {
    return checkFn(name)
  }

  return names.some((n) => checkFn(n))
}

interface Base {
  name?: string
  names?: string[]
  environments?: Environments[]
  constraintType?: DataScopeConstraintsType
  constraint?: string
  type?: 'public'
  resolution?: 'enabled' | 'disabled'
  children: React.ReactNode
}

// One of two required
type RequireProperty<T, Prop extends keyof T> = T & {[key in Prop]-?: T[key]}
// This makes either name or names required
type Props = RequireProperty<Base, 'name'> | RequireProperty<Base, 'names'>

/**
 *
 * @param children
 * @param name Feature flag name
 * @param names
 * @param constraintType (optional) used only in combination with type='public' and constraint
 * @param constraint (optional) used only in combination with type='public' and constraintType
 * @param type (optional) only if the flag is created with public constraint
 * @param resolution (optional, default value is enabled) it would resolve variant, e.g show A for enabled and show component B for disabled
 */
export const Features = ({
  children,
  name,
  names = [],
  constraintType,
  constraint,
  type,
  resolution = 'enabled'
}: Props) => {
  const {getFeature, getResolutionByNameAndConstraint, normalized} = useFeaturesState()
  if (type === 'public' && constraint && constraintType) {
    const show = checkIfFeatureEnabled(names, name, (fnName) =>
      getResolutionByNameAndConstraint(fnName, normalized, constraintType)[resolution].includes(
        constraint
      )
    )

    if (!show) {
      return null
    }
    return <>{children}</>
  }

  const enabled = checkIfFeatureEnabled(names, name, (fnName) => getFeature(fnName))

  if (resolution === 'disabled' && !enabled) {
    return <>{children}</>
  }
  if (!enabled || (resolution === 'disabled' && enabled)) {
    return null
  }
  return <>{children}</>
}
