import {Chip} from '@material-ui/core'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {RoleRequestState} from '../../utils'

const roleRequestStateToLocalizationKey = {
  [RoleRequestState.EXPIRED]: 'invitations.expired',
  [RoleRequestState.DEACTIVATED]: 'invitations.deactivated',
  [RoleRequestState.LIMIT_REACHED]: 'invitations.limitReached',
  [RoleRequestState.VALID]: 'invitations.valid'
}

const roleRequestStateToBackgroundColor = {
  [RoleRequestState.EXPIRED]: '#FEE5E4',
  [RoleRequestState.DEACTIVATED]: '#ECECEC',
  [RoleRequestState.LIMIT_REACHED]: '#ECECEC',
  [RoleRequestState.VALID]: '#E5ECF8'
}

const roleRequestStateToTextColor = {
  [RoleRequestState.EXPIRED]: '#CA605F',
  [RoleRequestState.DEACTIVATED]: '#AFAFAF',
  [RoleRequestState.LIMIT_REACHED]: '#AFAFAF',
  [RoleRequestState.VALID]: '#18699F'
}

type Props = {
  state: RoleRequestState
}

export const RoleRequestStateTag: React.FC<Props> = ({state}) => {
  const {t} = useTranslation()

  const style = {
    marginBottom: '5px',
    marginRight: '5px',
    color: roleRequestStateToTextColor[state],
    backgroundColor: roleRequestStateToBackgroundColor[state],
    border: 'none',
    paddingRight: '10px',
    paddingLeft: '10px'
  }

  return (
    <Chip
      variant="outlined"
      size="small"
      label={t(roleRequestStateToLocalizationKey[state])}
      style={style}
    />
  )
}
