import {User} from '@hconnect/apiclient/src'
import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Redirect, useLocation} from 'react-router'

import {downloadSheet} from '../../AsyncJobQueue/AsyncJobQueue.action'
import {useQueryParam} from '../../Hooks/useQueryParam'
import {AppState} from '../../Root.store'

interface ContextType {
  documentId?: string
}

const DocumentsDownloadContext = React.createContext<ContextType | undefined>(undefined)

export const DocumentsDownloadProvider = ({children}: {children: React.ReactNode}) => {
  const location = useLocation()
  const queryParam = useQueryParam()
  const dispatch = useDispatch()
  const user = useSelector<AppState, User | null>((state) => state.userProfile.userProfile)

  if (user && location.pathname === '/downloadRequests' && queryParam.has('requestId')) {
    dispatch(
      downloadSheet(queryParam.get('requestId') || '', queryParam.get('name') || 'document', {
        jobId: queryParam.get('jobId') || '',
        numberOfDaysSelected: queryParam.get('numberOfDaysSelected') || ''
      })
    )
    return <Redirect to="/overview" />
  }

  return (
    <DocumentsDownloadContext.Provider value={{documentId: queryParam.get('requestId') || ''}}>
      {children}
    </DocumentsDownloadContext.Provider>
  )
}
