import {RoleRequest} from '@hconnect/apiclient'
import moment from 'moment'

export const isRoleRequestExpired = (roleRequest: RoleRequest) => {
  const now = new Date()
  const timezoneOffsetSeconds = now.getTimezoneOffset() * 60
  return moment(now).diff(moment(roleRequest.expiryDate), 'seconds') > timezoneOffsetSeconds
}

export enum RoleRequestState {
  EXPIRED = 'EXPIRED',
  DEACTIVATED = 'DEACTIVATED',
  LIMIT_REACHED = 'LIMIT_REACHED',
  VALID = 'VALID'
}

export const getRoleRequestStates = (roleRequest: RoleRequest) => {
  const states: Array<RoleRequestState> = []
  if (isRoleRequestExpired(roleRequest)) {
    states.push(RoleRequestState.EXPIRED)
  }
  if (roleRequest.deactivated) {
    states.push(RoleRequestState.DEACTIVATED)
  }
  if (roleRequest.usageLimit > 0 && roleRequest.usageLeft === 0) {
    states.push(RoleRequestState.LIMIT_REACHED)
  }
  if (!isRoleRequestExpired(roleRequest) && !roleRequest.deactivated && roleRequest.usageLeft > 0) {
    states.push(RoleRequestState.VALID)
  }

  return states
}
