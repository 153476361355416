import {Typography} from '@hconnect/uikit'
import {
  Box,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core'
import {Check} from '@material-ui/icons'
import React, {Fragment} from 'react'
import {useTranslation} from 'react-i18next'

import {useCustomerById} from '../../hooks/useCustomers'
import {usePayerById} from '../../hooks/usePayers'
import {getPermissionsFromViewMapping} from '../../Invite.roleMatrix'
import {RoleRequestRedeem, SearchTypeKeys} from '../../Invite.types'

type Props = {
  roleRequest: RoleRequestRedeem
}

export const InviteReceiveInfo: React.FC<Props> = ({roleRequest}) => {
  const {t} = useTranslation()

  const customerId = roleRequest.dataScope?.customerIds?.[0]
  const payerId = roleRequest.dataScope?.payerIds?.[0]

  const searchType = payerId ? SearchTypeKeys.payer : SearchTypeKeys.customer

  const {
    data: customer,
    isLoading: isCustomerLoading,
    isError: isCustomerError
  } = useCustomerById(customerId)

  const {data: payer, isLoading: isPayerLoading, isError: isPayerError} = usePayerById(payerId)

  if (!searchType || isPayerError || isCustomerError) {
    return (
      <Box mt={3}>
        <h2>No CustomerId or PayerId found for this roleRequest</h2>
      </Box>
    )
  }

  const permissions = getPermissionsFromViewMapping(roleRequest.roleType, searchType)
  if (isCustomerLoading || isPayerLoading) {
    return (
      <Box mt={3}>
        <CircularProgress data-test-id="proejcts-create-invite-loader" />
      </Box>
    )
  }
  return (
    <>
      <Box mt={6} mb={2}>
        <Typography variant="h3" data-test-id={`inviteReceiveCustomerInfo-${roleRequest.id}`}>
          {t('invite.receive.for')}
          <br />
          {searchType === SearchTypeKeys.customer ? (
            <>
              {customer?.customerName}
              <br />
              {customer?.customerNumber}
            </>
          ) : (
            <>
              {payer?.payerName}
              <br />
              {payer?.payerNumber}
            </>
          )}
        </Typography>
        <Divider />
      </Box>
      <Fragment key={roleRequest.roleType}>
        <Typography
          data-test-id={`inviteReceiveBusinessLine-${roleRequest.id}`}
          variant="body1"
          customColor="textSecondarySoft"
        >
          {t('businessLine.single')}: {t(`businessLine.${roleRequest.dataScope.businessLine}`)}
        </Typography>

        <Box mt={2} data-test-id={`inviteReceive-permission-${roleRequest.id}`}>
          <List>
            {permissions &&
              Object.keys(permissions).map((permission, index) => {
                if (!permissions[permission]) {
                  return null
                }
                return (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <Check />
                    </ListItemIcon>
                    <ListItemText primary={t(`actionBasedPermissions.${permission}`)} />
                  </ListItem>
                )
              })}
          </List>
        </Box>
      </Fragment>
    </>
  )
}
