import {setInvoicesResultsPerPage} from '../Invoices'
import {setOrdersResultsPerPage} from '../Orders'

import {RESULTS_PER_PAGE} from './Action.types'

export const setGlobalResultsPerPage = (amount: number) => (dispatch) => {
  localStorage.setItem(RESULTS_PER_PAGE, JSON.stringify(amount))

  dispatch(setInvoicesResultsPerPage(amount))
  dispatch(setOrdersResultsPerPage(amount))
}

export const getGlobalResultsPerPage = (): number => Number(localStorage.getItem(RESULTS_PER_PAGE))
