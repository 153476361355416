import {Delivery} from '../../Pages/Finance/Invoices/Delivery.types'
import {Order} from '../../Pages/Order/Order.types'
import {PERMISSIONS_FETCH_SUCCESS, PermissionsActionTypes} from '../../Permissions/Action.types'
import {concatIds} from '../../util/reducer.helper'
import {
  CUSTOMERS_FETCH_FAILURE,
  CUSTOMERS_FETCH_REQUEST,
  CUSTOMERS_FETCH_RESPONSE
} from '../Customers'
import {CustomerActionTypes} from '../Customers/Action.types'
import {FilterActionType, createFiltersReducer, initialFiltersState} from '../Filters'

import {
  DELIVERIES_ORDERS_FETCH_FAILURE,
  DELIVERIES_ORDERS_FETCH_REQUEST,
  DELIVERIES_ORDERS_FETCH_SUCCESS,
  NormalizedOrderStoreType,
  ORDERS,
  ORDERS_CLEAR,
  ORDERS_FETCH_FAILURE,
  ORDERS_FETCH_REQUEST,
  ORDERS_FETCH_SUCCESS,
  ORDERS_LOAD_MORE,
  ORDERS_SET_SORT_KEY,
  OrderAbstractState,
  OrderDeliveriesActionTypes,
  OrdersActionTypes,
  ORDERS_SET_RESULTS_PER_PAGE
} from './Action.types'

const filterReducer = createFiltersReducer(ORDERS)

export const initialOrdersState: OrderAbstractState = {
  byId: {},
  allIds: [],
  sortOrder: {
    key: 'shippingDate',
    asc: false
  },
  skip: 0,
  limit: localStorage.getItem('RESULTS_PER_PAGE')
    ? Number(localStorage.getItem('RESULTS_PER_PAGE'))
    : 10,
  error: null,
  isFetching: false,
  showLoadMore: false,
  ...initialFiltersState
}

// eslint-disable-next-line complexity
export const orders = (
  state = initialOrdersState,
  action:
    | OrdersActionTypes
    | OrderDeliveriesActionTypes
    | FilterActionType
    | CustomerActionTypes
    | PermissionsActionTypes
) => {
  switch (action.type) {
    case PERMISSIONS_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: true
      }

    case CUSTOMERS_FETCH_REQUEST:
      return {
        ...state,
        isFetching: true
      }

    case CUSTOMERS_FETCH_RESPONSE:
      return {
        ...state,
        isFetching: false
      }

    case CUSTOMERS_FETCH_FAILURE:
      return {
        ...state,
        isFetching: false
      }

    case ORDERS_CLEAR:
      return {
        ...initialOrdersState,
        sortOrder: {
          ...state.sortOrder
        },
        limit: state.limit
      }

    case ORDERS_SET_SORT_KEY:
      // eslint-disable-next-line no-case-declarations
      const asc = !(state.sortOrder.key === action.payload && state.sortOrder.asc)
      return {
        ...state,
        skip: 0,
        error: null,
        sortOrder: {
          key: action.payload,
          asc
        }
      }

    case ORDERS_FETCH_REQUEST:
      return {
        ...state,
        error: null,
        isFetching: true
      }

    case ORDERS_FETCH_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const collectionIds = action.payload.map((item: Order) => item.orderId)
      return {
        ...state,
        error: null,
        isFetching: false,
        showLoadMore: action.showLoadMore,
        byId: {
          ...state.byId,
          ...action.payload.reduce<NormalizedOrderStoreType>(
            (prev, item) => ({
              ...prev,
              [item.orderId]: {
                deliveriesLoading: false,
                ...item,
                deliveriesFromOrder: item.deliveries
              }
            }),
            {}
          )
        },
        allIds: concatIds(state.allIds, collectionIds)
      }

    case ORDERS_FETCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      }

    case ORDERS_LOAD_MORE:
      return {
        ...state,
        skip: state.skip + state.limit
      }

    case DELIVERIES_ORDERS_FETCH_REQUEST:
      if (action.orderId) {
        return {
          ...state,
          byId: {
            ...state.byId,
            [action.orderId]: {
              ...state.byId[action.orderId],
              deliveriesLoading: true
            }
          }
        }
      }
      return state

    case DELIVERIES_ORDERS_FETCH_SUCCESS:
      if (action.orderId) {
        return {
          ...state,
          byId: {
            ...state.byId,
            [action.orderId]: {
              ...state.byId[action.orderId],
              deliveriesLoading: false,
              deliveries: action.payload.map((delivery: Delivery) => delivery.deliveryId)
            }
          }
        }
      }
      return state

    case DELIVERIES_ORDERS_FETCH_FAILURE:
      if (action.orderId) {
        return {
          ...state,
          byId: {
            ...state.byId,
            [action.orderId]: {
              ...state.byId[action.orderId],
              deliveriesLoading: false,
              deliveries: []
            }
          }
        }
      }
      return state

    case ORDERS_SET_RESULTS_PER_PAGE: {
      return {
        ...state,
        limit: action.payload
      }
    }

    default:
      if ('context' in action) {
        return {
          ...state,
          ...filterReducer(state, action),
          skip: 0
        }
      }
      return state
  }
}
