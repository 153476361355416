import {Box} from '@material-ui/core'
import {TFunction} from 'i18next'
import React from 'react'

import {Payer} from '../../../Payers'

interface Props {
  payer: Payer | null
  t?: TFunction
}

export const CashBalanceTitle: React.FC<Props> = ({payer}) => {
  return (
    <Box>
      <Box>{payer?.payerName || 'loading...'}</Box>
      <Box>{payer?.payerNumber || ''}</Box>
    </Box>
  )
}
