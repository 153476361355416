import {dateFormatter, timeFormatter, datetimeFormatter} from '@hconnect/uikit'
import {Box, CircularProgress} from '@material-ui/core'
import {isEmpty} from 'lodash'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useDismissFailedOrderRequest} from '../../common/react-query/hooks/mutations/useDismissFailedOrderRequest'
import {useFailedOrderRequests} from '../../common/react-query/hooks/queries/useFailedOrderRequests'
import {useNotValidatedOrderRequests} from '../../common/react-query/hooks/queries/useNotValidatedOrderRequests'
import {ErrorBanner} from '../../Molecules/ErrorBanner'
import {InfoBanner} from '../../Molecules/InfoBanner'

interface OrderRequestBannerProps {
  onReload: () => void
  customerId: string
}

export const OrderRequestBanner: React.FC<OrderRequestBannerProps> = ({onReload, customerId}) => {
  const {t} = useTranslation()
  const [isPendingOrders, setIsPendingOrders] = useState<boolean>(false)
  const [isOrderRequestsError, setIsOrderRequestsError] = useState<boolean>(false)
  const [stop, setStop] = useState<boolean>(false)
  const [isInfoBannerVisible, setIsInfoBannerVisible] = useState<boolean>(false)
  const [numberOfPendingOrders, setNumberOfPendingOrders] = useState<number>()

  const {data, error} = useNotValidatedOrderRequests(customerId, stop)

  useEffect(() => {
    if (isEmpty(data)) {
      setIsPendingOrders(false)
      setNumberOfPendingOrders(0)
      setStop(true)
      return
    }

    setIsInfoBannerVisible(true)
    setIsPendingOrders(true)
    setNumberOfPendingOrders(data?.length)
    setStop(false)

    if (error) {
      setIsPendingOrders(false)
      setIsOrderRequestsError(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error])

  const handleReloadClick = () => {
    setIsInfoBannerVisible(false)
    onReload()
  }

  return (
    <InfoBanner
      data-test-id="order-intake-info-banner"
      isVisible={isInfoBannerVisible}
      handleClick={handleReloadClick}
      isError={isOrderRequestsError}
      isPending={isPendingOrders}
      successText={t('orderIntake.ordersConfirmed')}
      errorText={t('orderIntake.errorMessage')}
      pendingText={t('orderIntake.pendingOrders', {numberOfPendingOrders})}
    />
  )
}

interface FailedOrderRequestBannerProps {
  customerId: string
}

export const FailedOrderRequestBanner: React.FC<FailedOrderRequestBannerProps> = ({customerId}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const {data: orderRequests, isLoading} = useFailedOrderRequests(customerId)

  const {mutate: dismissFailedOrderRequest} = useDismissFailedOrderRequest()

  return (
    <Box py={1} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      {isLoading ? <CircularProgress /> : null}
      {orderRequests?.map(({createdOn, modifiedOn, requestId, requestData}) => {
        return (
          <ErrorBanner
            data-test-id="order-intake-error-banner"
            key={requestId}
            isVisible={true}
            label={t('orderIntake.failedOrderRequestBanner', {
              timestamp: datetimeFormatter(modifiedOn ?? createdOn, language),
              orderDate: dateFormatter(requestData.deliveryDate, language),
              timeframe: `${timeFormatter(
                moment(requestData.deliveryTime.earliest, 'HH:mm:ss'),
                language
              )} - ${timeFormatter(moment(requestData.deliveryTime.latest, 'HH:mm:ss'), language)}`,
              material: requestData.material.materialDescription,
              qty: `${requestData.quantity}${requestData.quantityUom}`
            })}
            handleClick={() => dismissFailedOrderRequest({requestId})}
          />
        )
      })}
    </Box>
  )
}
