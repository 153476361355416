import {RoleRequest as RoleRequestQueryInfo} from '@hconnect/apiclient'
import {Typography, useTranslation} from '@hconnect/uikit'
import {Box} from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'
import {makeStyles} from '@material-ui/core/styles'
import React, {useMemo} from 'react'

const ERROR_ORANGE = '#ffa500'
const useStyles = makeStyles({
  borderLineProgess: {
    height: 5,
    borderRadius: 10,
    width: 100,
    backgroundColor: 'lightgrey',
    marginTop: '11px',
    '& .MuiLinearProgress-barColorPrimary': {
      borderRadius: 10,
      backgroundColor: '#28AAFF',
      color: 'red'
    }
  }
})

type InvitationsListValidationCellProps = {
  roleRequest: RoleRequestQueryInfo
}
export const InvitationsListValidationCell = ({
  roleRequest
}: InvitationsListValidationCellProps) => {
  const {t} = useTranslation()
  const styles = useStyles()

  const invitationLinkUsage = useMemo(() => {
    const progress = (roleRequest.usageCount / roleRequest.usageLimit) * 100
    const limit = roleRequest.usageLimit < 0 ? t('invitations.unlimited') : roleRequest.usageLimit

    return {
      progress,
      count: roleRequest.usageCount,
      limit
    }
  }, [t, roleRequest.usageCount, roleRequest.usageLimit])

  return (
    <Box data-test-id={`${roleRequest.id}-progress`}>
      <Typography
        style={{
          color: invitationLinkUsage.progress >= 80 ? ERROR_ORANGE : '#002E4D',
          fontSize: '16px'
        }}
        variant="subtitle1"
        data-test-id={`${roleRequest.id}-linkUsage`}
      >
        {invitationLinkUsage.count}/{invitationLinkUsage.limit} {t('invitations.used')}
      </Typography>
      <LinearProgress
        className={styles.borderLineProgess}
        variant="determinate"
        value={invitationLinkUsage.progress}
      />
    </Box>
  )
}
