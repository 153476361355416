import {
  PROJECTS_FETCH_FAILURE,
  PROJECTS_FETCH_REQUEST,
  PROJECTS_FETCH_SUCCESS,
  PROJECTS_WITH_SITES,
  ProjectsActionTypes,
  ProjectsStateType,
  REMOVE_PROJECT_FILTER,
  REMOVE_SITE_FILTER,
  SET_PROJECT_FILTER,
  SET_SITE_FILTER
} from './Action.types'
import {mapProjectsAndSites} from './utils'

export const initialState: ProjectsStateType = {
  projects: [],
  selectedProject: null,
  selectedSite: null,
  projectsWithSites: [],
  isFetching: false,
  error: null
}

export const projects = (state = initialState, action: ProjectsActionTypes): ProjectsStateType => {
  switch (action.type) {
    case PROJECTS_FETCH_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: initialState.error
      }

    case PROJECTS_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        projects: action.projects
      }

    case PROJECTS_FETCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        projects: [],
        error: action.error
      }

    case PROJECTS_WITH_SITES: {
      const sites = action.sites

      const projectsWithSites = mapProjectsAndSites(state.projects, sites)

      return {
        ...state,
        isFetching: false,
        projectsWithSites
      }
    }

    case SET_PROJECT_FILTER:
      return {
        ...state,
        selectedProject: {...action.project}
      }

    case SET_SITE_FILTER:
      return {
        ...state,
        selectedSite: {...action.site}
      }

    case REMOVE_PROJECT_FILTER:
      return {
        ...state,
        selectedProject: null
      }

    case REMOVE_SITE_FILTER:
      return {
        ...state,
        selectedSite: null
      }

    default:
      return state
  }
}
