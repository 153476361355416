import {trackEvent} from '@hconnect/common/logging/Analytics'
import {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios'
import {isNumber} from 'lodash'

import {errorKeyFromRequestError} from '../../ErrorMap'
import {getErrorMessage, RequestCanceller} from '../../util/action.helper'
import {errorsAdd, errorsClear} from '../Errors'
import {
  clearDefaultFiltersAbstract,
  clearFiltersAbstract,
  removeDefaultFilterAbstract,
  removeFilterAbstract,
  setDefaultFilterAbstract,
  setFilterAbstract
} from '../Filters'

import {
  MATERIALTESTS,
  MATERIALTESTS_CLEAR,
  MATERIALTESTS_FETCH_FAILURE,
  MATERIALTESTS_FETCH_REQUEST,
  MATERIALTESTS_FETCH_SUCCESS,
  MATERIALTESTS_LOAD_MORE,
  MATERIALTESTS_SET_SORT_KEY,
  MaterialTestsActionTypes
} from './Action.types'
import {MaterialTest} from './MaterialTest.types'

const Canceller = RequestCanceller()

export const setFilter = (filterName, filterValue) =>
  setFilterAbstract(MATERIALTESTS, filterName, filterValue)
export const setDefaultFilter = (filterName, filterValue) =>
  setDefaultFilterAbstract(MATERIALTESTS, filterName, filterValue)
export const removeFilter = (filterName) => removeFilterAbstract(MATERIALTESTS, filterName)
export const removeDefaultFilter = (filterName) =>
  removeDefaultFilterAbstract(MATERIALTESTS, filterName)
export const clearFilters = () => clearFiltersAbstract(MATERIALTESTS)
export const clearDefaultFilters = () => clearDefaultFiltersAbstract(MATERIALTESTS)

export const clear = (): MaterialTestsActionTypes => ({
  type: MATERIALTESTS_CLEAR
})

export const setSortKey = (key: any): MaterialTestsActionTypes => ({
  type: MATERIALTESTS_SET_SORT_KEY,
  payload: key
})

export const loadMore = (): MaterialTestsActionTypes => ({
  type: MATERIALTESTS_LOAD_MORE
})

export const fetchMaterialTestsRequest = (params: any): MaterialTestsActionTypes => ({
  type: MATERIALTESTS_FETCH_REQUEST,
  params
})

const fetchMaterialTestsSuccess = (
  params: any,
  payload: MaterialTest[],
  showLoadMore: boolean
): MaterialTestsActionTypes => ({
  type: MATERIALTESTS_FETCH_SUCCESS,
  params,
  payload,
  showLoadMore,
  receivedAt: new Date()
})

export const fetchMaterialTestsFailure = (error: any): MaterialTestsActionTypes => ({
  type: MATERIALTESTS_FETCH_FAILURE,
  error: true,
  payload: error
})

export const fetchMaterialTests =
  (customFilters: any, lookup?: any, analyticsId?: string) =>
  async (dispatch, getState, {api}) => {
    const url = '/MaterialTests'
    dispatch(errorsClear())
    dispatch(fetchMaterialTestsRequest(customFilters))

    try {
      Canceller.cancel('Request canceled by the next request.')
      const requestConfig: AxiosRequestConfig = {
        params: {...customFilters},
        cancelToken: Canceller.getToken()
      }
      const response: AxiosResponse<MaterialTest[]> = await api
        .get(url, requestConfig)
        .catch((thrown: any) => {
          if (!Canceller.isCancel(thrown)) {
            throw thrown
          }
        })
      if (response) {
        const {data} = response
        const {limit} = getState().materialTest.materialTests
        const payload = data.slice(0, limit)
        if (lookup && data.length === 0) {
          trackEvent('hubLookupFailed', {
            product: 'hub',
            lookupCategory: lookup?.entity,
            lookupValue: lookup?.value,
            lookupSource: 'rmctests',
            customerId: customFilters?.customerId,
            analyticsId
          })
        }
        const showLoadMore = data.length > payload.length
        dispatch(fetchMaterialTestsSuccess(customFilters, data, showLoadMore))
        return response
      }
    } catch (err) {
      if (lookup) {
        trackEvent('hubLookupFailed', {
          product: 'hub',
          lookupCategory: lookup?.entity,
          lookupValue: lookup?.value,
          lookupSource: 'rmctests',
          customerId: customFilters?.customerId,
          analyticsId
        })
      }
      const error = err as AxiosError
      const errorKey = errorKeyFromRequestError(error)
      const endpoint = error.response?.config?.url
      const errorStatus = error.response?.status
      dispatch(
        errorsAdd(
          getErrorMessage('materialTest', errorKey, dispatch, fetchMaterialTests, customFilters),
          endpoint,
          isNumber(errorStatus) ? errorStatus.toString() : errorStatus
        )
      )
      dispatch(fetchMaterialTestsFailure(error))
    }
  }
