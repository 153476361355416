/*
You can set any kind Filter with its fallback.
Filters will be differentiated by its contexts.

## SELECTORS

Create a redux-Selector for your filter:
const DATEFILTER = 'date'
const selectDateFilterAbstract = createSelectFilter(DATEFILTER)
export const selectDateFilter = state => selectDateFilterAbstract(selectInvoices(state))

Usage:
state => ({
    myFilter: selectDateFilter(state),
    //...
})

## ACTIONS

Create actions for this Filter:
export const setDateFilter = ({ startDate, endDate }) => setFilter(DATEFILTER, { startDate, endDate })
export const setDefaultDateFilter = ({ startDate, endDate }) => setDefaultFilter(DATEFILTER, { startDate, endDate })
export const removeDateFilter = () => removeFilter(DATEFILTER)

Usage:
dispatch => ({
    setDateFilter: (startDate, endDate) => dispatch(setDateFilter({ startDate, endDate })),
    //...
})

## REDUCERS

Filters.reducer should be used as a Nested-Reducer:

const INVOICES = 'invoices'
const filterReducer = createFiltersReducer(INVOICES)
//...
default:
  return {
    ...state,
    ...filterReducer(state, action),
  }
//...

 */

export * from './Action.types'
export * from './Filters.selector'
export * from './Filters.action'
export * from './Filters.reducer'
