import {concatIds} from '../../util/reducer.helper'

import {} from './Deliveries.action'
import {
  DELIVERIES_INVOICES_FETCH_FAILURE,
  DELIVERIES_INVOICES_FETCH_REQUEST,
  DELIVERIES_INVOICES_FETCH_SUCCESS,
  DeliveriesInvoiceActionTypes,
  DeliveriesStateType
} from './Action.types'

import {Delivery} from '../../Pages/Finance/Invoices/Delivery.types'

export const initialDeliveriesState: DeliveriesStateType = {
  loading: false,
  byId: {},
  allIds: [],
  error: null
}

export const deliveries = (
  state = initialDeliveriesState,
  action: DeliveriesInvoiceActionTypes
) => {
  switch (action.type) {
    case DELIVERIES_INVOICES_FETCH_REQUEST:
      return {
        ...state,
        loading: true
      }

    case DELIVERIES_INVOICES_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        byId: {
          ...state.byId,
          ...action.payload.reduce<any>(
            (prev: Delivery, item: Delivery) => ({
              ...prev,
              [item.deliveryId]: item
            }),
            {}
          )
        },
        allIds: concatIds(
          state.allIds,
          action.payload.map((item: Delivery) => item.deliveryId)
        ),
        error: null
      }

    case DELIVERIES_INVOICES_FETCH_FAILURE: {
      return {
        ...state,
        loading: false
      }
    }

    default:
      return state
  }
}
