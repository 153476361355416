import {useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'

import {api} from '../../../App.global'
import {Site, SiteSearchParams} from '../types'

export const useSites = (args: SiteSearchParams) => {
  return useQuery(
    ['sites', {...args}],
    async () => {
      const res: AxiosResponse<Site[]> = await api.get('/sites', {
        params: {...args}
      })
      return res.data
    },
    {
      enabled: Boolean(args.customerId)
    }
  )
}
