import {createContext, useContext, useState} from 'react'

export interface OrderCancellationStateType {
  orderItemNumber: string
  isFailed: boolean
}

export interface OrderCancellationContextType {
  OrderCancellationState: OrderCancellationStateType[]
  setOrderCancellationState: (
    state:
      | OrderCancellationStateType[]
      | ((currentState: OrderCancellationStateType[]) => OrderCancellationStateType[])
  ) => void
}

const OrderCancellationContext = createContext<OrderCancellationContextType>({
  OrderCancellationState: [],
  setOrderCancellationState: () => {}
})

const OrderCancellationProvider = ({children}) => {
  const [OrderCancellationState, setOrderCancellationState] = useState<
    OrderCancellationStateType[]
  >([])

  return (
    <OrderCancellationContext.Provider value={{OrderCancellationState, setOrderCancellationState}}>
      {children}
    </OrderCancellationContext.Provider>
  )
}

const useOrderCancellationState = () => useContext(OrderCancellationContext)

export {OrderCancellationProvider, useOrderCancellationState}
