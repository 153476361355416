import {
  COUNTRIES_GET_COUNTRY_FAILURE,
  COUNTRIES_GET_COUNTRY_REQUEST,
  COUNTRIES_GET_COUNTRY_SUCCESS,
  CountriesActionType
} from './Action.types'
import {CountriesState} from './Countries.types'

export const countriesInitialState: CountriesState = {
  isFetching: false,
  country: null,
  defaultLocale: null,
  locales: null,
  error: null
}

export const countriesReducer = (state = countriesInitialState, action: CountriesActionType) => {
  switch (action.type) {
    case COUNTRIES_GET_COUNTRY_REQUEST: {
      return {
        ...state,
        isFetching: true
      }
    }

    case COUNTRIES_GET_COUNTRY_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    }

    case COUNTRIES_GET_COUNTRY_SUCCESS: {
      const {locales, countryName, defaultLocale} = action.payload
      return {
        ...state,
        isFetching: false,
        country: countryName,
        defaultLocale,
        locales
      }
    }

    default:
      return state
  }
}
