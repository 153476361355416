import {Delivery} from '../../Pages/Finance/Invoices/Delivery.types'
import {AppState} from '../../Root.store'

export const selectDeliveries = (state: AppState) => state.order.deliveries

export const selectDelivery = (state: AppState, deliveryId: string) =>
  selectDeliveries(state).byId[deliveryId]

export const selectAllDeliveries = (state: AppState) =>
  selectDeliveries(state).allIds.map((deliveryId: string) => selectDelivery(state, deliveryId))

export const selectDeliveriesByDeliveryNumber = (
  state: AppState,
  deliveryNumber: string,
  customerId: string,
  useHaulieView: boolean
) => 
  selectDeliveries(state)
    .allIds.filter((deliveryId: string) => deliveryId.includes(deliveryNumber))
    .filter((deliveryId: string) =>
      useHaulieView
        ? selectDelivery(state, deliveryId)?.haulier?.haulierId === customerId
        : selectDelivery(state, deliveryId).customerId === customerId
    )
    .map((deliveryId: string) => selectDelivery(state, deliveryId))

export const selectDeliveriesByCustomerDeliveryNumber = (
  state: AppState,
  customerDeliveryNumber: string,
  customerId: string
) => {
  const deliveries = selectAllDeliveries(state)
  return deliveries.filter(
    (delivery: Delivery) =>
      ((delivery.customerDeliveryNumber &&
        delivery.customerDeliveryNumber === customerDeliveryNumber) ||
        delivery.deliveryNumber === customerDeliveryNumber) &&
      delivery.customerId === customerId
  )
}
