import {
  SITES_FETCH_FAILURE,
  SITES_FETCH_REQUEST,
  SITES_FETCH_RESPONSE,
  SitesActionTypes,
  SitesStateType
} from './Action.types'

export const initialState: SitesStateType = {
  sites: [],
  isFetching: false,
  error: null
}

export const sites = (state = initialState, action: SitesActionTypes) => {
  switch (action.type) {
    case SITES_FETCH_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: initialState.error
      }

    case SITES_FETCH_RESPONSE:
      return {
        ...state,
        isFetching: false,
        error: null,
        sites: action.response
      }

    case SITES_FETCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        sites: [],
        error: action.error
      }

    default:
      return state
  }
}
