import {RoleRequest} from '@hconnect/apiclient'
import {ResponsiveTable, useBreakPoints} from '@hconnect/uikit'
import {Box, Button, makeStyles, Paper, Snackbar} from '@material-ui/core'
import {Alert} from '@material-ui/lab'
import {Slide} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useRoleRequests} from '../../hooks/useRoleRequests'
import {filterForRoleType, useRoles} from '../../hooks/useRoles'

import {DeactivateLinkDialog} from './dialogs/DeactivateLinkDialog'
import {RevokeRoleRequestDialog} from './dialogs/RevokeRoleRequestDialog'
import {InvitationListDetails} from './InvitationListDetails'
import {InvitationsFilter} from './InvitationsFilter'
import {InvitationsListRows} from './InvitationsListRows'

interface InvitationListProperties {
  loggedInUserId: string
}

// eslint-disable-next-line complexity
export const InvitationsList = ({loggedInUserId}: InvitationListProperties) => {
  const useStyles = makeStyles(() => ({
    noScrollbar: {
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        width: 0
      }
    },
    deactivateLinkButton: {
      border: 'none',
      outline: 'none',
      color: '#CA605F',
      textTransform: 'unset',
      width: '135px',
      whiteSpace: 'nowrap',
      '&:hover': {
        backgroundColor: 'transparent',
        boxShadow: 'none'
      }
    },
    copyLinkButton: {
      marginLeft: '-20px',
      textTransform: 'unset',
      border: 'none',
      whiteSpace: 'nowrap',
      outline: 'none',
      color: '#29aaff',
      '&:hover': {
        backgroundColor: 'transparent',
        boxShadow: 'none'
      }
    }
  }))

  const classes = useStyles()

  const {t} = useTranslation()

  const rolesQueryInfo = useRoles()
  const hasBusinessOwnerRole = filterForRoleType(rolesQueryInfo.data, 'BUSINESS_OWNER').length > 0

  const [alsoShowInactive, setAlsoShowInactive] = useState(false)
  const [selectedTab, setSelectedTab] = useState<number>(0)

  const createdByMe = selectedTab === 1
  const filter = {alsoShowInactive, createdByMe}

  const [selectedRoleRequestId, setSelectedRoleRequestId] = useState<string>('')

  const roleRequestsQueryInfo = useRoleRequests(filter)

  const selectedRoleRequest = roleRequestsQueryInfo.data?.find(
    (roleRequest) => roleRequest.id === selectedRoleRequestId
  )

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)

  const roleRequestId = selectedRoleRequest?.id

  const [toBeRevokedUserId, setToBeRevokedUserId] = useState<string | null>(null)
  const [toBeDeactivatedLink, setToBeDeactivatedLink] = useState<string | null>(null)

  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)
  const isTablet = ['xs', 'sm', 'md'].includes(screenSizes)

  const [selectedRow, setSelectedRow] = useState<RoleRequest | undefined>()

  const handleRowClick = (row?: RoleRequest) => {
    if (row) {
      setSelectedRoleRequestId(row.id)
      setSelectedRow(row)
    }
  }

  const renderResponsiveTable = (
    <Box
      style={{
        borderRight: isTablet ? 'none' : '1px solid lightgrey',
        paddingRight: isTablet ? 0 : '20px',
        height: isMobile ? '100%' : '70vh',
        overflow: isMobile ? 'visible' : 'auto',
        width: isTablet ? '100%' : '70%'
      }}
      className={classes.noScrollbar}
    >
      <Box
        style={{
          backgroundColor: 'white',
          paddingTop: '15px',
          zIndex: 99
        }}
      >
        <Box
          style={{
            backgroundColor: 'white',
            width: '100%',
            opacity: isTablet && !isMobile && selectedRow ? 0.5 : 1
          }}
        >
          <Box
            style={{
              position: 'sticky',
              top: isMobile ? '50px' : '0',
              backgroundColor: '#FFFFFF',
              zIndex: 100,
              margin: '10px 0',
              height: isMobile ? '106px' : 'auto'
            }}
          >
            <InvitationsFilter
              allLinksTabVisible={hasBusinessOwnerRole}
              selectedTab={selectedTab}
              onTabChange={(_, selectedTab) => {
                setSelectedRoleRequestId('')
                setSelectedTab(selectedTab)
              }}
              showExpired={alsoShowInactive}
              onShowExpiredChange={(alsoShowInactive) => {
                setSelectedRoleRequestId('')
                setAlsoShowInactive(alsoShowInactive)
              }}
            />
          </Box>
          <ResponsiveTable
            keyField="id"
            onSort={() => null}
            columns={InvitationsListRows({
              setIsSnackbarOpen,
              setToBeDeactivatedLink,
              setSelectedRoleRequestId
            })}
            rows={roleRequestsQueryInfo.data || []}
            loading={roleRequestsQueryInfo.isLoading}
            isRowSelectable={isMobile ? undefined : () => true}
            onRowClick={handleRowClick}
            emptyMessage={!roleRequestsQueryInfo.isLoading && t('invitations.noRecords')}
            rowDetailsAvailable={!isMobile && true}
            mobileRowDetails={
              <InvitationListDetails
                selectedRoleRequest={selectedRoleRequest}
                setSelectedRow={setSelectedRow}
                selectedRoleRequestId={selectedRoleRequestId}
                setToBeRevokedUserId={setToBeRevokedUserId}
                setIsSnackbarOpened={setIsSnackbarOpen}
                setToBeDeactivatedLink={setToBeDeactivatedLink}
                loggedInUserId={loggedInUserId}
              />
            }
            isMobile={isMobile}
          />
        </Box>
      </Box>
    </Box>
  )

  const detailsSlide = (
    <Box
      position="absolute"
      right={0}
      top={0}
      height="100%"
      width="50%"
      style={{marginRight: '-20px'}}
    >
      <Slide
        in={Boolean(isTablet && selectedRow)}
        direction="left"
        mountOnEnter
        unmountOnExit
        data-test-id={`slide-${selectedRow?.id}`}
      >
        <Paper
          elevation={4}
          color="white"
          style={{
            height: '100%',
            maxHeight: '100vh',
            overflowY: 'auto',
            paddingBottom: 10,
            backgroundColor: 'white',
            zIndex: 1000
          }}
        >
          <InvitationListDetails
            selectedRoleRequest={selectedRoleRequest}
            setSelectedRow={setSelectedRow}
            selectedRoleRequestId={selectedRoleRequestId}
            setToBeRevokedUserId={setToBeRevokedUserId}
            setIsSnackbarOpened={setIsSnackbarOpen}
            setToBeDeactivatedLink={setToBeDeactivatedLink}
            loggedInUserId={loggedInUserId}
          />
        </Paper>
      </Slide>
    </Box>
  )

  if (roleRequestsQueryInfo.isError) {
    return (
      <Button
        data-test-id="invitations-tryagain"
        onClick={() => void roleRequestsQueryInfo.refetch()}
        variant="outlined"
        color="secondary"
      >
        {t('invitations.try again')}
      </Button>
    )
  }

  return (
    <>
      <Box style={{display: 'flex', position: 'relative'}}>
        {renderResponsiveTable}
        {!isTablet && !isMobile && (
          <InvitationListDetails
            selectedRoleRequest={selectedRoleRequest}
            setSelectedRow={setSelectedRow}
            selectedRoleRequestId={selectedRoleRequestId}
            setToBeRevokedUserId={setToBeRevokedUserId}
            setIsSnackbarOpened={setIsSnackbarOpen}
            setToBeDeactivatedLink={setToBeDeactivatedLink}
            loggedInUserId={loggedInUserId}
          />
        )}
        {isTablet && !isMobile && selectedRow && detailsSlide}
      </Box>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={() => setIsSnackbarOpen(false)}
      >
        <Alert onClose={() => setIsSnackbarOpen(false)} severity="success">
          {t('invitations.copied')}!
        </Alert>
      </Snackbar>
      <RevokeRoleRequestDialog
        toBeRevokedUserId={toBeRevokedUserId}
        setToBeRevokedUserId={setToBeRevokedUserId}
        roleRequestId={roleRequestId}
        loggedInUserId={loggedInUserId}
      />
      <DeactivateLinkDialog
        toBeDeactivatedLink={toBeDeactivatedLink}
        setToBeDeactivatedLink={setToBeDeactivatedLink}
        loggedInUserId={loggedInUserId}
        roleRequestId={roleRequestId}
      />
    </>
  )
}
