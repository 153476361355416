import {AxiosRequestConfig, AxiosResponse} from 'axios'

import {createSelectFilter, removeFilterAbstract, setFilterAbstract} from '../Filters'
import {INVOICES} from '../Invoices'
import {ORDERS} from '../Orders'
const STATEMENTS = 'STATEMENTS'

import {
  PAYERS_FETCH_FAILURE,
  PAYERS_FETCH_REQUEST_START,
  PAYERS_FETCH_REQUEST_FINISHED,
  PAYERS_FETCH_SUCCESS,
  PayersActionTypes,
  PAYER_FILTER,
  SELECT_PAYER_FILTER,
  SET_PAYER_FILTER,
  REMOVE_PAYER_FILTER
} from './Action.types'
import {Payer} from './Payers.types'

export const selectPayerFilterAbstract = createSelectFilter(PAYER_FILTER)

export const selectPayerFilter = (payerId: string): PayersActionTypes => ({
  type: SELECT_PAYER_FILTER,
  payerId
})
export const setPayerFilter = (payer: Payer | null): PayersActionTypes => ({
  type: SET_PAYER_FILTER,
  payer
})
export const removePayerFilter = (): PayersActionTypes => ({
  type: REMOVE_PAYER_FILTER
})

// based on https://stackoverflow.com/questions/40900354/updating-state-managed-by-another-reducer
const setOrdersPayerFilter = (payer: Payer | null) =>
  setFilterAbstract(ORDERS, PAYER_FILTER, {...payer})
const setInvoicesPayerFilter = (payer: Payer | null) =>
  setFilterAbstract(INVOICES, PAYER_FILTER, {...payer})
const setStatementsPayerFilter = (payer: Payer | null) =>
  setFilterAbstract(STATEMENTS, PAYER_FILTER, {...payer})

export const setGlobalPayerFilter = (payer: Payer | null) => (dispatch) => {
  dispatch(setOrdersPayerFilter(payer))
  dispatch(setInvoicesPayerFilter(payer))
  dispatch(setStatementsPayerFilter(payer))
  return dispatch(setPayerFilter(payer))
}

const removeOrdersPayerFilter = () => removeFilterAbstract(ORDERS, PAYER_FILTER)
const removeInvoicesPayerFilter = () => removeFilterAbstract(INVOICES, PAYER_FILTER)
const removeStatementsPayerFilter = () => removeFilterAbstract(STATEMENTS, PAYER_FILTER)
export const removeGlobalPayerFilter = () => (dispatch) => {
  dispatch(removeOrdersPayerFilter())
  dispatch(removeInvoicesPayerFilter())
  dispatch(removeStatementsPayerFilter())
  return dispatch(removePayerFilter())
}

export const fetchPayersRequestStart = (): PayersActionTypes => ({
  type: PAYERS_FETCH_REQUEST_START
})
export const fetchPayersRequestFinished = (customerId: string): PayersActionTypes => ({
  type: PAYERS_FETCH_REQUEST_FINISHED,
  customerId
})

export const fetchPayersSuccess = (customerId: string, payers: Payer[]): PayersActionTypes => ({
  type: PAYERS_FETCH_SUCCESS,
  payers,
  customerId
})

export const fetchPayersFailure = (customerId: string, error: any): PayersActionTypes => ({
  type: PAYERS_FETCH_FAILURE,
  error,
  customerId
})

export const fetchPayers =
  (customerId: string) =>
  async (dispatch, getState, {api}) => {
    try {
      const requestConfig: AxiosRequestConfig = {
        params: {customerId}
      }
      const response: AxiosResponse<Payer[]> = await api.get('/payers', requestConfig)

      if (response) {
        dispatch(fetchPayersSuccess(customerId, response.data))
        dispatch(fetchPayersRequestFinished(customerId))
      }
    } catch (e) {
      dispatch(fetchPayersFailure(customerId, e))
    }
  }
