import {
  PaymentOptionsActionsType,
  PaymentOptionsStateType,
  ADD_DELEGO_CARD,
  ADD_DELEGO_CARD_SUCCESS,
  CREATE_PAYMENT_REQUEST,
  CREATE_PAYMENT_FAILURE,
  ADD_AUTHORIZATION_ERROR,
  ADD_SETTTLEMENT_ERROR,
  CLEAR_SETTTLEMENT_ERROR,
  REMOVE_DELEGO_CARD
} from './Action.types'

import {
  ADD_AUTHORIZATION_RESPONSE,
  CLEAR_AUTHORIZATION_ERROR,
  CLEAR_INITIAL_PAYMENT,
  CREATE_PAYMENT_SUCCESS
} from '.'

const initialState: PaymentOptionsStateType = {
  isFetching: false,
  error: null,
  addCardRequested: false,
  errorCards: false,
  addCardSuccess: false,
  authorization: undefined,
  authorizationError: false,
  authorized: false,
  settlementError: false,
  paymentMethodsAdded: undefined
}

export const paymentOptionsReducer = (
  state = initialState,
  action: PaymentOptionsActionsType
): PaymentOptionsStateType => {
  switch (action.type) {
    case ADD_DELEGO_CARD:
      return {
        ...state,
        paymentMethodsAdded: action.payload
      }

    case REMOVE_DELEGO_CARD: {
      return {
        ...state,
        paymentMethodsAdded: undefined
      }
    }

    case ADD_DELEGO_CARD_SUCCESS:
      return {
        ...state,
        errorCards: false,
        addCardRequested: false,
        addCardSuccess: true,
        paymentMethodsAdded: undefined
      }

    case ADD_AUTHORIZATION_RESPONSE:
      return {
        ...state,
        authorized: true,
        authorization: action.payload,
        authorizationError: false
      }

    case ADD_AUTHORIZATION_ERROR: {
      return {
        ...state,
        authorized: false,
        authorization: undefined,
        authorizationError: true,
        authorizationErrorType: action.payload
      }
    }

    case CLEAR_AUTHORIZATION_ERROR:
      return {
        ...state,
        authorized: false,
        authorization: undefined,
        authorizationError: false,
        authorizationErrorType: undefined
      }

    case CREATE_PAYMENT_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
        authorizationError: false
      }

    case CREATE_PAYMENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      }

    case CREATE_PAYMENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        paymentResult: action.payload
      }

    case CLEAR_INITIAL_PAYMENT:
      return {
        ...state,
        isFetching: false,
        paymentResult: undefined,
        authorizationError: false,
        settlementError: false
      }

    case ADD_SETTTLEMENT_ERROR:
      return {
        ...state,
        settlementError: true
      }

    case CLEAR_SETTTLEMENT_ERROR:
      return {
        ...state,
        settlementError: false
      }

    default:
      return state
  }
}
