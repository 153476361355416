import {
  PAYERS_FETCH_FAILURE,
  PAYERS_FETCH_SUCCESS,
  PayersActionTypes,
  PayersStateType,
  SET_PAYER_FILTER,
  REMOVE_PAYER_FILTER,
  PAYERS_FETCH_REQUEST_START,
  PAYERS_FETCH_REQUEST_FINISHED
} from './Action.types'

export const initialPayersState: PayersStateType = {
  isFetching: false,
  entities: {},
  selectedPayer: null,
  error: null
}

export const payersReducer = (
  state = initialPayersState,
  action: PayersActionTypes
): PayersStateType => {
  switch (action.type) {
    case PAYERS_FETCH_REQUEST_START:
      return {
        ...state,
        error: null,
        isFetching: true
      }

    case PAYERS_FETCH_REQUEST_FINISHED:
      return {
        ...state,
        selectedPayer: state.entities[action.customerId]
          ? state.entities[action.customerId][0]
          : null,
        isFetching: false
      }

    case PAYERS_FETCH_SUCCESS: {
      return {
        ...state,
        error: null,
        entities: {
          ...state.entities,
          [action.customerId]: action.payers.map((payer) => ({
            ...payer,
            parentCustomerId: action.customerId
          }))
        }
      }
    }

    case PAYERS_FETCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      }

    case SET_PAYER_FILTER:
      return {
        ...state,
        selectedPayer: action.payer ? {...action.payer} : null
      }
    case REMOVE_PAYER_FILTER:
      return {
        ...state,
        selectedPayer: null
      }
    default:
      return state
  }
}
