import {Delivery} from '../../Pages/Finance/Invoices/Delivery.types'
import {BreakdownLineItem, LineItem} from '../../Pages/Finance/Invoices/Invoice.types'
import {AppState} from '../../Root.store'

import {selectDelivery} from './Deliveries.selector'

export const selectInvoices = (state: AppState) => state.finance.invoices

export const selectInvoice = (state: AppState, invoiceId: string) =>
  selectInvoices(state)?.byId[invoiceId]

export const selectAllInvoices = (state: AppState) =>
  selectInvoices(state).allIds.map((invoiceId: string) => selectInvoice(state, invoiceId))

export const selectInvoiceLineItems = (state: AppState, invoiceId: string): LineItem[] =>
  selectInvoice(state, invoiceId).lineItems

export const selectInvoiceMaterialItems = (
  state: AppState,
  invoiceId: string
): BreakdownLineItem[] | undefined => selectInvoice(state, invoiceId).materialItems || []

export const selectInvoiceServiceItems = (
  state: AppState,
  invoiceId: string
): BreakdownLineItem[] | undefined => selectInvoice(state, invoiceId).serviceItems || []

export const selectInvoiceSurchargeItems = (
  state: AppState,
  invoiceId: string
): BreakdownLineItem[] | undefined => selectInvoice(state, invoiceId).surchargeItems || []

export const selectInvoiceLinksByType = (state: AppState, invoiceId: string, type: string) =>
  selectInvoice(state, invoiceId)?.links?.filter((link) => link.type === type)

export const selectInvoiceDeliveries = (
  state: AppState,
  invoiceId: string
): Delivery[] | null | undefined => {
  const invoice = selectInvoice(state, invoiceId)

  const deliveries: string[] = invoice?.deliveries || []

  if (deliveries.length > 0) {
    return deliveries.map((deliveryId: string) => selectDelivery(state, deliveryId))
  }
  return invoice?.deliveries
}
