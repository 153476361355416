import {makeStyles} from 'tss-react/mui'
export const useStylesV5 = makeStyles()((theme) => ({
  itemOnLight: {
    // display: 'flex',
    // height: 'auto',
    justifyContent: 'flex-start',
    // alignItems: 'flex-start',
    // flexDirection: 'row',
    minHeight: '44px',
    '&:hover': {
      backgroundColor: '#e7e7e7'
    },
    '& p': {
      color: theme.palette.text.primarySoft
    },
    width: '100%'
  },
  selectedOnLight: {
    backgroundColor: '#f0f0f0 !important',
    justifyContent: 'flex-start',
    '& span': {border: 'none'},
    '& div': {border: 'none'},
    '&:hover': {
      backgroundColor: ['#f0f0f0', '!important']
    }
  }
}))
