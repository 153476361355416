import {trackEvent} from '@hconnect/common/logging/Analytics'
import {useTranslation} from '@hconnect/uikit'
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined'
import {Box, Button, Card, CardContent, Theme, Typography} from '@mui/material'
import {useEffect} from 'react'
import {useLocalStorage} from 'react-use'
import {makeStyles} from 'tss-react/mui'

import {useMaterialCertificatesSubscriptions} from '../hooks/useMaterialCertificatesSubscriptions'
import {MaterialCertificate} from '../types'

const useStyles = makeStyles()((theme: Theme) => ({
  card: {
    boxSizing: 'border-box',
    borderLeft: '4px solid #EE8B00',
    marginBottom: theme.spacing(3)
  },
  cardContent: {
    color: '#435A70',
    fontSize: 16
  },
  dismissButton: {
    color: '#435A70',
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#d5d5d5'
    }
  },
  subscribeButton: {
    color: '#FFFFFF',
    backgroundColor: '#EE8B00',
    flexGrow: 1
  }
}))

interface CertificateSubscriptionCardProps {
  certificate: MaterialCertificate
  setCertificate: (certificate?: MaterialCertificate) => void
}

export const CertificateSubscriptionCard: React.FC<CertificateSubscriptionCardProps> = ({
  certificate,
  setCertificate
}) => {
  const {t} = useTranslation()
  const {classes} = useStyles()
  const [isCardDismissed, setIsCardDismissed] = useLocalStorage<boolean>(
    'test-subscription-card-dismissed',
    false
  )
  const {mutate, isLoading, isError, isSuccess, reset} = useMaterialCertificatesSubscriptions()

  useEffect(() => reset(), [certificate.plantIdUnique, reset])

  const subscribe = () => {
    trackEvent('hubMaterialsCertificatesSubscription', {
      product: 'hub',
      plant: certificate.plantId,
      materialDescription: certificate.materialDescription,
      action: 'subscribe'
    })

    mutate(
      {certificate, subscribe: true},
      {
        onSuccess: () => {
          setCertificate({...certificate, isSubscribed: true})
        }
      }
    )
  }

  if (isCardDismissed || certificate.isSubscribed || isSuccess) return null
  return (
    <Card
      variant="elevation"
      elevation={2}
      className={classes.card}
      data-test-id="test-subscription-card"
    >
      <CardContent className={classes.cardContent}>
        <Box display="flex" alignItems="center" mb={2} columnGap={1}>
          <NotificationsActiveOutlinedIcon />
          <Typography fontWeight={600}>{t('certificate.subscribeCard.title')}</Typography>
        </Box>
        <Typography>{t('certificate.subscribeCard.description')}</Typography>
        <Box display="flex" columnGap={2} mt={2}>
          <Button
            variant="contained"
            color="primary"
            className={classes.dismissButton}
            onClick={() => setIsCardDismissed(true)}
            data-test-id="certificate-subscribe-card-dismiss-button"
          >
            {t('certificate.subscribeCard.dismissButton')}
          </Button>
          <Button
            variant="contained"
            className={classes.subscribeButton}
            color="warning"
            onClick={subscribe}
            disabled={isLoading}
            data-test-id="certificate-subscribe-card-subscribe-button"
          >
            {t('certificate.subscribeCard.subscribeButton')}
          </Button>
        </Box>
        {isError && (
          <Box mt={2}>
            <Typography color="error">{t('errorboundary.error')}</Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  )
}
