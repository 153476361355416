import {TextButton, Typography} from '@hconnect/uikit'
import {Box, TextField, LinearProgress} from '@material-ui/core'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {getTextBoxHelperText} from '../../LettersOfCertification.utils'

interface PlantSignatureProps {
  isFetching: boolean
  signature: string
  isSignatureSaveError: boolean
  isSignatureSaveSuccess: boolean
  isSignatureLoading: boolean
  isSnippetsLoading: boolean
  setSignature(signature: string): void
  handleSignatureSave(): void
}

export const PlantSignature: React.FC<PlantSignatureProps> = ({
  isFetching,
  signature,
  setSignature,
  isSignatureSaveError,
  isSignatureSaveSuccess,
  handleSignatureSave,
  isSignatureLoading,
  isSnippetsLoading
}) => {
  const {t} = useTranslation()
  const [isDataLoading, setIsDataLoading] = useState<boolean>(true)

  useEffect(() => {
    setIsDataLoading(isSnippetsLoading || isFetching)
  }, [isSnippetsLoading, isFetching])
  const handleSignatureChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSignature(evt.target.value)
  }

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between">
      <Typography variant="h3">{t('certificate.editCertificate.signatureTitle')}</Typography>
      <Box p={8} />
      <Box display="flex">
        <Box display="flex" flexDirection="column">
          {isDataLoading ? <LinearProgress data-test-id="loc-plant-signature-loader" /> : null}
          <TextField
            placeholder={isDataLoading ? '' : t('certificate.editCertificate.signaturePlaceholder')}
            value={signature}
            variant="filled"
            onChange={handleSignatureChange}
            disabled={isSnippetsLoading || isFetching}
            error={isSignatureSaveError}
            helperText={getTextBoxHelperText(isSignatureSaveError, isSignatureSaveSuccess, t)}
            multiline
          />
          <Box p={1} />
          <TextButton
            variant="text"
            color="secondary"
            onClick={handleSignatureSave}
            disabled={isSignatureLoading || isFetching || !signature}
          >
            {t('certificate.editCertificate.saveSignature')}
          </TextButton>
        </Box>
      </Box>
    </Box>
  )
}
