import {Branding} from '@hconnect/common/branding/Branding.types'

export const BRANDING = 'BRANDING'
export const SET_BRANDING = 'BRANDING/SET_BRANDING'

interface SetBranding {
  type: typeof SET_BRANDING
  data: Branding
}

export type BrandingActionTypes = SetBranding
