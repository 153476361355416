import {AxiosError} from 'axios'
import {useEffect, useMemo} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {useParams} from 'react-router-dom'

import {RoleKeys} from '../../../Roles'
import {api} from '../Api/Api'
import {Step, RoleRequestRedeemResponse} from '../Invite.types'

export const useInviteReceive = (
  userCountryId: string,
  userId: string,
  fetchPermissions: (userId: string) => void,
  fetchCustomer: () => void
) => {
  const {countryId, roleRequestId} = useParams<{roleRequestId: string; countryId: string}>()
  const queryClient = useQueryClient()

  const countryMissMatch = userCountryId !== countryId
  const {mutate, isLoading, data, error} = useMutation<
    RoleRequestRedeemResponse,
    AxiosError<{detail?: string}>
  >(
    async () => {
      const response = await api.post<RoleRequestRedeemResponse>(
        `/roleRequests/redeem/${roleRequestId}`
      )
      return response.data
    },
    {
      onSuccess: () => {
        if (userId) {
          void queryClient.invalidateQueries(RoleKeys.byUserId(userId))
          fetchPermissions(userId)
        }
        fetchCustomer()
      }
    }
  )

  const statusCode = error?.response?.status

  const step = useMemo(() => {
    if (countryMissMatch) {
      return Step.ERROR_COUNTRY_MISMATCH
    }
    if (error) {
      if (!error.response) {
        return Step.ERROR
      }

      if (statusCode === 304) {
        return Step.ERROR_NO_CHANGE
      }

      if (error?.response?.data?.detail) {
        switch (error?.response?.data?.detail) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
          case Step.INVITE_EXPIRED:
            return Step.ERROR_EXPIRED
          // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
          case Step.INVITE_DEACTIVATED:
            return Step.ERROR_DEACTIVATED
          // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
          case Step.INVITE_MAX_USAGE_REACHED:
            return Step.ERROR_LIMIT_REACHED
          // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
          case Step.INVITE_NOT_FOUND:
            return Step.ERROR_WRONG_LINK
          default:
            return Step.ERROR_FROM_API
        }
      }

      switch (statusCode) {
        case 400:
          return Step.ERROR_EXPIRED
        case 404:
          return Step.ERROR_WRONG_LINK
        default:
          return Step.ERROR
      }
    }

    return Step.SUCCESS
  }, [error, countryMissMatch, statusCode])

  useEffect(() => {
    mutate()
  }, [mutate])

  return {
    mutate,
    isLoading,
    data,
    step,
    error
  }
}
