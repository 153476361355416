import {RoleRequest} from '@hconnect/apiclient'
import {useQuery, UseQueryResult} from 'react-query'

import {getRequestPathData} from './utils'

interface Filter {
  createdByMe: boolean
  alsoShowInactive: boolean
}

const fetchRoleRequests = async ({queryKey}) => {
  const [endpoint, {filter}] = queryKey

  const queryParams = new URLSearchParams({})

  queryParams.append('expand', 'usedByUserInformation')
  queryParams.append('filter', filter.alsoShowInactive ? 'all' : 'active')
  queryParams.append('createdByMe', filter.createdByMe)

  const roleRequests = await getRequestPathData<RoleRequest[]>(
    `/${endpoint}?${queryParams.toString()}`
  )

  return roleRequests.map((roleRequest) => {
    return {
      ...roleRequest,
      roles: roleRequest.roles.map((role) => ({
        ...role,
        dataScope: JSON.parse(role.dataScope as unknown as string)
      }))
    }
  })
}

export const useRoleRequests = (filter: Filter): UseQueryResult<RoleRequest[]> => {
  return useQuery(['roleRequests', {filter}], fetchRoleRequests, {
    retry: false
  })
}
