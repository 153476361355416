import {LoadingButton} from '@hconnect/uikit'
import {Box, makeStyles} from '@material-ui/core'
import {ArrowForward} from '@material-ui/icons'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {UseMutationResult} from 'react-query'

import {RoleRequestResponseJSON, Role, DataScope} from '../hooks/useRoleRequestMutation'
import {ActionBasedPermissions, DefaultSettings, RoleEnum, SearchTypeKeys} from '../Invite.types'

import {ProjectsSitesState} from './components/projectsSites/ProjectsSitesModal'
import {RedemptionSettings} from './components/RedemptionSettings'
import {RoleRequestLink} from './components/RoleRequestLink'

import {trackEventWithBrowserProps} from '@hconnect/common/logging/Analytics'

type Props = {
  modalState: ProjectsSitesState
  customerOrPayerId?: string
  selectedCountryId: string
  defaultSettings: DefaultSettings
  rolesToGenerate: Role[]
  roleType: RoleEnum
  projectsAndSitesModal: JSX.Element | null
  searchType?: string
  toggles: ActionBasedPermissions
  userId: string
  roleRequestMutation: UseMutationResult<
    RoleRequestResponseJSON,
    unknown,
    {roles: Role[]; usageLimit: number; validDays: number}
  >
  setLinkUsageLimit: (limit?: number) => void
  setLinkValidDays: (days?: number) => void
  linkUsageLimit?: number
  linkValidDays?: number
}

const useStyles = makeStyles(() => ({
  generateLink: {
    textTransform: 'none',
    fontWeight: 600,
    outline: 'none',
    borderRadius: '6px',
    border: '1px solid #016AD4',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.08)',
    background: '#016AD4',
    color: '#FFFFFF !important',
    '&:hover': {
      opacity: 0.6,
      background: '#016AD4',
      color: '#FFFFFF'
    },
    '&.Mui-disabled': {
      opacity: '0.6'
    }
  },
  fullWidth: {
    width: '100%'
  }
}))

export const CreateInvite: React.FC<Props> = ({
  modalState,
  customerOrPayerId,
  rolesToGenerate,
  defaultSettings,
  roleType,
  selectedCountryId,
  searchType,
  userId,
  roleRequestMutation,
  linkUsageLimit,
  setLinkUsageLimit,
  linkValidDays,
  setLinkValidDays
}) => {
  const {t} = useTranslation()
  const classes = useStyles()

  const isLinkUsageLimitValid =
    !!linkUsageLimit &&
    linkUsageLimit >= 1 &&
    defaultSettings &&
    linkUsageLimit <= defaultSettings.maxUsageLimit
  const isLinkValidDaysValid =
    !!linkValidDays &&
    linkValidDays >= 1 &&
    defaultSettings &&
    linkValidDays <= defaultSettings.maxExpireDays

  const distinctArray = (roles: Role[]) => [
    ...new Map(
      roles.map((v) => [
        JSON.stringify([
          v.dataScope.businessLine,
          v.dataScope.countryId,
          v.dataScope.customerIds,
          v.dataScope.orgUnitId,
          v.dataScope.payerIds,
          v.dataScope.siteIds,
          v.dataScope.projectIds,
          v.roleType
        ]),
        v
      ])
    ).values()
  ]

  const addProjectAndSiteIds = (roles: Role[], modalState: ProjectsSitesState) =>
    roles.map((role) => {
      role.dataScope.projectIds = modalState.projectsIds.length ? modalState.projectsIds : undefined
      role.dataScope.siteIds = modalState.sitesIds.length ? modalState.sitesIds : undefined
      return role
    })

  const createRoleRequest = () => {
    if (linkUsageLimit && linkValidDays) {
      const roles = rolesToGenerate.map((role) => {
        const dataScope: DataScope = {
          ...role.dataScope,
          customerIds: undefined,
          payerIds: undefined
        }
        if (customerOrPayerId && searchType === SearchTypeKeys.customer) {
          dataScope.customerIds = [customerOrPayerId]
        }
        if (customerOrPayerId && searchType === SearchTypeKeys.payer) {
          dataScope.payerIds = [customerOrPayerId]
        }

        return {
          dataScope,
          roleType
        }
      })

      void roleRequestMutation
        .mutateAsync({
          roles: distinctArray(modalState.all ? roles : addProjectAndSiteIds(roles, modalState)),
          usageLimit: linkUsageLimit,
          validDays: linkValidDays
        })
        .then((response) => {
          trackEventWithBrowserProps('inviteGenerateLinkSuccess', {
            product: 'hub',
            date: new Date().toISOString(),
            userId,
            roleRequestId: response.roleRequestId,
            roles,
            linkUsageLimit,
            linkValidDays
          })
        })
        .catch((error) => {
          trackEventWithBrowserProps('inviteGenerateLinkFailed', {
            product: 'hub',
            date: new Date().toISOString(),
            userId,
            detail: error.response.data.detail
          })
        })
    }
  }

  const renderGenerateButton = () => {
    return (
      <LoadingButton
        disabled={
          !isLinkValidDaysValid ||
          !isLinkUsageLimitValid ||
          (!modalState.all && modalState.projectsIds.length < 1 && modalState.sitesIds.length < 1)
        }
        loading={roleRequestMutation.isLoading}
        startIcon={<ArrowForward />}
        data-test-id="generate-link"
        onClick={createRoleRequest}
        className={classes.fullWidth}
        btnClassName={classes.generateLink}
        fullWidth
      >
        {roleRequestMutation.isLoading
          ? t('invite.create.generatingLink')
          : t('invite.create.generateLink')}
      </LoadingButton>
    )
  }

  return (
    <>
      <RedemptionSettings
        linkValidDays={linkValidDays}
        setLinkValidDays={setLinkValidDays}
        linkUsageLimit={linkUsageLimit}
        setLinkUsageLimit={setLinkUsageLimit}
        isLinkUsageLimitValid={isLinkUsageLimitValid}
        isLinkValidDaysValid={isLinkValidDaysValid}
        defaultSettings={defaultSettings}
      />
      <Box mt={2} width={'100%'}>
        {renderGenerateButton()}
      </Box>
      <RoleRequestLink roleRequestMutation={roleRequestMutation} countryId={selectedCountryId} />
    </>
  )
}
