import {dateFormatter, isoDateFormatter} from '@hconnect/uikit'
import {Box, Divider} from '@material-ui/core'
import CloseIcon from '@mui/icons-material/Close'
import {Grid, IconButton} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {ReactComponent as NoCertificateSelectIcon} from '../../../Assets/certificateIcons/no-certificate-select-icon.svg'
import {CustomerFeedbackPanel} from '../../../Molecules/CustomerFeedbackProgramme'
import {CustomHeader} from '../../../Molecules/CustomHeader'
import {FieldValue} from '../../../Molecules/FieldDetails'
import {useIconStyles} from '../../Finance/Invoices/InvoiceDetail/components'

import {
  CertificateGenerateButtonsAside,
  CertificateSubscribeButton
} from './CertificateGenerateButtons.aside'
import {CertificateSubscriptionCard} from './Components/CertificateSubscriptionCard'
import {MaterialCertificateLineItemList} from './MaterialCertificateLineItemList'
import {useCementStyles} from './styles'
import {MaterialCertificate} from './types'
import {PageNames} from '../../../common/constants'

interface CementAsideProp {
  certificateDetails: MaterialCertificate | undefined
  setSelectedRow: (certificate?: MaterialCertificate) => void
  startDate: string | Date
  endDate: string | Date
  showCustomerPanel: boolean
  close: () => void
  smallScreen?: boolean
}

export const CementAside: React.FC<CementAsideProp> = ({
  certificateDetails,
  setSelectedRow,
  startDate,
  endDate,
  showCustomerPanel,
  close,
  smallScreen
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const classesCement = useCementStyles()
  const {classes} = useIconStyles()

  return showCustomerPanel ? (
    <CustomerFeedbackPanel close={close} entryPoint={PageNames.TEST_REPORTS} />
  ) : certificateDetails ? (
    <Box p={4}>
      <div data-test-id="cement-certificates-selected">
        <CustomHeader title={t('certificate.detailsTitle')}>
          <IconButton
            className={classes.icon}
            size="large"
            onClick={close}
            data-test-id="selected-cement-certificates-close"
          >
            <CloseIcon />
          </IconButton>
        </CustomHeader>

        <Grid container>
          <CertificateSubscriptionCard
            certificate={certificateDetails}
            setCertificate={setSelectedRow}
          />
          <Grid item xs={12}>
            <FieldValue
              label={t('certificate.columns.certificationDate')}
              value={
                certificateDetails.materialCertifications.length
                  ? dateFormatter(
                      isoDateFormatter(
                        certificateDetails.materialCertifications[0].certificationDate
                      ),
                      language
                    )
                  : '-'
              }
              dataTestId="certificate-date"
              gutter
            />
          </Grid>

          <Grid item xs={12}>
            <FieldValue
              label={t('certificate.columns.plant')}
              value={certificateDetails.plantName}
              dataTestId="certificate-plant"
              gutter
            />
          </Grid>

          <Grid item xs={12}>
            <FieldValue
              label={t('certificate.columns.product')}
              value={certificateDetails.materialDescription}
              dataTestId="certificate-material-desc"
              gutter
            />
          </Grid>
        </Grid>

        <CertificateGenerateButtonsAside
          certificateDetails={certificateDetails}
          startDate={startDate}
          endDate={endDate}
        />

        <Divider className={classesCement.horizontalLine} />

        <CertificateSubscribeButton
          certificate={certificateDetails}
          setCertificate={setSelectedRow}
        />

        <Divider className={classesCement.horizontalLine} />

        <MaterialCertificateLineItemList
          materialCertificationList={certificateDetails.materialCertifications}
          materialDescription={certificateDetails.materialDescription}
          plantName={certificateDetails.plantName}
        />
      </div>
    </Box>
  ) : smallScreen ? null : (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100%'
      }}
      data-test-id="no-cement-certificates-selected"
    >
      <NoCertificateSelectIcon />
      <Box
        sx={{
          maxWidth: '250px',
          textAlign: 'center'
        }}
      >
        {t('certificate.noCertificateSelected')}
      </Box>
    </Box>
  )
}
