import {Typography, useBreakPoints, useTranslation} from '@hconnect/uikit'
import {Box, makeStyles, Switch, Tab, Tabs} from '@material-ui/core'
import React from 'react'

type Props = {
  showExpired: boolean
  onShowExpiredChange: (showExpired: boolean) => void
  selectedTab: number
  onTabChange: (_, selectedTab: number) => void
  allLinksTabVisible: boolean
}

const useStyles = makeStyles((theme) => ({
  firstTab: {
    color: '#0C4F6D',
    textTransform: 'none',
    padding: '10px 17px',
    minHeight: 0,
    minWidth: 0,
    border: '1px solid #F0F0F0',
    borderRadius: '5px',
    height: '35px',
    letterSpacing: 'normal',
    [theme.breakpoints.down('sm')]: {
      width: '49%'
    }
  },
  secondTab: {
    color: '#0C4F6D',
    textTransform: 'none',
    padding: '10px 17px',
    minHeight: 0,
    border: '1px solid #F0F0F0',
    borderRadius: '5px',
    minWidth: 0,
    height: '35px',
    letterSpacing: 'normal',
    [theme.breakpoints.down('sm')]: {
      width: '49%'
    }
  },
  tabSelected: {
    background: '#0C4F6D',
    color: 'white',
    borderRadius: '4px',
    fontSize: '14px',
    lineHeight: '19px',
    fontWeight: 600,
    cursor: 'default'
  }
}))
export const InvitationsFilter: React.FC<Props> = ({
  showExpired,
  onShowExpiredChange,
  selectedTab,
  onTabChange,
  allLinksTabVisible
}) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        marginBottom: '1px',
        alignItems: isMobile ? 'normal' : 'center',
        justifyContent: 'space-between'
      }}
    >
      <Box sx={{borderBottom: 1, borderColor: 'divider', marginTop: '20px'}}>
        <Tabs
          value={selectedTab}
          onChange={onTabChange}
          TabIndicatorProps={{
            style: {
              display: 'none'
            }
          }}
          style={{width: isMobile ? '100%' : '200px'}}
        >
          {allLinksTabVisible && (
            <Tab
              label={t('invitations.allLinks')}
              className={classes.firstTab}
              classes={{selected: classes.tabSelected}}
            />
          )}
          <Tab
            label={t('invitations.myLinks')}
            className={classes.secondTab}
            classes={{selected: classes.tabSelected}}
          />
        </Tabs>
      </Box>
      <Box style={{display: 'flex', alignItems: 'center', alignSelf: 'center'}}>
        <Switch
          aria-label="Multiple download switch"
          size="medium"
          data-test-id="invitation-show-expired"
          checked={showExpired}
          onChange={(_, checked) => onShowExpiredChange(checked)}
        />
        <Box m={1} />
        <Typography variant="body1" color="textPrimary">
          {t('invitations.alsoShowInactive')}
        </Typography>
      </Box>
    </Box>
  )
}
