import blobStream from 'blob-stream'
// TODO fix import for Typescript support
import {TFunction} from 'i18next'
import PDFDocument from 'pdfkit/js/pdfkit.standalone'

export interface PdfData {
  date: string
  address: string
  titleText: string
  signatureText: string
  materialText: string[]
  job?: string
  contractor?: string
  masonry?: string
  architect?: string
  logoUrl: string
}

// eslint-disable-next-line max-statements
export const createPdfDocument = async (
  callback: (arg: string) => void,
  {
    date,
    address,
    job,
    contractor,
    masonry,
    architect,
    titleText,
    signatureText,
    materialText,
    logoUrl
  }: PdfData,
  t: TFunction
): Promise<void> => {
  // Create a document
  const doc = new PDFDocument({
    autoFirstPage: false
  })

  // Prevent default page to be able to control layout of first page, too.
  // "Every printer is different but 0.25" (6.35 mm) is a safe bet." - https://stackoverflow.com/a/3513476
  doc.addPage({
    size: 'A4',
    layout: 'portrait',
    // 1/4 inch
    margin: 40
  })

  // pipe the document to a blob
  const stream = doc.pipe(blobStream())

  /**
   * LH-Logo
   */

  const response = await fetch(logoUrl)
  const imageBuffer = await response.arrayBuffer()
  doc.image(imageBuffer, 340, 30)

  /**
   * Page Number
   */
  const baseTextProps = {width: 500}
  doc.font('Helvetica')
  doc.text(`${t('certificate.pdf.page')} 1/1`, baseTextProps)
  doc.moveDown(3)

  /**
   * Right Address below Logo
   */
  doc.fontSize(8)
  doc.fillColor('#004E2B')
  doc.text(titleText, {...baseTextProps, align: 'right'})

  /**
   * Date
   */
  doc.moveDown(6)
  doc.fillColor('black')
  doc.fontSize(12)
  doc.text(date, baseTextProps)

  /**
   * Address
   */

  doc.moveDown(3)
  doc.text(address, baseTextProps)

  /**
   * Optional Data
   */

  doc.moveDown(1.5)
  job && doc.text(`${t('certificate.pdf.job')}: ${job}`, baseTextProps)
  contractor && doc.text(`${t('certificate.pdf.contractor')}: ${contractor}`, baseTextProps)
  masonry && doc.text(`${t('certificate.pdf.masonry')}: ${masonry}`, baseTextProps)
  architect && doc.text(`${t('certificate.pdf.architect')}: ${architect}`, baseTextProps)

  /**
   * Main body
   */
  doc.moveDown(3)
  doc.font('Helvetica-Bold')
  doc.text(t('certificate.pdf.subject'), baseTextProps)
  doc.moveDown(3)
  doc.font('Helvetica')
  doc.text(`${t('certificate.pdf.dear')},`, baseTextProps)
  doc.moveDown(1)

  materialText.forEach((text) => {
    doc.text(text, baseTextProps)
    doc.moveDown(1)
  })

  /**
   * Signature
   */

  doc.moveDown(3)
  doc.text(signatureText)

  doc.end()

  stream.on('finish', () => callback(stream.toBlob('application/pdf')))
}

export const downloadBlob = (blob: unknown, name = 'file.txt'): void => {
  // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
  const blobUrl = URL.createObjectURL(blob as Blob)
  if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
    // for IE
    ;(window.navigator as any).msSaveOrOpenBlob(blob, name)
  } else {
    window.open(blobUrl)
  }
}

export const isIE11 = !!/Trident\/7\./.exec(navigator.userAgent)
