import {AxiosError} from 'axios'
import {TFunction} from 'i18next'

import {Step} from '../../Invite.types'

export const getErrorMessage = (t: TFunction, step: Step, error: AxiosError | null) => {
  console.error('getErrorMessage', step, error)
  switch (step) {
    case Step.ERROR_NO_CHANGE:
      return t('invite.receive.errorNoChange')

    case Step.ERROR_COUNTRY_MISMATCH:
      return t('invite.receive.errorCountryMismatch')

    case Step.ERROR_DEACTIVATED:
      return t('invite.receive.errorDeactivatedNoContact')

    case Step.ERROR_EXPIRED:
      return t('invite.receive.errorExpiredNoContact')

    case Step.ERROR_LIMIT_REACHED:
      return t('invite.receive.errorLimitReachedNoContact')

    case Step.ERROR_WRONG_LINK:
      return t('invite.receive.errorWrongLink')

    case Step.ERROR:
    case Step.ERROR_FROM_API:
    // fall through

    case Step.ERROR_CUSTOMERS_LIST_IS_EMPTY:
      return t('invite.receive.error')

    case Step.ERROR_DATASCOPE_CUSTOMER_IDS_EMPTY:
      return t('invite.receive.error')

    default:
      return ''
  }
}
