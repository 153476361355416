import {find} from 'lodash'
import get from 'lodash/get'
import {createSelector} from 'reselect'

import {AppState} from '../../Root.store'
import {selectCustomers} from '../Customers'
import {Customer} from '../Customers/Customers.types'

import {PayersStateType} from './Action.types'
import {Payer} from './Payers.types'

export const selectPayers = (state: AppState): PayersStateType => state.payers

export const selectSelectedPayerFilter = (state: AppState): Payer | null =>
  selectPayers(state).selectedPayer

export const selectPayerFromCustomer = (state: PayersStateType, customerId: string): Payer | null =>
  get(state.entities, [customerId, 0], null)

export const selectPayersForCustomer = createSelector(
  selectPayers,
  (_: any, selectedCustomer: Customer | null) => selectedCustomer,
  (payers, customer) => {
    if (customer) {
      return payers.entities[customer.customerId]
    }
    return []
  }
)

export interface CustomersPayers extends Partial<Customer> {
  items: Payer[]
}

export const selectCustomersPayers = createSelector(
  selectPayers,
  selectCustomers,
  (payers, customers) => {
    const {entities} = payers
    if (customers.customers && customers.customers.length > 0) {
      return customers.customers.reduce<CustomersPayers[]>((agg, customer) => {
        return [...agg, {...customer, items: entities[customer.countryId]}]
      }, [])
    }
    return []
  }
)

export const selectCustomerFromPayer = (payer: Payer, customers: Customer[]) => {
  const customerId = payer.parentCustomerId
  return customers.find((customer) => customer.customerId === customerId)
}

export const selectDefaultPayerFromCustomerId = (
  state: AppState,
  customerId?: string
): Payer | undefined => {
  if (!customerId) {
    return undefined
  }
  const {isFetching, entities} = selectPayers(state)
  return !isFetching && entities[customerId]
    ? selectPayers(state).entities[customerId].find(
        (payer) => payer.parentCustomerId === customerId
      )
    : undefined
}

export const selectSelectedPayer = (state: AppState, payerId) =>
  find(selectPayers(state).entities[payerId], (payer) => payer.payerId === payerId)
