import {Site} from './Sites.types'

export const SITES_FETCH_REQUEST = 'SITES_FETCH_REQUEST'
export const SITES_FETCH_RESPONSE = 'SITES_FETCH_RESPONSE'
export const SITES_FETCH_FAILURE = 'SITES_FETCH_FAILURE'

interface FetchSitesRequestAction {
  type: typeof SITES_FETCH_REQUEST
}

interface FetchSitesFailureAction {
  type: typeof SITES_FETCH_FAILURE
  error: any
}

interface FetchSitesResponseAction {
  type: typeof SITES_FETCH_RESPONSE
  response: Site[]
}

export type SitesActionTypes =
  | FetchSitesRequestAction
  | FetchSitesFailureAction
  | FetchSitesResponseAction

export interface SitesStateType {
  sites: Site[]
  isFetching: boolean
  error: any
}
