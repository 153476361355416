import {ContactInformation, MaterialTest, ShippingAddress} from './MaterialTest.types'

export const MATERIALTESTS = 'MATERIALTESTS'
export const MATERIALTESTS_CLEAR = 'MATERIALTESTS/CLEAR'
export const MATERIALTESTS_FILTERS = 'MATERIALTESTS/FILTERS'
export const MATERIALTESTS_LOAD_MORE = 'MATERIALTESTS/LOAD_MORE'
export const MATERIALTESTS_SET_SORT_KEY = 'MATERIALTESTS/SET_SORT_KEY'
export const MATERIALTESTS_FETCH_REQUEST = 'MATERIALTESTS/FETCH_REQUEST'
export const MATERIALTESTS_FETCH_SUCCESS = 'MATERIALTESTS/FETCH_SUCCESS'
export const MATERIALTESTS_FETCH_FAILURE = 'MATERIALTESTS/FETCH_FAILURE'
export const MATERIALTESTS_TOGGLE_CHECKED = 'MATERIALTESTS/MATERIALTESTS_TOGGLE_CHECKED'
export const MATERIALTESTS_TOGGLE_IS_MULTIPLE_DOWNLOAD_ACTIVE =
  'MATERIALTESTS/MATERIALTESTS_TOGGLE_IS_MULTIPLE_DOWNLOAD_ACTIVE'
export const MATERIALTESTS_UPDATE_DELIVERY_CHECKED_AFTER_FETCH =
  'MATERIALTESTS/MATERIALTESTS_UPDATE_DELIVERY_CHECKED_AFTER_FETCH'

interface ClearMaterialTestAction {
  type: typeof MATERIALTESTS_CLEAR
}
interface SetSortKeyAction {
  type: typeof MATERIALTESTS_SET_SORT_KEY
  payload: any
}
interface LoadMoreAction {
  type: typeof MATERIALTESTS_LOAD_MORE
}
interface FetchMaterialTestRequestAction {
  type: typeof MATERIALTESTS_FETCH_REQUEST
  params: any
}
interface FetchMaterialTestSuccessAction {
  type: typeof MATERIALTESTS_FETCH_SUCCESS
  params: any
  payload: MaterialTest[]
  showLoadMore: boolean
  receivedAt?: Date
}
interface FetchMaterialTestFailureAction {
  type: typeof MATERIALTESTS_FETCH_FAILURE
  error: boolean
  payload: any
}

export type MaterialTestsActionTypes =
  | ClearMaterialTestAction
  | SetSortKeyAction
  | LoadMoreAction
  | FetchMaterialTestRequestAction
  | FetchMaterialTestSuccessAction
  | FetchMaterialTestFailureAction

export interface MaterialTestsStateType {
  byOrderNumber: GroupedByOrderNumber
  allOrderNumbers: string[]
  sortOrder: {
    key: string
    asc: boolean
  }
  skip: number
  limit: number
  showLoadMore: boolean
  error: any
  isFetching: boolean
  filters: any
  defaultFilters: any
}
export interface GroupedByOrderNumber {
  [key: string]: {
    shippingDate: Date | string
    shippingAddress: ShippingAddress
    contactInformation: ContactInformation
    orderNumber: string
    available: boolean
    items: MaterialTest[]
  }
}
