import {Delivery} from '../../Pages/Finance/Invoices/Delivery.types'
import {Order} from '../../Pages/Order/Order.types'

export const ORDERS = 'ORDERS'
export const ORDERS_CLEAR = 'ORDERS/CLEAR'
export const ORDERS_FILTERS = 'ORDERS/FILTERS'
export const ORDERS_LOAD_MORE = 'ORDERS/LOAD_MORE'
export const ORDERS_SET_RESULTS_PER_PAGE = 'ORDERS_SET_RESULTS_PER_PAGE'
export const ORDERS_SET_SORT_KEY = 'ORDERS/SET_SORT_KEY'
export const ORDERS_FETCH_REQUEST = 'ORDERS/FETCH_REQUEST'
export const ORDERS_FETCH_SUCCESS = 'ORDERS/FETCH_SUCCESS'
export const ORDERS_FETCH_FAILURE = 'ORDERS/FETCH_FAILURE'

export const DELIVERIES_TOGGLE_CHECKED = 'ORDERS/DELIVERIES_TOGGLE_CHECKED'
export const ORDERS_UPDATE_DELIVERY_CHECKED_AFTER_FETCH =
  'ORDERS/ORDERS_UPDATE_DELIVERY_CHECKED_AFTER_FETCH'
export const DELIVERIES = 'ORDERS/DELIVERIES'
export const DELIVERIES_ORDERS_FETCH_REQUEST = 'DELIVERIES/FETCH_REQUEST'
export const DELIVERIES_ORDERS_FETCH_SUCCESS = 'DELIVERIES/FETCH_SUCCESS'
export const DELIVERIES_ORDERS_FETCH_FAILURE = 'DELIVERIES/FETCH_FAILURE'

interface ClearOrders {
  type: typeof ORDERS_CLEAR
}
interface SetSortKey {
  type: typeof ORDERS_SET_SORT_KEY
  payload: string
}

interface LoadMore {
  type: typeof ORDERS_LOAD_MORE
}

interface SetOrdersResultsPerPage {
  type: typeof ORDERS_SET_RESULTS_PER_PAGE
  payload: number
}

interface FetchOrdersRequest {
  type: typeof ORDERS_FETCH_REQUEST
  params: any
}

// TODO: Error type in Payload
interface FetchOrdersFailure {
  type: typeof ORDERS_FETCH_FAILURE
  payload: any
  error: boolean
}

interface FetchOrdersSuccess {
  type: typeof ORDERS_FETCH_SUCCESS
  payload: Order[]
  params: any
  showLoadMore: boolean
  receivedAt: Date
}

export type OrdersActionTypes =
  | ClearOrders
  | SetSortKey
  | LoadMore
  | FetchOrdersRequest
  | FetchOrdersFailure
  | FetchOrdersSuccess
  | SetOrdersResultsPerPage

interface FetchDeliveriesRequest {
  type: typeof DELIVERIES_ORDERS_FETCH_REQUEST
  orderId: string
}

interface FetchDeliveriesFailure {
  type: typeof DELIVERIES_ORDERS_FETCH_FAILURE
  payload: any
  error: boolean
  orderId: string
}

interface FetchDeliveriesSuccess {
  type: typeof DELIVERIES_ORDERS_FETCH_SUCCESS
  payload: Delivery[]
  orderId: string
}

export type OrderDeliveriesActionTypes =
  | FetchDeliveriesFailure
  | FetchDeliveriesRequest
  | FetchDeliveriesSuccess

export interface NormalizedOrderStoreType {
  [key: string]: any
}
export interface NormalizedDeliveryStoreType {
  [key: string]: Delivery
}
export interface OrderAbstractState {
  byId: NormalizedOrderStoreType
  allIds: string[]
  sortOrder: {
    key: string
    asc: boolean
  }
  skip: number
  limit: number
  error: any
  isFetching: boolean
  filters: any
  defaultFilters: any
  showLoadMore: boolean
}

export interface OrderDeliveriesStateType {
  loading: boolean
  byId: NormalizedDeliveryStoreType
  allIds: string[]
  error?: any
}
