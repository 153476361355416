import {useMemo} from 'react'

import {featuresConfig} from '../../../Config/featuresConfig'

interface Constraint {
  country: string
  businessLines: string[]
}

export const useIsBusinessLineAllowed = (
  featureName: string,
  country: string,
  businessLine: string
): boolean => {
  const featureConfig = useMemo(() => featuresConfig[featureName], [featureName])

  return featureConfig.constraints.some(
    (constraint: Constraint) =>
      constraint.country === country && constraint.businessLines.includes(businessLine)
  )
}
