import {AppState} from '../../Root.store'

export const selectMaterialTests = (state: AppState) => state.materialTest.materialTests

export const selectMaterialTest = (state: AppState, materialTestId: string) =>
  selectMaterialTests(state).byOrderNumber[materialTestId]

export const selectAllMaterialTests = (state: AppState) =>
  selectMaterialTests(state).allOrderNumbers.map((materialTestId: string) =>
    selectMaterialTest(state, materialTestId)
  )
