import {Site} from '../Sites'

import {Project, ProjectSitesDropdownSiteItem, ProjectWithSites} from './Projects.types'

export const mapProjectsAndSites = (
  projects: Project[],
  sites: Site[]
): ProjectWithSites[] | ProjectSitesDropdownSiteItem[] => {
  const projectsById: ProjectWithSites[] = Object.assign(
    {},
    ...(projects || []).map((project: Project) => ({
      [project.projectId]: {
        ...project,
        items: []
      }
    }))
  )

  sites &&
    sites.forEach((site: Site) => site.projectId && projectsById[site.projectId]?.items.push(site))

  const sitesWithoutProjectIds: ProjectSitesDropdownSiteItem = {
    items: []
  }

  sites &&
    sites
      .filter((site: Site) => site.projectId === null || site.projectId === undefined)
      .forEach((site: Site) => sitesWithoutProjectIds.items.push(site))

  const projectsWithSites: ProjectWithSites[] | ProjectSitesDropdownSiteItem[] = [
    ...Object.values(projectsById),
    sitesWithoutProjectIds
  ]

  return projectsWithSites
}
