import {trackEventWithBrowserProps} from '@hconnect/common/logging/Analytics'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useRevokeRoleRequestMutation} from '../../../hooks/useRoleRequestMutation'

type RevokeRoleRequestDialogProps = {
  toBeRevokedUserId: string | null
  setToBeRevokedUserId: (userId: string | null) => void
  loggedInUserId: string
  roleRequestId?: string
}

export const RevokeRoleRequestDialog = ({
  roleRequestId,
  setToBeRevokedUserId,
  toBeRevokedUserId,
  loggedInUserId
}: RevokeRoleRequestDialogProps) => {
  const {t} = useTranslation()
  const revokeRoleRequestMutation = useRevokeRoleRequestMutation()

  const revokeRoleRequest = (userId: string) => {
    if (!roleRequestId) {
      return
    }
    void revokeRoleRequestMutation
      .mutateAsync({roleRequestId, userId})
      .then(() => {
        trackEventWithBrowserProps('inviteRevokeSuccess', {
          product: 'hub',
          date: new Date().toISOString(),
          userIdOfRevokedUser: userId,
          roleRequestId,
          userId: loggedInUserId
        })
      })
      .catch((error) => {
        trackEventWithBrowserProps('inviteRevokeFailed', {
          product: 'hub',
          date: new Date().toISOString(),
          userIdOfRevokedUser: userId,
          userId: loggedInUserId,
          roleRequestId,
          detail: error.response.data.detail
        })
      })
  }

  return (
    <Dialog
      open={!!toBeRevokedUserId}
      onClose={() => setToBeRevokedUserId(null)}
      aria-labelledby="roleRequest-revoke-confirmation-dialog"
      aria-describedby="roleRequest-revoke-confirmation-dialog-description"
      data-test-id={`roleRequest-revoke-confirmation-dialog-${roleRequestId}-userId-${toBeRevokedUserId}`}
    >
      <DialogTitle id="roleRequest-revoke-confirmation-dialog">
        {t('invitations.revokeConfirmationTitle')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="roleRequest-revoke-confirmation-dialog-description"
          style={{color: '#00374d'}}
        >
          {t('invitations.revokeConfirmationText')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setToBeRevokedUserId(null)}
          color="primary"
          variant="text"
          data-test-id={`roleRequest-revoke-confirmation-dialog-${roleRequestId}-userId-${toBeRevokedUserId}-cancel`}
        >
          {t('invitations.cancel')}
        </Button>
        <Button
          onClick={() => {
            toBeRevokedUserId && revokeRoleRequest(toBeRevokedUserId)
            setToBeRevokedUserId(null)
          }}
          variant="text"
          style={{color: '#CA605F'}}
          data-test-id={`roleRequest-revoke-confirmation-dialog-${roleRequestId}-userId-${toBeRevokedUserId}-confirm`}
        >
          {t('invitations.revoke')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
