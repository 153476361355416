import {useQuery} from 'react-query'

import {DefaultSettings} from '../Invite.types'

import {getRequestPathData} from './utils'

const fetchRoleRequestsDefaultSettings = ({queryKey}) => {
  const [endpoint] = queryKey
  return getRequestPathData<DefaultSettings>(endpoint)
}

export const useDefaultSettings = () => {
  return useQuery('/roleRequests/defaultSettings', fetchRoleRequestsDefaultSettings)
}
