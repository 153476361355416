import {Branding} from '@hconnect/common/branding/Branding.types'

import {BrandingActionTypes, SET_BRANDING} from './Action.types'

export interface BrandingState {
  config: Branding
}

export const initialBrandingState: BrandingState = {
  config: {
    nationalBrandName: undefined,
    nationalLogoUrl: undefined,
    nationalLogoTitle: undefined,
    nationalUrl: undefined,
    localBrandName: undefined,
    localLogoUrl: undefined,
    localLogoTitle: undefined,
    contactEmail: undefined,
    contactEmailSubject: undefined,
    pageTitle: undefined,
    country: undefined
  }
}

export const brandingReducer = (state = initialBrandingState, action: BrandingActionTypes) => {
  switch (action.type) {
    case SET_BRANDING:
      return {
        ...state,
        config: action.data
      }

    default:
      return state
  }
}
