import {AxiosResponse} from 'axios'

import {api, publicApi} from '../../App.global'

import {
  Feature,
  FeaturesActions,
  FeaturesDispatch,
  FEATURES_FAILURE,
  FEATURES_REQUEST,
  FEATURES_SUCCESS
} from './Features.types'

export const fetchFeaturesRequest = (): FeaturesActions => ({
  type: FEATURES_REQUEST
})

export const fetchFeaturesFailure = (error: any): FeaturesActions => ({
  type: FEATURES_FAILURE,
  payload: error
})

export const fetchFeaturesSuccess = (features: Feature[]): FeaturesActions => ({
  type: FEATURES_SUCCESS,
  payload: features
})

export const fetchFeatures = async (dispatch: FeaturesDispatch, publicHttp?: boolean) => {
  dispatch(fetchFeaturesRequest())
  try {
    const network = publicHttp ? publicApi : api
    const response: AxiosResponse<Feature[]> = await network.get('features?tag=hub')
    dispatch(fetchFeaturesSuccess(response.data))
  } catch (e) {
    fetchFeaturesFailure(e)
  }
}
