import {CashInfoItemTypes} from './CashBalance.enum'
import {CashInfoBoxStyling} from './CashBalance.types'

export const getPercentageAsString = (fraction: number, total: number, decimals: number): string =>
  ((fraction / total) * 100).toFixed(decimals)

export const getBoxTitleAndStyling = (
  variant: CashInfoItemTypes,
  hasDeposit?: boolean
): CashInfoBoxStyling => {
  if (variant === 'unpaid') {
    return {
      title: 'overview.cashBalance.unpaid',
      styleClass: 'unPaidBoxBorder',
      isTotalAmount: false
    }
  }
  if (variant === 'notYetInvoiced') {
    return {
      title: 'overview.cashBalance.notYetInvoiced',
      styleClass: 'notInvoicedBoxBorder',
      isTotalAmount: false
    }
  }
  if (variant === 'notYetInvoicedNotYetDispatched') {
    return {
      title: 'overview.cashBalance.notYetInvoiced',
      styleClass: 'notInvoicedNotYetDispatchedBoxBorder',
      isTotalAmount: false
    }
  }
  if (hasDeposit) {
    return {
      title: 'overview.cashBalance.balanceLeft',
      styleClass: 'totalBoxBorder',
      isTotalAmount: true
    }
  }
  return {
    title: 'overview.cashBalance.creditLeft',
    styleClass: 'totalBoxBorder',
    isTotalAmount: true
  }
}
