import {RoleRequest as RoleRequestQueryInfo} from '@hconnect/apiclient'
import {Typography, useBreakPoints, useTranslation} from '@hconnect/uikit'
import {Box} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import React, {CSSProperties, useMemo} from 'react'

import {useOrgUnitConfiguration} from '../../../hooks/useConfigurations'
import {useCustomerById} from '../../../hooks/useCustomers'
import {usePayerById} from '../../../hooks/usePayers'
const ERROR_ORANGE = '#ffa500'

const useStyles = makeStyles((theme) => ({
  subtitle: {
    color: '#002E4D',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'none',
      whiteSpace: 'normal',
      overflow: 'visible',
      textOverflow: 'clip'
    },
    maxWidth: '200px'
  },
  title: {
    margin: 0,
    fontSize: 18,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}))

type InvitationsListCustomerNameCellProps = {
  roleRequest: RoleRequestQueryInfo
}
export const InvitationsListCustomerNameCell = ({
  roleRequest
}: InvitationsListCustomerNameCellProps) => {
  const role = roleRequest.roles[0]
  const {dataScope} = role
  const orgUnitConfigurationQueryInfo = useOrgUnitConfiguration(
    dataScope?.businessLine,
    dataScope?.countryId
  )
  const {t} = useTranslation()
  const styles = useStyles()
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)
  const orgUnitConfiguration = orgUnitConfigurationQueryInfo.data?.find(
    ({orgUnitId}) => orgUnitId === dataScope.orgUnitId
  )

  const countryBusinessLineAndOrgUnit = !role
    ? ''
    : `${dataScope?.countryId}/${dataScope?.businessLine}/${
        orgUnitConfiguration?.orgUnitName ?? ''
      }`

  const customerId = dataScope.customerIds?.length ? dataScope.customerIds[0] : undefined
  const payerId = dataScope.payerIds?.length ? dataScope.payerIds[0] : undefined
  const customerDetailsQueryInfo = useCustomerById(customerId)
  const payerDetailsQueryInfo = usePayerById(payerId)
  const name = customerDetailsQueryInfo.data?.customerName || payerDetailsQueryInfo.data?.payerName

  const countProjectAndSites = useMemo(() => {
    const projectIds = role?.dataScope?.projectIds ?? []
    const siteIds = role?.dataScope?.siteIds ?? []

    return `${projectIds?.length ? projectIds?.length : t('invitations.all')} ${t(
      'invitations.projects'
    )} ${siteIds?.length ? siteIds?.length : t('invitations.all')} ${t('invitations.sites')}`
  }, [t, role])

  const renderCustomerName = () => {
    const nowrap: CSSProperties = {
      whiteSpace: isMobile ? 'normal' : 'nowrap',
      overflow: isMobile ? 'visible' : 'hidden',
      textOverflow: isMobile ? 'clip' : 'ellipsis',
      maxWidth: isMobile ? 'none' : '200px',
      marginTop: '3px'
    }

    if (customerDetailsQueryInfo.error) {
      return (
        <Typography
          paragraph
          data-test-id={`${roleRequest.id}-customerName`}
          className={styles.title}
          style={{
            color: ERROR_ORANGE,
            ...nowrap
          }}
        >
          {`${t('invitations.customer not found')}.${customerId}`}
        </Typography>
      )
    }

    return (
      <Typography
        paragraph
        data-test-id={`${roleRequest.id}-customerName`}
        style={{color: '#002E4D', ...nowrap}}
        className={styles.title}
      >
        {name}
      </Typography>
    )
  }

  return (
    <Box data-test-id={`${roleRequest.id}-mainBox`}>
      <Typography variant="subtitle2" className={styles.subtitle}>
        {countryBusinessLineAndOrgUnit}
      </Typography>
      {renderCustomerName()}
      <Typography variant="subtitle2" className={styles.subtitle}>
        {countProjectAndSites}
      </Typography>
    </Box>
  )
}
