import {Site, Project} from '@hconnect/apiclient'
import {Box, Input, Tab, Tabs, Typography} from '@material-ui/core'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {ProjectsSitesSelection} from './ProjectsSitesSelection'
import {ProjectAndSitesTableProps} from './ProjectsSubSitesSelection'
import {useStyles} from './styles'

export interface ProjectsSitesState {
  all: boolean
  projectsIds: string[]
  sitesIds: string[]
}

export const defaultState: ProjectsSitesState = {
  all: true,
  projectsIds: [],
  sitesIds: []
}

type Props = {
  modalState: ProjectsSitesState
  hasSitesEnabled?: boolean
  hasProjectsEnabled?: boolean
  setModalState: (modalState: ProjectsSitesState) => void
  projectsData: Project[]
  sitesData: Site[]
  loading: boolean
}

export const ProjectsSitesModal: React.FC<Props> = ({
  modalState,
  hasSitesEnabled = false,
  hasProjectsEnabled = false,
  setModalState,
  sitesData,
  projectsData,
  loading
}) => {
  const {t} = useTranslation()

  const [filter, setFilter] = useState<string>('')

  const [filteredProjectsAndSitesRows, setFilteredProjectsAndSitesRows] = useState<
    ProjectAndSitesTableProps[]
  >([])

  // distinct Projects
  const projects = Array.from(new Set(projectsData.map((project) => project.projectId))).map(
    (projectId) => projectsData.find((project) => project.projectId === projectId) as Project
  )

  // distinct Sites
  const sites = Array.from(new Set(sitesData.map((site) => site.siteId))).map(
    (siteId) => sitesData.find((site) => site.siteId === siteId) as Site
  )

  const classes = useStyles()

  const applyProjectsFilter = (availableProjects: Project[]) => {
    if (!filter) return availableProjects
    return availableProjects.filter((project) =>
      project.projectName.toLowerCase().includes(filter.toLowerCase())
    )
  }

  const applySitesFilter = (availableSites: Site[]) => {
    if (!filter) return availableSites
    return availableSites.filter((site) =>
      site.siteName.toLowerCase().includes(filter.toLowerCase())
    )
  }

  const projectsIds = modalState.projectsIds
  const sitesIds = modalState.sitesIds

  const [tab, setTab] = useState(1)
  const calcProjects = () => {
    if (tab === 1) {
      return projects.filter((project) => projectsIds.includes(project.projectId))
    }

    return projects
  }

  const calcSites = () => {
    if (tab === 1) {
      return sites.filter((site) => sitesIds.includes(site.siteId))
    }

    return sites
  }

  const [selectedProjectsAndSites, setSelectedProjectsAndSites] = useState<string[]>([])

  useEffect(() => {
    const filteredProjectIds = selectedProjectsAndSites.filter((value) =>
      projects.map((project) => project.projectId).includes(value)
    )
    const filteredSiteIds = selectedProjectsAndSites.filter((value) =>
      sites.map((site) => site.siteId).includes(value)
    )
    setModalState({
      ...modalState,
      projectsIds: filteredProjectIds,
      sitesIds: filteredSiteIds
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProjectsAndSites])

  useEffect(() => {
    if (filter) {
      const filtered = allProjectsAndSitesRows.filter(
        (value) =>
          value.row.address.toLowerCase().includes(filter) ||
          value.row.name.toLowerCase().includes(filter)
      )
      setFilteredProjectsAndSitesRows(filtered)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  const siteRows: ProjectAndSitesTableProps[] = sites
    .filter(
      (relatedSite) =>
        !projects.map((relatedProject) => relatedProject.projectId).includes(relatedSite.projectId)
    )
    .map((site) => ({
      row: {
        id: site.siteId,
        name: site.siteName,
        address: site.street + ',' + site.city
      }
    }))

  const projectRows: ProjectAndSitesTableProps[] = projects.map((project) => ({
    row: {
      id: project.projectId,
      name: project.projectName,
      address: ''
    },
    isProject: true,
    assignedSitesRows: sites
      .filter((site) => project.projectId === site.projectId)
      .map((value) => ({
        id: value.siteId,
        name: value.siteName,
        address: value.street,
        siteNumber: value.siteNumber
      }))
  }))

  const assignedSitesRowsFilter = (value: ProjectAndSitesTableProps) =>
    value.assignedSitesRows && value.assignedSitesRows.length > 0
      ? true
      : selectedProjectsAndSites.includes(value.row.id)

  const allProjectsAndSitesRows = siteRows
    .concat(projectRows)
    .map((value) =>
      tab === 0 && value.assignedSitesRows
        ? {
            ...value,
            assignedSitesRows: value.assignedSitesRows.filter((values) =>
              selectedProjectsAndSites.includes(values.id)
            )
          }
        : value
    )
    .filter((value) => (tab === 1 ? true : assignedSitesRowsFilter(value)))

  return (
    <div className={classes.paper} data-test-id="projectsSitesModalPaper">
      <div>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <Typography variant="caption" style={{marginBottom: '20px', fontSize: '14px'}}>
            Please specify which projects and location
          </Typography>
          <div
            className={classes.headerRow}
            style={{justifyContent: 'space-between', flexWrap: 'wrap', gap: '14px'}}
          >
            <div className={classes.headerRow}>
              <Tabs
                value={tab}
                TabIndicatorProps={{
                  style: {
                    display: 'none'
                  }
                }}
                onChange={(_, value) => setTab(value)}
                className={classes.tabContainer}
              >
                <Tab
                  data-test-id="selectedTab"
                  label={t('invitations.selectedProjects', {
                    count: selectedProjectsAndSites.length
                  })}
                  className={classes.firstTab}
                  classes={{
                    selected: classes.tabSelected
                  }}
                />
                <Tab
                  data-test-id="allTab"
                  label={t('invitations.viewAllProjects')}
                  className={classes.secondTab}
                  classes={{
                    selected: classes.tabSelected
                  }}
                />
              </Tabs>
            </div>
            <div className={classes.headerRow}>
              <Input
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                placeholder="Search"
                className={classes.searchInput}
                disableUnderline={true}
              />
            </div>
          </div>
          <Box className={classes.infoContainer}>
            <Typography variant="caption">{t('roleAssignment.onlyProjectsThreeMonths')}</Typography>
          </Box>
        </div>
      </div>
      <ProjectsSitesSelection
        projectsIds={modalState.projectsIds}
        sitesIds={modalState.sitesIds}
        key={`${hasSitesEnabled}-${hasProjectsEnabled}`}
        hasSitesEnabled={hasSitesEnabled}
        hasProjectsEnabled={hasProjectsEnabled}
        loading={loading}
        applyProjectsFilter={applyProjectsFilter}
        applySitesFilter={applySitesFilter}
        calcProjects={calcProjects}
        calcSites={calcSites}
        selectedProjectsAndSites={selectedProjectsAndSites}
        setSelectedProjectsAndSites={setSelectedProjectsAndSites}
        rows={filter ? filteredProjectsAndSitesRows : allProjectsAndSitesRows}
      />
    </div>
  )
}
