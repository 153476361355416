import {trackEvent} from '@hconnect/common/logging/Analytics'
import {AxiosRequestConfig, AxiosResponse} from 'axios'

import {errorKeyFromRequestError} from '../../ErrorMap'
import {CheckboxIndetermiateType} from '../../Molecules/CheckboxDropdown'
import {Invoice} from '../../Pages/Finance/Invoices/Invoice.types'
import {RequestCanceller, getErrorMessage} from '../../util/action.helper'
import {errorsAdd, errorsClear} from '../Errors'
import {
  clearDefaultFiltersAbstract,
  clearFiltersAbstract,
  removeDefaultFilterAbstract,
  removeFilterAbstract,
  setDefaultFilterAbstract,
  setFilterAbstract
} from '../Filters'

import {
  INVOICES,
  INVOICES_CLEAR,
  INVOICES_FETCH_FAILURE,
  INVOICES_FETCH_REQUEST,
  INVOICES_FETCH_SUCCESS,
  INVOICES_LOAD_MORE,
  INVOICES_MULTI_DOWNLOAD_CHECK_ALL_DELIVERIES,
  INVOICES_MULTI_DOWNLOAD_CHECK_ALL_INVOICES,
  INVOICES_MULTI_DOWNLOAD_TOGGLE_CHECKBOX_ALL,
  INVOICES_MULTI_DOWNLOAD_TOGGLE_CHECKBOX_DELIVERY,
  INVOICES_MULTI_DOWNLOAD_TOGGLE_CHECKBOX_INVOICE,
  INVOICES_MULTI_DOWNLOAD_TOGGLE_CHECKBOX_TABLEROW,
  INVOICES_MULTI_DOWNLOAD_TOGGLE_SWITCH_MULTIPLE_DOWNLOAD,
  INVOICES_SET_EXPANDED,
  INVOICES_SET_SORT_KEY,
  INVOICES_SET_RESULTS_PER_PAGE,
  InvoicesActionTypes
} from './Action.types'

export const setFilter = (filterName: string, filterValue) =>
  setFilterAbstract(INVOICES, filterName, filterValue)
export const setDefaultFilter = (filterName: string, filterValue) =>
  setDefaultFilterAbstract(INVOICES, filterName, filterValue)
export const removeFilter = (filterName: string) => removeFilterAbstract(INVOICES, filterName)
export const removeDefaultFilter = (filterName: string) =>
  removeDefaultFilterAbstract(INVOICES, filterName)
export const clearFilters = () => clearFiltersAbstract(INVOICES)
export const clearDefaultFilters = () => clearDefaultFiltersAbstract(INVOICES)

const InvoiceCanceller = RequestCanceller()
const InvoicesCanceller = RequestCanceller()

export const clear = (): InvoicesActionTypes => ({
  type: INVOICES_CLEAR
})

export const setSortKey = (key: string): InvoicesActionTypes => ({
  type: INVOICES_SET_SORT_KEY,
  payload: key
})

export const loadMore = (): InvoicesActionTypes => ({
  type: INVOICES_LOAD_MORE
})

export const setInvoicesResultsPerPage = (amount: number): InvoicesActionTypes => ({
  type: INVOICES_SET_RESULTS_PER_PAGE,
  payload: amount
})

export const setExpanded = (invoiceId: string, expanded: boolean): InvoicesActionTypes => ({
  type: INVOICES_SET_EXPANDED,
  invoiceId,
  expanded
})

export const fetchInvoicesRequest = (params) => ({
  type: INVOICES_FETCH_REQUEST,
  params
})

export const fetchInvoicesSuccess = (
  params: any,
  payload: Invoice[],
  showLoadMore: boolean,
  totalCount: number,
  totalGrossValue: number
): InvoicesActionTypes => ({
  type: INVOICES_FETCH_SUCCESS,
  params,
  payload,
  showLoadMore,
  totalCount,
  totalGrossValue,
  receivedAt: new Date()
})

export const fetchInvoicesFailure = (): InvoicesActionTypes => ({
  type: INVOICES_FETCH_FAILURE
})

// TODO: Add types to customFilters
export const fetchInvoices =
  (customFilters: any, lookup?: any, analyticsId?: string) =>
  async (dispatch, getState, {api}) => {
    dispatch(errorsClear())
    // inform UI that we are going to load data now

    dispatch(fetchInvoicesRequest(customFilters))
    if (Object.keys(customFilters).includes('invoiceId')) {
      const expand = 'itemBreakdown'

      try {
        const {invoiceId, customerId} = customFilters
        InvoiceCanceller.cancel('Request canceled by the next request.')
        const requestConfig: AxiosRequestConfig = {
          params: {expand, customerId},
          cancelToken: InvoiceCanceller.getToken()
        }
        const response: AxiosResponse<Invoice> = await api
          .get(`invoices/${invoiceId}`, requestConfig)
          .catch((thrown: any) => {
            if (!InvoicesCanceller.isCancel(thrown)) {
              throw thrown
            }
          })
        if (response) {
          const {data, headers} = response
          const totalCount = headers['total-count'] ? +headers['total-count'] : 0
          const totalGrossValue = parseFloat(headers['total-gross-value']) || 0

          dispatch(fetchInvoicesSuccess(customFilters, [data], false, totalCount, totalGrossValue))
          return {...response, data}
        }
      } catch (err) {
        const errorKey = errorKeyFromRequestError(err)
        dispatch(
          errorsAdd(getErrorMessage('invoice', errorKey, dispatch, fetchInvoices, customFilters))
        )
        dispatch(fetchInvoicesFailure())
      }
    } else {
      try {
        InvoicesCanceller.cancel('Request canceled by the next request.')
        const requestConfig: AxiosRequestConfig = {
          params: {...customFilters},
          cancelToken: InvoicesCanceller.getToken()
        }
        const response: AxiosResponse<Invoice[]> = await api
          .get('invoices', requestConfig)
          .catch((thrown: any) => {
            if (!InvoicesCanceller.isCancel(thrown)) {
              throw thrown
            }
          })
        if (response) {
          const {data, headers} = response
          const totalCount = +headers['total-count']
          const totalGrossValue = parseFloat(headers['total-gross-value']) || 0
          const {limit} = getState().finance.invoices
          const payload = data.slice(0, limit)
          const showLoadMore = data.length > payload.length
          if (lookup && totalCount === 0) {
            trackEvent('hubLookupFailed', {
              product: 'hub',
              lookupCategory: lookup?.entity,
              lookupValue: lookup?.value,
              lookupSource: 'invoices',
              customerId: customFilters?.customerId,
              analyticsId
            })
          }
          dispatch(
            fetchInvoicesSuccess(customFilters, payload, showLoadMore, totalCount, totalGrossValue)
          )
          return response
        }
      } catch (err) {
        if (lookup) {
          trackEvent('hubLookupFailed', {
            product: 'hub',
            lookupCategory: lookup?.entity,
            lookupValue: lookup?.value,
            lookupSource: 'invoices',
            customerId: customFilters?.customerId,
            analyticsId
          })
        }
        const errorKey = errorKeyFromRequestError(err)
        dispatch(
          errorsAdd(getErrorMessage('invoice', errorKey, dispatch, fetchInvoices, customFilters))
        )
        dispatch(fetchInvoicesFailure())
      }
    }
  }

export const toggleSwitchMultipleDownload = (): InvoicesActionTypes => ({
  type: INVOICES_MULTI_DOWNLOAD_TOGGLE_SWITCH_MULTIPLE_DOWNLOAD
})

export const toggleCheckboxInvoice = (
  invoiceId: string,
  checked: boolean
): InvoicesActionTypes => ({
  type: INVOICES_MULTI_DOWNLOAD_TOGGLE_CHECKBOX_INVOICE,
  invoiceId,
  checked
})
export const toggleCheckTableRow = (
  rowId: string,
  checked: CheckboxIndetermiateType
): InvoicesActionTypes => ({
  type: INVOICES_MULTI_DOWNLOAD_TOGGLE_CHECKBOX_TABLEROW,
  rowId,
  checked
})

export const toggleCheckboxDelivery = (
  deliveryId: string,
  invoiceId: string,
  checked: boolean
): InvoicesActionTypes => ({
  type: INVOICES_MULTI_DOWNLOAD_TOGGLE_CHECKBOX_DELIVERY,
  deliveryId,
  invoiceId,
  checked
})

export const toggleAllCheckboxChecked = (currentCheckedState: CheckboxIndetermiateType) => ({
  type: INVOICES_MULTI_DOWNLOAD_TOGGLE_CHECKBOX_ALL,
  checked: currentCheckedState
})

export const checkAllInvoices = () => ({
  type: INVOICES_MULTI_DOWNLOAD_CHECK_ALL_INVOICES
})

export const checkAllDeliveries = () => ({
  type: INVOICES_MULTI_DOWNLOAD_CHECK_ALL_DELIVERIES
})
