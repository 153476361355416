import {OrderStatus, ShippingType} from '../../Pages/Order/Order.types'

export enum OrderStatusAggregate {
  Reserved = 'reserved',
  Pending = 'pending',
  Unconfirmed = 'unconfirmed',
  Confirmed = 'confirmed',
  Cancelled = 'cancelled',
  Active = 'active',
  Completed = 'completed',
  Rejected = 'rejected',
  Closed = 'closed',
  PaymentPending = 'paymentPending'
}

export const DefaultOrderStatusAggregate = OrderStatusAggregate.Unconfirmed

// eslint-disable-next-line complexity
export const statusFromOrderStatus = (orderStatus: OrderStatus, shippingType?: ShippingType) => {
  switch (orderStatus) {
    case OrderStatus.Reserved:
      return OrderStatusAggregate.Reserved

    case OrderStatus.Pending:
      return OrderStatusAggregate.Pending

    case OrderStatus.Confirmed:
      return OrderStatusAggregate.Confirmed

    case OrderStatus.Cancelled:
      return OrderStatusAggregate.Cancelled

    case OrderStatus.InProgress:
      return OrderStatusAggregate.Active

    case OrderStatus.PartiallyDelivered:
      return OrderStatusAggregate.Active

    case OrderStatus.PartiallyShipped:
      return OrderStatusAggregate.Active

    case OrderStatus.Shipped:
      if (shippingType && shippingType === ShippingType.Collect) {
        return OrderStatusAggregate.Completed
      }
      return OrderStatusAggregate.Active

    case OrderStatus.Delivered:
      return OrderStatusAggregate.Completed

    case OrderStatus.Rejected:
      return OrderStatusAggregate.Rejected

    case OrderStatus.Closed:
      return OrderStatusAggregate.Closed

    case OrderStatus.PaymentPending:
      return OrderStatusAggregate.PaymentPending

    default:
      return DefaultOrderStatusAggregate
  }
}

export enum OrderShippingType {
  Deliver = 'deliver',
  Collect = 'collect'
}
