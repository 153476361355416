export const DOCUMENT_DOWNLOAD_REQUEST = 'DOCUMENT/DOWNLOAD_REQUEST'
export const DOCUMENT_DOWNLOAD_SUCCESS = 'DOCUMENT/DOWNLOAD_SUCCESS'
export const DOCUMENT_DOWNLOAD_FAILURE = 'DOCUMENT/DOWNLOAD_FAILURE'

interface DownloadDocumentRequestType {
  type: typeof DOCUMENT_DOWNLOAD_REQUEST
  data: any
}

interface DownloadDocumentSuccessType {
  type: typeof DOCUMENT_DOWNLOAD_SUCCESS
  data: any
}

interface DownloadDocumentFailureType {
  type: typeof DOCUMENT_DOWNLOAD_FAILURE
  payload: any
  receivedAt: string
}

export type DocumentActionTypes =
  | DownloadDocumentRequestType
  | DownloadDocumentSuccessType
  | DownloadDocumentFailureType

export interface DocumentStateType {
  loading: boolean
  document: any
}
