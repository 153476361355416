import {TextButton, Typography} from '@hconnect/uikit'
import {Box, Modal, TextField} from '@material-ui/core'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {MaterialCertificate} from '../../../types'
import {getTextBoxHelperText} from '../../LettersOfCertification.utils'

interface PopoverProps {
  materialDescription: string
  isSnippetsLoading: boolean
  isMaterialDescriptionSaveError: boolean
  isMaterialDescriptionSaveSuccess: boolean
  isModalOpen: boolean
  selectedMaterialCertificate: MaterialCertificate | undefined
  isMaterialDescriptionLoading: boolean

  setIsModalOpen(status: boolean): void
  setIsMaterialDescriptionSaveError(status: boolean): void
  setIsMaterialDescriptionSaveSuccess(status: boolean): void
  setMaterialDescription(value: string): void
  handleMaterialDescriptionSave(): void
}

export const Popover: React.FC<PopoverProps> = ({
  materialDescription,
  isSnippetsLoading,
  setIsMaterialDescriptionSaveError,
  isMaterialDescriptionSaveError,
  isMaterialDescriptionSaveSuccess,
  setIsMaterialDescriptionSaveSuccess,
  setIsModalOpen,
  isModalOpen,
  selectedMaterialCertificate,
  setMaterialDescription,
  isMaterialDescriptionLoading,
  handleMaterialDescriptionSave
}) => {
  const {t} = useTranslation()
  const [isConfirmationScreen, setIsConfirmationScreen] = useState<boolean>(false)

  const materialNumber = `${selectedMaterialCertificate?.materialNumber}` || ''
  const plantName = `${selectedMaterialCertificate?.plantName}` || ''

  const handleMaterialDescriptionChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMaterialDescription(evt.target.value)
  }

  return (
    <Modal
      open={isModalOpen}
      onClose={() => {
        setIsMaterialDescriptionSaveError(false)
        setIsMaterialDescriptionSaveSuccess(false)
        setIsModalOpen(false)
        setIsConfirmationScreen(false)
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Box
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '65%',

          backgroundColor: 'white',
          border: '2px solid #99afb7',
          boxShadow: '2px',
          padding: '120px'
        }}
      >
        <Box p={1} />
        {isConfirmationScreen ? (
          <Box display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row" justifyContent="center">
              <Typography variant="h2">{t('certificate.editCertificate.confirmTitle')}</Typography>
            </Box>
            <Box p={2} />
            <Box display="flex" justifyContent="center">
              <TextButton
                color="primary"
                variant="text"
                onClick={() => {
                  setIsConfirmationScreen(false)
                }}
              >
                {t('certificate.editCertificate.cancelSave')}
              </TextButton>
              <Box width="96px" height="1px" />
              <TextButton
                variant="text"
                color="secondary"
                onClick={() => {
                  handleMaterialDescriptionSave()
                  setIsConfirmationScreen(false)
                }}
              >
                {t('certificate.editCertificate.confirmSave')}
              </TextButton>
            </Box>
          </Box>
        ) : (
          <Box>
            <Typography variant="h2">
              {t('certificate.editCertificate.popoverHeadline', {
                materialNumber,
                plantName,
                interpolation: {escapeValue: false}
              })}
            </Typography>
            <Box p={1} />
            <Box display="flex" flexDirection="column">
              <TextField
                multiline
                placeholder="No description yet"
                value={materialDescription}
                onChange={handleMaterialDescriptionChange}
                disabled={isSnippetsLoading}
                error={isMaterialDescriptionSaveError}
                helperText={getTextBoxHelperText(
                  isMaterialDescriptionSaveError,
                  isMaterialDescriptionSaveSuccess,
                  t
                )}
              />
              <Box p={1} />
              <Box display="flex" justifyContent="flex-end">
                <TextButton
                  variant="text"
                  color="secondary"
                  onClick={() => setIsConfirmationScreen(true)}
                  disabled={isMaterialDescriptionLoading || !materialDescription}
                >
                  {t('certificate.editCertificate.saveCertification')}
                </TextButton>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  )
}
