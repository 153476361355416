import {
  OrderIntakeActionTypes,
  RESET_ORDER_INTAKE,
  SET_COLLECTIONS,
  SET_DELIVERIES,
  SET_ORDER_SUMMARY,
  TOGGLE_RESET_ORDER_INTAKE
} from '../../OrderIntake/declarations/Action.types'
import {BulkCementOrderIntakeStateType} from '../../OrderIntake/declarations/types'

export const initialState: BulkCementOrderIntakeStateType = {
  selectedSite: undefined,
  selectedSiteCollect: undefined,
  deliveries: [],
  collections: [],
  orderSummary: [],
  isReset: false
}

export const orderIntake = (
  state: BulkCementOrderIntakeStateType,
  action: OrderIntakeActionTypes
) => {
  switch (action.type) {
    case RESET_ORDER_INTAKE:
      return {
        ...initialState,
        isReset: true
      }

    case TOGGLE_RESET_ORDER_INTAKE:
      return {
        ...state,
        isReset: false
      }

    case SET_DELIVERIES:
      return {
        ...state,
        deliveries: action.payload
      }

    case SET_COLLECTIONS:
      return {
        ...state,
        collections: action.payload
      }

    case SET_ORDER_SUMMARY:
      return {
        ...state,
        orderSummary: [...state.orderSummary, action.payload]
      }

    default:
      return state
  }
}
