import {AuthorizationResponse, DelegoCreditCard, Payment, PaymentResponse} from './Payments.types'

export const PAYMENTS = 'PAYMENTS'
export const PAYMENTS_REQUEST = 'PAYMENTS_REQUEST'
export const PAYMENTS_FAILURE = 'PAYMENTS_FAILURE'
export const PAYMENTS_SUCCESS = 'PAYMENTS_SUCCESS'
export const PAYMENTS_SET_SORT_KEY = 'PAYMENTS_SET_SORT_KEY'

export const ADD_DELEGO_CARD = 'ADD_DELEGO_CARD'
export const REMOVE_DELEGO_CARD = 'REMOVE_DELEGO_CARD'
export const ADD_AUTHORIZATION_RESPONSE = 'ADD_AUTHORIZATION_RESPONSE'
export const ADD_AUTHORIZATION_ERROR = 'ADD_AUTHORIZATION_ERROR'
export const CLEAR_AUTHORIZATION_ERROR = 'CLEAR_AUTHORIZATION_ERROR'
export const ADD_SETTTLEMENT_ERROR = 'ADD_SETTTLEMENT_ERROR'
export const CLEAR_SETTTLEMENT_ERROR = 'CLEAR_SETTTLEMENT_ERROR'
export const ADD_DELEGO_CARD_SUCCESS = 'ADD_DELEGO_CARD_REQUEST_SUCCESS'

export const CREATE_PAYMENT_REQUEST = 'CREATE_PAYMENT_REQUEST'
export const CREATE_PAYMENT_SUCCESS = 'CREATE_PAYMENT_SUCCESS'
export const CREATE_PAYMENT_FAILURE = 'CREATE_PAYMENT_FAILURE'

export const CLEAR_INITIAL_PAYMENT = 'CLEAR_INITIAL_PAYMENT'

interface PaymentRequest {
  type: typeof PAYMENTS_REQUEST
  payerId: string
}
interface PaymentFailure {
  type: typeof PAYMENTS_FAILURE
  payerId: string
  error: any
}
interface PaymentSuccess {
  type: typeof PAYMENTS_SUCCESS
  payerId: string
  payments: Payment[]
}
interface PaymentsSetSortKey {
  type: typeof PAYMENTS_SET_SORT_KEY
  key: string
}

export type PaymentActionsType =
  | PaymentRequest
  | PaymentFailure
  | PaymentSuccess
  | PaymentsSetSortKey

interface AddDelegoCreditCard {
  type: typeof ADD_DELEGO_CARD
  payload: DelegoCreditCard
}

interface RemoveDelegoCreditCard {
  type: typeof REMOVE_DELEGO_CARD
}
interface AddDelegoCreditCardSuccess {
  type: typeof ADD_DELEGO_CARD_SUCCESS
}

interface CreatePaymentRequest {
  type: typeof CREATE_PAYMENT_REQUEST
}
interface CreatePaymentFailure {
  type: typeof CREATE_PAYMENT_FAILURE
  error: string
}

interface CreatePaymentSuccess {
  type: typeof CREATE_PAYMENT_SUCCESS
  payload: CreatePaymentResponse
}
interface AddAuthorizationResponse {
  type: typeof ADD_AUTHORIZATION_RESPONSE
  payload: AuthorizationResponse
}
interface AddAuthorizationError {
  type: typeof ADD_AUTHORIZATION_ERROR
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  payload: Error | string | unknown
}

interface ClearAuthorizationError {
  type: typeof CLEAR_AUTHORIZATION_ERROR
}
export interface AuthorizationRequest {
  amount: {
    value: number
    currency: string
  }
  payment: PaymentResponse
  companyCode: string
  countryCode: string
  payerId: string
  isInternalUser: boolean
  userEmail: string
  userName: string
  feedbackEmail: string
  analytics: {
    analyticsId: string
  }
  locale?: string
  surchargeAmount?: number
  customerReference?: string
  selectedInvoices?: string
}

export interface ClearInitialPayment {
  type: typeof CLEAR_INITIAL_PAYMENT
}

export interface AddSettlementError {
  type: typeof ADD_SETTTLEMENT_ERROR
}

export interface ClearSettlementError {
  type: typeof CLEAR_SETTTLEMENT_ERROR
}

export type PaymentOptionsActionsType =
  | AddDelegoCreditCard
  | RemoveDelegoCreditCard
  | AddDelegoCreditCardSuccess
  | AddAuthorizationResponse
  | AddAuthorizationError
  | ClearAuthorizationError
  | CreatePaymentRequest
  | CreatePaymentSuccess
  | CreatePaymentFailure
  | ClearInitialPayment
  | AddSettlementError
  | ClearSettlementError

export interface PaymentsStatePerPayer {
  [key: string]: NormalizedPaymentsStore
}
export interface NormalizedPaymentsStore {
  [key: string]: Payment
}
export interface CreatePaymentResponse {
  status?: 'created' | 'pending' | 'done' | 'failed'
  id?: string
  error?: string
}

export interface PaymentsStateType {
  isFetching: boolean
  error: any
  byId: PaymentsStatePerPayer
  allIds: string[]
  sortOrder: {key: string; asc: boolean}
  filters: any
  defaultFilters: any
}

export interface PaymentOptionsStateType {
  isFetching: boolean
  error: any
  paymentMethodsAdded?: DelegoCreditCard
  addCardRequested: boolean
  errorCards: boolean
  addCardSuccess: boolean
  authorized: boolean
  authorization?: AuthorizationResponse
  authorizationError?: boolean
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  authorizationErrorType?: Error | string | unknown
  paymentResult?: CreatePaymentResponse
  settlementError?: boolean
}
