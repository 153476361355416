import {AxiosResponse} from 'axios'

import {
  PaymentOptionsActionsType,
  ADD_DELEGO_CARD,
  ADD_DELEGO_CARD_SUCCESS,
  ADD_AUTHORIZATION_RESPONSE,
  CreatePaymentResponse,
  ADD_AUTHORIZATION_ERROR,
  CLEAR_AUTHORIZATION_ERROR,
  ADD_SETTTLEMENT_ERROR,
  CLEAR_SETTTLEMENT_ERROR,
  REMOVE_DELEGO_CARD
} from './Action.types'
import {AuthorizationResponse, DelegoCreditCard} from './Payments.types'

import {
  AuthorizationRequest,
  CLEAR_INITIAL_PAYMENT,
  CREATE_PAYMENT_FAILURE,
  CREATE_PAYMENT_REQUEST,
  CREATE_PAYMENT_SUCCESS,
  DelegoPaymentRequestType
} from '.'

export const addCardToTheStore = (creditCard: DelegoCreditCard): PaymentOptionsActionsType => ({
  type: ADD_DELEGO_CARD,
  payload: creditCard
})

export const removeCardFromTheStore = (): PaymentOptionsActionsType => ({
  type: REMOVE_DELEGO_CARD
})

export const addAuthorizationResponse = (
  authorization: AuthorizationResponse
): PaymentOptionsActionsType => ({
  type: ADD_AUTHORIZATION_RESPONSE,
  payload: authorization
})

export const addAuthorizationError = (
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  error: Error | string | unknown
): PaymentOptionsActionsType => ({
  type: ADD_AUTHORIZATION_ERROR,
  payload: error
})

export const clearAuthorizationError = (): PaymentOptionsActionsType => ({
  type: CLEAR_AUTHORIZATION_ERROR
})
export const removeTempCard = (): PaymentOptionsActionsType => ({
  type: ADD_DELEGO_CARD_SUCCESS
})

export const createPaymentRequest = (): PaymentOptionsActionsType => ({
  type: CREATE_PAYMENT_REQUEST
})

export const addSettlementError = (): PaymentOptionsActionsType => ({
  type: ADD_SETTTLEMENT_ERROR
})

export const clearSettlementError = (): PaymentOptionsActionsType => ({
  type: CLEAR_SETTTLEMENT_ERROR
})

export const createPaymentSuccess = (
  response: CreatePaymentResponse
): PaymentOptionsActionsType => ({
  type: CREATE_PAYMENT_SUCCESS,
  payload: response
})
export const createPaymentFailure = (error: string): PaymentOptionsActionsType => ({
  type: CREATE_PAYMENT_FAILURE,
  error
})

export const createPayment =
  (args: AuthorizationRequest, authorization: AuthorizationResponse) =>
  async (dispatch, getState, {api}) => {
    dispatch(createPaymentRequest())
    try {
      const body: DelegoPaymentRequestType = {
        authorizationDate: authorization.authorizationDate,
        delegoAuthorization: {
          arAmount: authorization.authorizedAmount.value,
          arRequestedAmount: authorization.requestedAmount.value,
          ...authorization.response,
          fpltr: authorization.response.fpltr.toString()
        },
        currency: args.amount.currency,
        companyCode: args.companyCode,
        creditCardInput: {
          countryCode: args.countryCode,
          holderName: authorization.payment.cardholderName,
          token: authorization.payment.token as string,
          cardType: authorization.payment.cardType as string,
          payerId: args.payerId,
          expiryDate: `${authorization.payment.cardExpiration.year}-${authorization.payment.cardExpiration.month}-28`
        },
        isInternalUser: args.isInternalUser,
        userEmail: args.userEmail,
        feedbackEmail: args.feedbackEmail,
        userName: args.userName,
        analytics: args.analytics,
        ...(args.locale ? {locale: args.locale} : {}),
        ...(args.surchargeAmount ? {surchargeAmount: args.surchargeAmount} : {}),
        ...(args.customerReference ? {customerReference: args.customerReference} : {}),
        ...(args.selectedInvoices ? {selectedInvoices: args.selectedInvoices} : {})
      }
      const response: AxiosResponse<CreatePaymentResponse> = await api.post(
        '/paymentrequests',
        body
      )
      dispatch(createPaymentSuccess(response.data))
    } catch (error) {
      dispatch(createPaymentFailure('Payment cannot be created'))
    }
  }

export const clearInitialPayment = (): PaymentOptionsActionsType => ({
  type: CLEAR_INITIAL_PAYMENT
})
