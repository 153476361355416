import {union} from 'lodash'

import {getTestDocumentLink} from '../../Pages/Certificate/Rmc/MaterialTest.utils'
import {PERMISSIONS_FETCH_SUCCESS, PermissionsActionTypes} from '../../Permissions/Action.types'
import {
  CUSTOMERS_FETCH_FAILURE,
  CUSTOMERS_FETCH_REQUEST,
  CUSTOMERS_FETCH_RESPONSE
} from '../Customers'
import {CustomerActionTypes} from '../Customers/Action.types'
import {FilterActionType, createFiltersReducer, initialFiltersState} from '../Filters'

import {
  GroupedByOrderNumber,
  MATERIALTESTS,
  MATERIALTESTS_CLEAR,
  MATERIALTESTS_FETCH_FAILURE,
  MATERIALTESTS_FETCH_REQUEST,
  MATERIALTESTS_FETCH_SUCCESS,
  MATERIALTESTS_LOAD_MORE,
  MATERIALTESTS_SET_SORT_KEY,
  MaterialTestsActionTypes,
  MaterialTestsStateType
} from './Action.types'
import {mapRowToTestType} from './MaterialTest.utils'

const filterReducer = createFiltersReducer(MATERIALTESTS)

export const initialMaterialTestsState: MaterialTestsStateType = {
  byOrderNumber: {},
  allOrderNumbers: [],
  sortOrder: {
    key: 'shippingDate',
    asc: true
  },
  skip: 0,
  limit: 10,
  showLoadMore: false,
  error: null,
  isFetching: false,
  ...initialFiltersState
}

export const materialTests = (
  state = initialMaterialTestsState,
  action: MaterialTestsActionTypes | FilterActionType | CustomerActionTypes | PermissionsActionTypes
) => {
  switch (action.type) {
    case PERMISSIONS_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: true
      }

    case CUSTOMERS_FETCH_REQUEST:
      return {
        ...state,
        isFetching: true
      }

    case CUSTOMERS_FETCH_RESPONSE:
      return {
        ...state,
        isFetching: false
      }

    case CUSTOMERS_FETCH_FAILURE:
      return {
        ...state,
        isFetching: false
      }

    case MATERIALTESTS_CLEAR:
      return {
        ...initialMaterialTestsState,
        sortOrder: {
          ...state.sortOrder
        }
      }

    case MATERIALTESTS_SET_SORT_KEY: {
      const asc = !(state.sortOrder.key === action.payload && state.sortOrder.asc)
      return {
        ...state,
        skip: 0,
        error: null,
        sortOrder: {
          key: action.payload,
          asc
        }
      }
    }

    case MATERIALTESTS_FETCH_REQUEST:
      return {
        ...state,
        error: null,
        isFetching: true
      }

    case MATERIALTESTS_FETCH_SUCCESS: {
      const collectionIds = action.payload.map((item) => item.orderNumber)

      return {
        ...state,
        error: null,
        showLoadMore: action.showLoadMore,
        isFetching: false,
        byOrderNumber: {
          ...state.byOrderNumber,
          ...action.payload.reduce<GroupedByOrderNumber>((agg, curr) => {
            if (agg[curr.orderNumber]) {
              const currAvailable =
                agg[curr.orderNumber].available || getTestDocumentLink(curr) !== undefined

              agg[curr.orderNumber] = {
                ...agg[curr.orderNumber],
                available: currAvailable,
                items: [...agg[curr.orderNumber].items, ...mapRowToTestType(curr)]
              }
            }

            if (!agg[curr.orderNumber]) {
              agg[curr.orderNumber] = {
                shippingDate: curr.shippingDate,
                shippingAddress: curr.shippingAddress,
                contactInformation: curr.contactInformation,
                orderNumber: curr.orderNumber,
                available: !!getTestDocumentLink(curr),
                items: mapRowToTestType(curr)
              }
            }
            return agg
          }, {})
        },
        allOrderNumbers: union(state.allOrderNumbers, collectionIds)
      }
    }

    case MATERIALTESTS_FETCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        paginationFetching: false,
        error: action.payload
      }

    case MATERIALTESTS_LOAD_MORE:
      return {
        ...state,
        paginationEnabled: false,
        skip: state.skip + state.limit
      }

    default:
      if ('context' in action) {
        return {
          ...state,
          ...filterReducer(state, action),
          skip: 0
        }
      }
      return state
  }
}
