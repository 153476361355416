import {AppState} from '../../Root.store'

import {RESULTS_PER_PAGE} from './Action.types'

export const selectSelectedResultPerPages = (state: AppState) => {
  const localStorageDefaultResultsPerPageString: string | null =
    localStorage.getItem(RESULTS_PER_PAGE)
  return localStorageDefaultResultsPerPageString
    ? JSON.parse(localStorageDefaultResultsPerPageString)
    : state.order.orders.limit
}
