import {Typography} from '@hconnect/uikit'
import React from 'react'

interface PlantSelectItemType {
  plantName: string
}
export const PlantSelectItem: React.FC<PlantSelectItemType> = ({plantName}) => (
  <Typography component="div" variant="body1" color={'textPrimary'}>
    {plantName}
  </Typography>
)
