import {find} from 'lodash'

import {AppState} from '../../Root.store'

import {CustomerStateType} from './Action.types'
import {Customer} from './Customers.types'

export const selectCustomers = (state: AppState): CustomerStateType => state.customers

export const selectSelectedCustomerFilter = (state: AppState): Customer | null =>
  selectCustomers(state).selectedCustomer

export const selectIsCustomerFetching = (state: AppState) => selectCustomers(state).isFetching

export const selectSelectedCustomer = (state: AppState, customerId) =>
  find(selectCustomers(state).customers, (customer) => customer.customerId === customerId)

export const selectSearchIsChoosen = (state: AppState): boolean =>
  selectCustomers(state).customerChoosen
