import {PrimaryCell, SecondaryCell, dateFormatter, isoDateFormatter} from '@hconnect/uikit'
import {useTranslation} from 'react-i18next'

import {ReactComponent as RightArrow} from '../../../Assets/right-arrow.svg'

import {SubscriptionStatus} from './Components/SubscriptionStatus'
import {useCementStyles} from './styles'
import {MaterialCertificate} from './types'

interface CertificateColumnType {
  navigateToGenerateCertificatePage?: any
  showLoc: boolean
  startDate: string | Date
  endDate: string | Date
}
const CertificateColumns = ({showLoc}: CertificateColumnType) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const classesCement = useCementStyles()

  const columnsPool = {
    certificationDate: {
      field: 'certificationDate',
      headerName: t('certificate.columns.certificationDate'),
      sortable: true,
      flex: 2,
      renderCell: (item: MaterialCertificate) => (
        <PrimaryCell>
          {item.materialCertifications?.length
            ? dateFormatter(
                isoDateFormatter(item.materialCertifications[0].certificationDate),
                language
              )
            : '-'}
        </PrimaryCell>
      )
    },
    plantName: {
      field: 'plantName',
      headerName: t('certificate.columns.plant'),
      sortable: true,
      flex: 3,
      renderCell: (item: MaterialCertificate) => <SecondaryCell>{item.plantName}</SecondaryCell>
    },
    materialName: {
      field: 'materialName',
      headerName: t('certificate.columns.product'),
      sortable: true,
      flex: 3,
      renderCell: (item: MaterialCertificate) => (
        <SecondaryCell>{item.materialDescription}</SecondaryCell>
      )
    },
    subscriptionStatus: {
      field: 'subscriptionStatus',
      headerName: t('certificate.columns.subscriptionStatus'),
      sortable: false,
      flex: 1,
      renderCell: (item: MaterialCertificate) => (
        <PrimaryCell>
          <SubscriptionStatus isSubscribed={item.isSubscribed} t={t} />
        </PrimaryCell>
      )
    },
    certificationLetter: {
      field: 'certificationLetter',
      headerName: t('certificate.columns.certificationLetterHeader'),
      sortable: false,
      flex: 2,
      renderCell: () => (
        <PrimaryCell className={classesCement.downloadRowItem}>
          {t('certificate.columns.certificationLetterButton')}
          <RightArrow />
        </PrimaryCell>
      )
    }
  }
  return [
    columnsPool.certificationDate,
    columnsPool.plantName,
    columnsPool.materialName,
    columnsPool.subscriptionStatus,
    ...(showLoc ? [columnsPool.certificationLetter] : [])
  ]
}

export default CertificateColumns
