import {
  Invoice,
  LineItemType,
  ServicesOrSurcharge,
  ServicesOrSurcharges
} from '../../Pages/Finance/Invoices/Invoice.types'

export const getServicesOrSurcharges = (item: Invoice): ServicesOrSurcharges => {
  const {materialItems, surchargeItems, serviceItems} = item
  if (materialItems && serviceItems && surchargeItems) {
    if (materialItems.length > 0) {
      if (serviceItems.length > 0 || surchargeItems.length > 0) {
        return ServicesOrSurcharge.MaterialAndServicesOrSurcharges
      }
      return ServicesOrSurcharge.MaterialOnly
    }
    if (materialItems.length === 0) {
      if (serviceItems.length > 0 || surchargeItems.length > 0) {
        return ServicesOrSurcharge.ServicesOrSurchargesOnly
      }
    }
  }
  return ServicesOrSurcharge.MaterialOnly
}

type MergedBreakdownItems = Pick<Invoice, 'materialItems' | 'surchargeItems' | 'serviceItems'>

export const mergeLineItemsInfoIntoBreakdown = (item: Invoice): MergedBreakdownItems => {
  const {materialItems, serviceItems, surchargeItems, lineItems} = item
  return lineItems.reduce<MergedBreakdownItems>(
    (agg, curr) => {
      const {itemNumber} = curr
      if (
        curr.lineItemTypes.includes(LineItemType.material) ||
        curr.lineItemTypes.includes(LineItemType.default)
      ) {
        const materialItem = materialItems?.find(
          (material) => material.relatedLineItem === itemNumber
        )
        // eslint-disable-next-line
        materialItem && agg.materialItems?.push({...curr, ...materialItem})
      }
      if (
        curr.lineItemTypes.includes(LineItemType.service) ||
        curr.lineItemTypes.includes(LineItemType.serviceOrSurcharge)
      ) {
        const relatedServiceItems = serviceItems
          ?.filter((service) => service.relatedLineItem === itemNumber)
          .map((service) => ({
            ...curr,
            ...service,
            links: [...curr.links, ...(service.links || [])]
          }))
        agg.serviceItems = [...agg.serviceItems, ...relatedServiceItems]
      }
      if (
        curr.lineItemTypes.includes(LineItemType.surcharge) ||
        curr.lineItemTypes.includes(LineItemType.serviceOrSurcharge)
      ) {
        const relatedSurchargeItems = surchargeItems
          ?.filter((surcharge) => surcharge.relatedLineItem === itemNumber)
          .map((surcharge) => ({
            ...curr,
            ...surcharge,
            links: [...(surcharge.links || [])]
          }))
        agg.surchargeItems = [...agg.surchargeItems, ...relatedSurchargeItems]
      }
      return agg
    },
    {materialItems: [], serviceItems: [], surchargeItems: []}
  )
}
