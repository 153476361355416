import {Customer} from '@hconnect/apiclient/src/response/Customer'
import {useQuery} from 'react-query'

import {getRequestPathData} from './utils'

export interface Payer {
  countryId: string
  customers: Customer[]
  payerId: string
  payerName: string
  payerNumber: string
}

const fetchPayerDetails = ({queryKey}) => {
  const [endpoint, {payerNumber, payerId}] = queryKey

  const queryParams = new URLSearchParams({})

  if (payerNumber) {
    queryParams.append('payerNumber', payerNumber)
  }

  if (payerId) {
    queryParams.append('payerId', payerId)
  }

  return getRequestPathData<Payer[]>(`/${endpoint}?${queryParams.toString()}`)
}

const fetchPayerById = ({queryKey}) => {
  const [endpoint, {payerId}] = queryKey
  return getRequestPathData<Payer>(`/${endpoint}/${payerId}`)
}

const fetchPayers = ({queryKey}) => {
  const [endpoint, {businessLine, countryId, orgUnitId}] = queryKey

  if (businessLine && countryId && orgUnitId) {
    const queryParams = new URLSearchParams({})
    queryParams.append('countryId', countryId)
    queryParams.append('orgUnitId', orgUnitId)
    queryParams.append('businessLine', businessLine)

    return getRequestPathData<Payer[]>(`/${endpoint}?${queryParams.toString()}`)
  }

  return getRequestPathData<Payer[]>(`/${endpoint}`)
}

export const usePayerByNumber = (payerNumber?: string, enabled?: boolean) => {
  return useQuery(['payers', {payerNumber}], fetchPayerDetails, {
    retry: false,
    enabled
  })
}

export const usePayerByNumberLogic = (payerNumber?: string, enabled?: boolean) => {
  const payerQueryInfo = usePayerByNumber(payerNumber, enabled)

  const selectedPayer = payerQueryInfo.data?.[0]
  const payerFound = !!payerQueryInfo.data?.length
  const payerNotFound = payerQueryInfo.data?.length === 0

  return {
    isPayerByNumberLoading: payerQueryInfo.isLoading,
    selectedPayer,
    payerFound,
    payerNotFound
  }
}

export const usePayerById = (payerId?: string) => {
  return useQuery(['payers', {payerId}], fetchPayerById, {
    retry: false,
    enabled: !!payerId
  })
}

export const usePayers = (
  enabled?: boolean,
  businessLine?: string,
  countryId?: string,
  orgUnitId?: string
) => {
  return useQuery(['payers', {businessLine, countryId, orgUnitId}], fetchPayers, {
    enabled
  })
}

export const usePayersLogic = (
  payerIdsFromRole: string[],
  hasCustomerOrPayerAssignments: boolean,
  salesAgentDataScopes?: {businessLine: string; countryId: string; orgUnitId: string},
  enabled?: boolean
) => {
  const payersQueryInfo = usePayers(
    enabled,
    salesAgentDataScopes?.businessLine,
    salesAgentDataScopes?.countryId,
    salesAgentDataScopes?.orgUnitId
  )

  const filteredPayers = !hasCustomerOrPayerAssignments
    ? payersQueryInfo.data
    : payersQueryInfo.data?.filter((payer) => payerIdsFromRole.includes(payer.payerId))
  const payerItems = filteredPayers?.map((payer) => ({
    value: payer.payerNumber ?? 'no-payer-number',
    label: payer.payerName
  }))

  return {
    isPayerLoading: payersQueryInfo.isLoading,
    isPayerError: payersQueryInfo.isError,
    payerItems
  }
}
