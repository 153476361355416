import {AxiosResponse} from 'axios'

import {errorKeyFromRequestError} from '../../ErrorMap'
import {Delivery} from '../../Pages/Finance/Invoices/Delivery.types'
import {errorsAdd} from '../Errors'

import {
  DELIVERIES_INVOICES_FETCH_FAILURE,
  DELIVERIES_INVOICES_FETCH_REQUEST,
  DELIVERIES_INVOICES_FETCH_SUCCESS,
  DeliveriesInvoiceActionTypes
} from './Action.types'

// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
export const fetchDeliveriesRequest = (params: string | any): DeliveriesInvoiceActionTypes => {
  const action: DeliveriesInvoiceActionTypes = {
    type: DELIVERIES_INVOICES_FETCH_REQUEST
  }
  if (typeof params === 'string') {
    action.invoiceId = params
    return action
  }
  // This is the case when we are fetching delivery in lookup
  action.customerId = params.customerId
  if ('deliveryNumber' in params) {
    action.deliveryNumber = params.deliveryNumber
  }
  if ('customerDeliveryNumber' in params) {
    action.customerDeliveryNumber = params.customerDeliveryNumber
  }
  return action
}

export const fetchDeliveriesFailure = (
  invoiceId: string,
  err: any
): DeliveriesInvoiceActionTypes => ({
  type: DELIVERIES_INVOICES_FETCH_FAILURE,
  payload: err,
  error: true,
  invoiceId
})

export const fetchDeliveriesSuccess = (
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  params: string | any,
  deliveries: Delivery[]
): DeliveriesInvoiceActionTypes => {
  const action: DeliveriesInvoiceActionTypes = {
    type: DELIVERIES_INVOICES_FETCH_SUCCESS,
    payload: deliveries
  }
  if (typeof params === 'string') {
    action.invoiceId = params
    return action
  }
  if ('deliveryNumber' in params) {
    action.deliveryNumber = params.deliveryNumber
    action.customerId = params.customerId
  }
  if ('customerDeliveryNumber' in params) {
    action.customerDeliveryNumber = params.customerDeliveryNumber
    action.customerId = params.customerId
  }
  return action
}

export const fetchDelivery =
  (params: any, isPumpServiceEnabled: boolean, trackingEvent: () => void) =>
  async (dispatch, getState, {api}) => {
    if (isPumpServiceEnabled) {
      params.includePumpService = true
    }
    dispatch(fetchDeliveriesRequest(params))

    try {
      const response: AxiosResponse<Delivery[]> = await api.get('/deliveries', {params})
      await dispatch(fetchDeliveriesSuccess(params, response.data))
    } catch (err) {
      trackingEvent()
      const errorKey = errorKeyFromRequestError(err)
      dispatch(
        errorsAdd({
          key: 'invoice',
          translationKey: `errorMessages.${errorKey}`,
          callback: () => dispatch(fetchDelivery(params, isPumpServiceEnabled, trackingEvent)),
          callbackTranslationKey: 'error.action.retry'
        })
      )
      dispatch(fetchDeliveriesFailure('', err))
    }
  }
export const fetchDeliveryByCustomerDeliveryNumber =
  (
    customerDeliveryNumber: string,
    customerId: string,
    isPumpServiceEnabled: boolean,
    trackingEvent: () => void
  ) =>
  async (dispatch) => {
    await dispatch(
      fetchDelivery(
        {
          customerDeliveryNumber,
          customerId
        },
        isPumpServiceEnabled,
        trackingEvent
      )
    )
  }

export const fetchDeliveryByDeliveryNumber =
  (
    deliveryNumber: string,
    customerId: string,
    isPumpServiceEnabled: boolean,
    trackingEvent: () => void
  ) =>
  async (dispatch) => {
    await dispatch(
      fetchDelivery(
        {
          deliveryNumber,
          customerId
        },
        isPumpServiceEnabled,
        trackingEvent
      )
    )
  }
