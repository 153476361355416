import {Project} from '@hconnect/apiclient'
import {useQuery} from 'react-query'

import {api} from '../Api/Api'

const fetchProjects = async ({queryKey}) => {
  const [endpoint, {customerId}] = queryKey

  const queryParams = new URLSearchParams({customerId})

  const roleRequests = await api.get<Project[]>(`/${endpoint}?${queryParams.toString()}`)

  return roleRequests?.data
}

export const useProjects = (customerId: string | undefined, projectsIds: string[], enabled) => {
  const projectsQueryInfo = useQuery(['projects', {customerId}], fetchProjects, {
    enabled
  })

  const projects = projectsQueryInfo.data ?? []

  const selectedProjects = projectsIds.map((projectId) =>
    projects.find((project) => project.projectId === projectId)
  )

  return {
    isProjectsLoading: projectsQueryInfo.isLoading,
    projects,
    selectedProjects
  }
}
