import {Country, Locale} from './Countries.types'

export const COUNTRIES_GET_COUNTRY_REQUEST = 'COUNTRIES/GET_COUNTRY_REQUEST'
export const COUNTRIES_GET_COUNTRY_SUCCESS = 'COUNTRIES/GET_COUNTRY_SUCCESS'
export const COUNTRIES_GET_COUNTRY_FAILURE = 'COUNTRIES/GET_COUNTRY_FAILURE'
export const COUNTRIES_SET_DEFAULT_LOCALE = 'COUNTRIES/SET_DEFAULT_LOCALE'

interface GetCountryRequest {
  type: typeof COUNTRIES_GET_COUNTRY_REQUEST
}

interface GetCountrySuccess {
  type: typeof COUNTRIES_GET_COUNTRY_SUCCESS
  payload: Country
}

interface GetCountryFailure {
  type: typeof COUNTRIES_GET_COUNTRY_FAILURE
  error: any
}

interface SetDefaultLocale {
  type: typeof COUNTRIES_SET_DEFAULT_LOCALE
  payload: Locale
}

export type CountriesActionType =
  | GetCountryRequest
  | GetCountrySuccess
  | GetCountryFailure
  | SetDefaultLocale
