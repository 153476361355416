import {User} from '@hconnect/apiclient/src'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {Typography, isoUtcDateFormatter} from '@hconnect/uikit'
import {Box, Button} from '@material-ui/core'
import NotificationAddOutlinedIcon from '@mui/icons-material/NotificationAddOutlined'
import NotificationsOffOutlinedIcon from '@mui/icons-material/NotificationsOffOutlined'
import first from 'lodash/first'
import queryString from 'query-string'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector, useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {v4 as uuidV4} from 'uuid'

import {ReactComponent as DownloadIcon} from '../../../Assets/download.svg'
import {ReactComponent as PlusIcon} from '../../../Assets/plus.svg'
import {selectSelectedCustomer} from '../../../Organisms/Customers'
import {Customer} from '../../../Organisms/Customers/Customers.types'
import {
  Entity,
  EntityPage,
  getDocumentFileName,
  downloadDocument as downloadDocumentAbstract
} from '../../../Organisms/Documents'
import {AppState} from '../../../Root.store'
import {LinkMedia, LinkMediaType} from '../../Finance/Invoices/Delivery.types'

import {useMaterialCertificatesSubscriptions} from './hooks/useMaterialCertificatesSubscriptions'
import {GenerateCertificateRoute} from './LettersOfCertification/GenerateCertificate'
import {useCementStyles} from './styles'
import {MaterialCertificate} from './types'
import {BusinessLineType} from '../../../common/types'

interface CertificateGenerateButtonsProp {
  certificateDetails: MaterialCertificate
  startDate: string | Date
  endDate: string | Date
}

export const downloadPdfButtonClicked = () => {}

export const CertificateGenerateButtonsAside: React.FunctionComponent<
  CertificateGenerateButtonsProp
> = ({certificateDetails, startDate, endDate}) => {
  const {t} = useTranslation()
  const classesCement = useCementStyles()
  const user = useSelector<AppState, User | null>((state) => state.userProfile.userProfile)
  const history = useHistory()
  const {customerId} = queryString.parse(window.location.search)
  const customer = useSelector<AppState, Customer | undefined>((state: AppState) =>
    selectSelectedCustomer(state, customerId)
  )
  const dispatch = useDispatch()

  const downloadDocument = (jobId: string, documentId: string, fileName: string) =>
    dispatch(downloadDocumentAbstract(jobId, documentId, fileName))
  const firstCertification: any = first(certificateDetails.materialCertifications)

  const {links, certificationDate} = firstCertification || {}
  const certificatePdf = links && links.length ? links[0] : undefined
  const documentId: string = certificatePdf ? certificatePdf.href.split('/')[1] : undefined
  const {plantName, materialDescription, plantId} = certificateDetails
  const date = isoUtcDateFormatter(certificationDate as string)
  const fileName = certificatePdf
    ? getDocumentFileName(
        EntityPage.testsCertificates,
        Entity.millTestReports,
        certificatePdf?.media as LinkMediaType,
        t,
        {plantName, materialDescription, date}
      )
    : `${Entity.millTestReports}.${LinkMedia.Pdf}`

  const pdfBtnClickHandler = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    const jobId: string = uuidV4()
    trackEvent('hubDownloadSingle', {
      product: 'hub',
      jobId: jobId,
      downloadedItem: 'certificate',
      linkToDownloadedItem: certificatePdf.href,
      customerId: customerId,
      customerName: customer?.customerName,
      entryPoint: 'Certificate List',
      filteredDateRange: {startDate: startDate, endDate: endDate},
      issuedDate: certificationDate,
      plant: plantId,
      material: materialDescription,
      userId: user?.user_id,
      userMail: user?.eMail,
      userCountry: user?.country,
      userRoles: user?.hasRoles,
      userIsInternal: user?.isInternal,
      downloadedItemBusinessLine: BusinessLineType.CEM
    })
    documentId && downloadDocument(jobId, documentId, fileName)
  }

  return (
    <div className={classesCement.buttonContainer}>
      {certificatePdf && (
        <Button
          data-test-id="download-certificate-button"
          variant="contained"
          color="primary"
          startIcon={<DownloadIcon />}
          className={classesCement.downloadCertificateButton}
          onClick={pdfBtnClickHandler}
        >
          {t('certificate.downloadCertificate')}
        </Button>
      )}

      <Button
        data-test-id="generate-certificate-button"
        variant="contained"
        startIcon={<PlusIcon />}
        className={classesCement.generateCertificateButton}
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          event.stopPropagation()
          history.push(
            `${GenerateCertificateRoute}/?plantId=${certificateDetails?.plantId}&materialNumber=${certificateDetails?.materialNumber}`
          )
        }}
      >
        {t('certificate.generateTheCertificate')}
      </Button>
    </div>
  )
}

interface CertificateSubscribeButtonProps {
  certificate: MaterialCertificate
  setCertificate: (certificate?: MaterialCertificate) => void
}
export const CertificateSubscribeButton: React.FunctionComponent<
  CertificateSubscribeButtonProps
> = ({certificate, setCertificate}) => {
  const {t} = useTranslation()
  const classesCement = useCementStyles()

  const {mutate, isLoading, isError, reset} = useMaterialCertificatesSubscriptions()
  const {isSubscribed, plantIdUnique} = certificate

  useEffect(() => reset(), [plantIdUnique, reset])

  const changeSubscription = () => {
    const subscribe = !certificate.isSubscribed
    trackEvent('hubMaterialsCertificatesSubscription', {
      product: 'hub',
      plant: certificate.plantId,
      materialDescription: certificate.materialDescription,
      action: subscribe ? 'subscribe' : 'unsubscribe'
    })

    mutate(
      {certificate, subscribe},
      {
        onSuccess: () => setCertificate({...certificate, isSubscribed: subscribe})
      }
    )
  }

  return (
    <Box className={classesCement.buttonContainer}>
      <Typography>
        {isSubscribed ? t('certificate.unsubscribeMessage') : t('certificate.subscribeMessage')}
      </Typography>
      <Button
        data-test-id="certificates-subscribe-button"
        variant="contained"
        startIcon={
          isSubscribed ? <NotificationsOffOutlinedIcon /> : <NotificationAddOutlinedIcon />
        }
        className={classesCement.generateCertificateButton}
        disabled={isLoading}
        onClick={changeSubscription}
      >
        {isSubscribed ? t('certificate.unsubscribe') : t('certificate.subscribe')}
      </Button>
      {isError && (
        <Box mt={2}>
          <Typography color="error">{t('errorboundary.error')}</Typography>
        </Box>
      )}
    </Box>
  )
}
