import {trackEvent} from '@hconnect/common/logging/Analytics'

import {api} from '../../App.global'
import {store} from '../../Root.store'
import './DelegoCustomStyle.css'
import {
  addAuthorizationError,
  addAuthorizationResponse,
  addCardToTheStore,
  AuthorizationRequest,
  AuthorizationResponse,
  createPayment
} from '../Payments'

import {AxiosResponse} from 'axios'

import {
  PaymentAnalyticsEvents,
  PaymentMethods,
  PaymentProviders
} from '../../Pages/Finance/PayNow/common/constants'

// eslint-disable-next-line no-var
var rapidPay = null

const delegoUrlPerCountry = {
  'US-QA': 'https://lehigh-qrk-web.delego-cloud.com/DelegoRP',
  'US-UAT': 'https://lehigh-qrl-web.delego-cloud.com/DelegoRP',
  US: 'https://lehigh-prs-web.delego-cloud.com/DelegoRP',
  'AU-QA': 'https://hanson-acq-rp.delego-cloud.com/DelegoRP',
  AU: 'https://hanson-acp-rp.delego-cloud.com/DelegoRP'
}

export const getDelegoUrl = (country: string) => {
  let key = country
  if (process.env.REACT_APP_STAGE !== 'prod') {
    if (country !== 'AU') {
      key = process.env.REACT_APP_STAGE === 'uat' ? `${country}-UAT` : `${country}-QA`
    } else {
      key = `${country}-QA`
    }
  }
  return delegoUrlPerCountry[key]
}

function signDelegoToken(authorizationToken, total, currencyCode, country: string, sorg?: string) {
  return (window as any).DelegoRapidPay.open({
    url: getDelegoUrl(country),
    token: authorizationToken,
    sorg,
    element: '#emptydelegohost',
    paymentRequest: {
      currencyCode,
      total: total || 100
    },
    events: {
      onApplicationInitialized: function () {},
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onTokenizeSuccess: function (tokenizeResponse, signedTokenizeResponse) {},
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onFailure: function (error) {}
    }
  })
}

export function openRapidPay(
  authorizationToken: string,
  country: string,
  analytics: any,
  sorg?: string
) {
  return (window as any).DelegoRapidPay.open({
    url: getDelegoUrl(country),
    token: authorizationToken,
    sorg,
    paymentRequest: {
      currencyCode: 'USD',
      total: 100
      /*
       * Payment request object
       */
    },
    element: '#delegoiframehost',
    iframeclass: 'fit-to-parent',
    events: {
      onApplicationInitialized: function () {
        /*
         * Actions to take once RapidPay is displayed
         */
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onTokenizeSuccess: function (tokenizeResponse, signedTokenizeResponse) {
        const {
          token,
          cardType,
          cardExpiration: {year, month},
          cardholderName: holderName
        } = tokenizeResponse.payment
        trackEvent(PaymentAnalyticsEvents.HUB_PAYMENT_TOKENIZE_CREATED, analytics)

        store.dispatch(
          addCardToTheStore({
            token,
            cardType,
            holderName,
            isDefault: true,
            expiryDate: `${year}-${month}`
          })
        )
        window.parent.postMessage('iFrameClose', window.location.origin)
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onFailure: function (error) {
        /*
         * Actions to take if RapidPay fails to tokenize a payment card (typically a system error)
         */
        trackEvent(PaymentAnalyticsEvents.HUB_PAYMENT_TOKENIZE_FAILED, analytics)
      }
    }
    /*
     * Additional optional parameters, as desired.
     */
  })
}

export function delegoAuthorize(args: AuthorizationRequest) {
  ;(rapidPay as any)
    .authorize({
      amount: args.amount,
      payment: args.payment,
      useVerifiableResponse: false
    })
    .then((authorization: AuthorizationResponse) => {
      if (authorization.authorized) {
        trackEvent(PaymentAnalyticsEvents.HUB_PAYMENT_AUTHORIZE_SUCCESS, args.analytics)
        store.dispatch(addAuthorizationResponse(authorization))
        store.dispatch(createPayment(args, authorization))
      } else {
        trackEvent(PaymentAnalyticsEvents.HUB_PAYMENT_AUTHORIZE_FAIL, {
          product: 'hub',
          payerId: args.payerId,
          amount: args.amount.value.toString(),
          currency: args.amount.currency,
          countryCode: args.countryCode,
          analyticsId: args.analytics.analyticsId,
          responseCode: authorization.responseCode,
          responseDescription: authorization.responseDescription,
          paymentMethod: PaymentMethods.DELEGO,
          paymentProvider: PaymentProviders.DELEGO
        })
        store.dispatch(addAuthorizationError(authorization.responseCode))
      }
    })
    .catch((err: unknown) => {
      store.dispatch(addAuthorizationError(err))
    })
}

export async function delegoCheckout({
  noDelegoUi,
  amount,
  currencyCode,
  country,
  analytics,
  sorg
}: {
  noDelegoUi?: boolean
  amount?: number
  currencyCode?: string
  country?: string
  analytics: any
  sorg?: string
}) {
  if (!country) {
    return
  }
  const delegoSignedToken = await getDelegoJwt(country)
  return (rapidPay = noDelegoUi
    ? signDelegoToken(delegoSignedToken, amount, currencyCode, country, sorg)
    : openRapidPay(delegoSignedToken, country, analytics, sorg))
}

const getDelegoJwt = async (country?: string) => {
  try {
    const response: AxiosResponse<string> = await api.post(
      '/paymentRequests/delegoAuthorization/token',
      {country}
    )
    return response.data
  } catch (e) {
    console.error(e)
    return ''
  }
}
