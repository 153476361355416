import React from 'react'

import AccountBalanceSvg from '../../../../Assets/cashBalanceIcons/account_balance.svg'
import NotInvoicedSvg from '../../../../Assets/cashBalanceIcons/invoice-pending.svg'
import InvoicePendingSvg from '../../../../Assets/cashBalanceIcons/request_quote.svg'
import {CashInfoItemTypes} from '../CashBalance.enum'

export const CashBalanceIcons: React.FC<{variant: CashInfoItemTypes}> = ({variant}) => {
  switch (variant) {
    case 'unpaid':
      return <img src={InvoicePendingSvg} height="18px" width="18px" alt="" />
    case 'notYetInvoiced':
      return <img src={NotInvoicedSvg} height="18px" width="18px" alt="" />
    case 'creditLeft':
      return <img src={AccountBalanceSvg} height="18px" width="18px" alt="" />
    default:
      return null
  }
}
