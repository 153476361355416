import {Comment} from './Feedback.types'

export const FEEDBACK_FETCH_REQUEST = 'FEEDBACK_FETCH_REQUEST'
export const FEEDBACK_FETCH_SUCCESS = 'FEEDBACK_FETCH_SUCCESS'
export const FEEDBACK_FETCH_FAILURE = 'FEEDBACK_FETCH_FAILURE'

export interface CommentsType {
  // key should be deliveryId
  [key: string]: {
    additionalComments: Comment[]
    isFetching: boolean
    error: any
  }
}

export interface FeedbackStateType {
  comments: CommentsType
}

interface FeedbackFetchRequestType {
  type: typeof FEEDBACK_FETCH_REQUEST
  deliveryId: string
}

interface FeedbackFetchSuccessType {
  type: typeof FEEDBACK_FETCH_SUCCESS
  deliveryId: string
  payload: Comment[]
}

interface FeedbackFetchFailureType {
  type: typeof FEEDBACK_FETCH_FAILURE
  deliveryId: string
  error: any
}

export type FeedbackActionTypes =
  | FeedbackFetchFailureType
  | FeedbackFetchRequestType
  | FeedbackFetchSuccessType
