import {
  DOCUMENT_DOWNLOAD_FAILURE,
  DOCUMENT_DOWNLOAD_REQUEST,
  DOCUMENT_DOWNLOAD_SUCCESS,
  DocumentActionTypes,
  DocumentStateType
} from './Action.types'

export const initialDocumentsState: DocumentStateType = {
  document: null,
  loading: false
}

export const documents = (state = initialDocumentsState, action: DocumentActionTypes) => {
  switch (action.type) {
    case DOCUMENT_DOWNLOAD_REQUEST:
      return {
        ...state,
        loading: true
      }

    case DOCUMENT_DOWNLOAD_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case DOCUMENT_DOWNLOAD_FAILURE:
      return {
        ...state,
        loading: false
      }

    default:
      return state
  }
}
