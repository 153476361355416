import {
  FEEDBACK_FETCH_FAILURE,
  FEEDBACK_FETCH_REQUEST,
  FEEDBACK_FETCH_SUCCESS,
  FeedbackActionTypes,
  FeedbackStateType
} from './Action.types'

export const initialFeedbackState: FeedbackStateType = {
  comments: {}
}

export const feedbackReducer = (state = initialFeedbackState, action: FeedbackActionTypes) => {
  switch (action.type) {
    case FEEDBACK_FETCH_REQUEST:
      return {
        ...state,
        comments: {
          ...state.comments,
          [action.deliveryId]: {
            isFetching: true,
            error: null,
            additionalComments: []
          }
        }
      }

    case FEEDBACK_FETCH_FAILURE:
      return {
        ...state,
        comments: {
          ...state.comments,
          [action.deliveryId]: {
            isFetching: false,
            error: action.error,
            additionalComments: []
          }
        }
      }

    case FEEDBACK_FETCH_SUCCESS:
      return {
        ...state,
        comments: {
          ...state.comments,
          [action.deliveryId]: {
            isFetching: false,
            error: null,
            additionalComments: action.payload
          }
        }
      }

    default:
      return state
  }
}
