import {dateFormatter, timeFormatter} from '@hconnect/uikit'
import {uniqBy, groupBy, transform, uniq, trim, isEmpty, sortBy, first, intersection} from 'lodash'
import moment, {Moment} from 'moment-timezone'
import {v4 as uuidv4} from 'uuid'

import {Dictionary} from '../../common/types'
import {useOrderIntakeStyles} from '../../Hooks/OrderIntake/useOrderIntakeStyles'
import {onlyFullSlots} from '../../OrderIntake/components/TimeScroller/TimeScroller.utils'
import {BUSINESS_HOURS_MIN_INTERVAL_DEFAULT} from '../../OrderIntake/declarations/constants'
import {MaterialFormStatus} from '../../OrderIntake/declarations/OrderIntake.enums'
import {
  DateRange,
  OrderIntakeOptions,
  OrderIntakeMaterialOptionPayload,
  GroupedMaterialDeliveries,
  OrderRequest,
  MaterialDeliveryDescription,
  MaterialDelivery,
  OrderIntakeOption,
  ConfigurableTimeSlot,
  SlotConfiguration,
  BusinessHours,
  BusinessDay,
  OrderIntakePayload,
  GroupedPlacedDeliveries,
  OrderRequestGroupResponse,
  OrderCancellationBusinessDay
} from '../../OrderIntake/declarations/types'
import {isTypeof} from '@microsoft/applicationinsights-core-js'

export const getDates = (startDate: Date, endDate: Date) => {
  const dateArray: Date[] = []
  let currentDate: Date | number = startDate
  while (currentDate <= endDate) {
    dateArray.push(new Date(currentDate))
    currentDate = new Date(currentDate).setDate(new Date(currentDate).getDate() + 1)
  }
  return dateArray
}

export const mapDeliverPayloadToTree = (
  payload: OrderIntakeMaterialOptionPayload[]
): OrderIntakeOptions => {
  const shipToGroups = groupBy(
    payload,
    (destinationOption: OrderIntakeMaterialOptionPayload) =>
      destinationOption.shippingAddress.siteNumber
  )
  return transform(shipToGroups, (result: OrderIntakeOptions, optionsForDestination) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {shippingAddress, contact, plant} = optionsForDestination[0]
    const materials = groupBy(
      optionsForDestination,
      (option) => option.material.materialEnteredNumber
    )
    const canDisableEmailSending = optionsForDestination[0].canDisableEmailSending
    result[shippingAddress.siteNumber] = {
      shippingAddress,
      contact,
      materials,
      canDisableEmailSending
    }
  })
}

export const mapCollectPayloadToTree = (
  payload: OrderIntakeMaterialOptionPayload[]
): OrderIntakeOptions => {
  const shipToGroups = groupBy(
    payload,
    (destinationOption: OrderIntakeMaterialOptionPayload) =>
      destinationOption.shippingAddress.siteNumber
  )
  return transform(shipToGroups, (result: OrderIntakeOptions, optionsForDestination) => {
    const {shippingAddress, contact} = optionsForDestination[0]
    const materials = groupBy(
      optionsForDestination,
      (option) => option.material.materialEnteredNumber
    )
    const canDisableEmailSending = optionsForDestination[0].canDisableEmailSending
    result[shippingAddress.siteNumber] = {
      shippingAddress,
      contact,
      materials,
      canDisableEmailSending
    }
  })
}

export const isSaturdayOrderingAvailable = (dateRange: DateRange): boolean => {
  if (isEmpty(dateRange.exceptions?.weekends)) return true
  if (typeof dateRange.exceptions?.weekends === 'undefined') return true
  return isEmpty(
    dateRange.exceptions?.weekends.filter((weekendDay) => {
      const weekendDayDate = new Date(weekendDay)
      return weekendDayDate.getUTCDay() === 6
    })
  )
}

export const isSundayOrderingAvailable = (dateRange: DateRange): boolean => {
  if (isEmpty(dateRange.exceptions?.weekends)) return true
  if (typeof dateRange.exceptions?.weekends === 'undefined') return true
  return isEmpty(
    dateRange.exceptions?.weekends?.filter((weekendDay) => {
      const weekendDayDate = new Date(weekendDay)
      return weekendDayDate.getUTCDay() === 0
    })
  )
}

export const isPublicHolidayOrderingAvailable = (dateRange: DateRange): boolean => {
  return isEmpty(dateRange.exceptions?.publicHolidays)
}

export const isCurrentTimeAfterCutOff = (
  timeStamp: string | undefined,
  timezone: string | undefined
): boolean => {
  if (!timeStamp) {
    return false
  }

  let currentTime: string
  let cutOffTime: string
  if (timezone !== undefined) {
    currentTime = moment().tz(timezone).format('YYYY-MM-DD HH:mm')
    cutOffTime = moment(timeStamp).tz(timezone).format('YYYY-MM-DD HH:mm')
  } else {
    currentTime = moment().format('YYYY-MM-DD HH:mm')
    cutOffTime = moment(timeStamp).format('YYYY-MM-DD HH:mm')
  }

  return moment(currentTime).diff(moment(cutOffTime)) >= 0
}

export const getTodaysCutOffTime = (businessDays: BusinessDay[]) => {
  const daysOfWeek = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']
  const today = getDayCode()
  let result = businessDays.filter((businessDay) => businessDay.dayOfWeek === today)

  // If result is empty, find the next available business day
  if (result.length === 0) {
    const sortedBusinessDays = [...businessDays].sort(
      (a, b) => daysOfWeek.indexOf(a.dayOfWeek) - daysOfWeek.indexOf(b.dayOfWeek)
    )

    let nextDayIndex =
      daysOfWeek.indexOf(today) === daysOfWeek.length - 1 ? 0 : daysOfWeek.indexOf(today) + 1
    let nextBusinessDay: BusinessDay | undefined
    let daysChecked = 0
    // Keep looking for the next available business day until it finds one
    while (!nextBusinessDay && daysChecked < daysOfWeek.length) {
      nextBusinessDay = sortedBusinessDays.find(
        (businessDay) => daysOfWeek.indexOf(businessDay.dayOfWeek) === nextDayIndex
      )
      nextDayIndex = (nextDayIndex + 1) % daysOfWeek.length
      daysChecked++
    }

    // If there's a business day later in the week, add it to result
    if (nextBusinessDay) {
      result = [nextBusinessDay]
    } else {
      // Otherwise, add the first business day of next week to result
      result = [sortedBusinessDays[0]]
    }
  }

  return result
}

export const availableDateRange = (
  dateRange: DateRange,
  timezone: string | undefined
): string[] => {
  const businessDays = dateRange?.businessDays ? dateRange?.businessDays : []
  const todaysCutOffTime = getTodaysCutOffTime(businessDays)[0].cutOffTime
  const cutOffTimeStamp = todaysCutOffTime?.timestamp

  const publicHolidayDates = dateRange.exceptions?.publicHolidays?.map(
    (publicHoliday) => publicHoliday.date
  )
  const weekends = dateRange.exceptions?.weekends
  const excludedDates = dateRange.exceptions?.excludedDates
  const cutOffDates = checkCutOffTimeDates(dateRange, timezone)
  const isAfterCutoffTime = isCurrentTimeAfterCutOff(cutOffTimeStamp, timezone)

  const exceptions = [
    ...(publicHolidayDates || []),
    ...(weekends || []),
    ...(excludedDates || []),
    ...(isAfterCutoffTime ? cutOffDates || [] : [])
  ]

  const dateRangeArray = getDates(new Date(dateRange.from), new Date(dateRange.to))
  const dateRangeArrayISODateStrings = dateRangeArray.map(
    (dateRangeArrayItem) => dateRangeArrayItem.toISOString().split('T')[0]
  )

  return dateRangeArrayISODateStrings.filter((item) => !exceptions.includes(item))
}

export const checkCutOffTimeDates = (dateRange?: DateRange, timezone?: string): string[] => {
  const businessDays = dateRange?.businessDays ? dateRange?.businessDays : []
  const todaysCutOffTime = getTodaysCutOffTime(businessDays)[0].cutOffTime
  const cutOffTimeStamp = todaysCutOffTime?.timestamp
  const cutOffDates = todaysCutOffTime?.dates

  if (cutOffDates && cutOffDates.length > 0) return cutOffDates

  if (dateRange && cutOffTimeStamp) {
    const dateRangeArray = getDates(new Date(dateRange.from), new Date(dateRange.to))
    const maximumLeadTime = getMaximalLeadTime(dateRange)
    const dates: string[] = [dateRange.from]

    dateRangeArray.forEach((day, index) => {
      if (index <= maximumLeadTime) {
        const availableDay = day.toISOString().split('T')[0]
        const dayCode = getDayCode(availableDay)
        const leadTime = getLeadTimeForDay(dayCode, dateRange)
        const dayDiff = getDifferenceFromToday(availableDay, timezone)

        if (dayDiff > 1 && dayDiff === leadTime) {
          dates.push(availableDay)
        }
      }
    })

    return dates
  }

  return []
}

export const getMaximalLeadTime = (dateRange: DateRange): number => {
  let maximumLeadTime = 0
  dateRange.businessDays?.forEach((day) => {
    if (day.leadTime > maximumLeadTime) maximumLeadTime = day.leadTime
  })

  return maximumLeadTime
}

export const getDayCode = (date?: string): string => {
  const day = date ? moment(date) : moment()
  const dayIndex = day.isoWeekday()
  const DAYS = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']

  return DAYS[dayIndex - 1]
}

export const getDifferenceFromToday = (date: string, timezone: string | undefined): number => {
  const today = timezone ? moment().tz(timezone) : moment()
  const day = moment.tz(date, timezone ?? '')

  return Math.abs(today.diff(day, 'days')) + 1
}

export const getLeadTimeForDay = (dayCode: string, dateRange: DateRange): number => {
  let resolvedLeadTime = 0

  dateRange.businessDays?.forEach((day) => {
    if (day.dayOfWeek === dayCode) resolvedLeadTime = day.leadTime
  })

  return resolvedLeadTime
}

export const isDateAvailable = (
  date: string,
  dateRange: DateRange,
  timezone: string | undefined
): boolean => {
  const availableDateRangeArray = availableDateRange(dateRange, timezone)
  return availableDateRangeArray.includes(date)
}

export const areDatesAvailable = (
  dateRange: DateRange,
  exceptions: Moment[],
  fullDays: Moment[]
) => {
  const areDatesInRangeAllExceptions = !getDates(
    new Date(dateRange.from),
    new Date(dateRange.to)
  ).every((day) => {
    return [...exceptions, ...fullDays]
      .map((date) => `${date.toDate().getDate()}-${date.toDate().getMonth()}`)
      .includes(`${day.getDate()}-${day.getMonth()}`)
  })

  return areDatesInRangeAllExceptions
}

export const earliestAvailableDate = (
  dateRange: DateRange,
  timezone: string | undefined
): string => {
  const availableDateRangeArray = availableDateRange(dateRange, timezone)
  const sortedAvailableDateRange = availableDateRangeArray.sort()
  return sortedAvailableDateRange[0]
}

export const getEarliestDeliveryDate = (
  initialDeliveryDate: string,
  dateRange: DateRange,
  timezone?: string
): {date: string; isDateChange: boolean} => {
  if (isDateAvailable(initialDeliveryDate, dateRange, timezone)) {
    return {
      date: initialDeliveryDate,
      isDateChange: false
    }
  }

  return {
    date: earliestAvailableDate(dateRange, timezone),
    isDateChange: true
  }
}

export const prepareDateRange = (
  dateRanges: DateRange[],
  businessHours: BusinessHours,
  materialsDict?: Dictionary<OrderIntakeMaterialOptionPayload[]>,
  slotsConfiguration?: SlotConfiguration[]
): DateRange => {
  const dateRange = mergeDateRanges(dateRanges)
  const allUnavailableDates: string[][] = []

  materialsDict &&
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(materialsDict).forEach(([materialEnteredNumber, materials]) => {
      materials
        .filter((material) => !material.invalid)
        ?.forEach((material) => {
          const unavailableDates = getUnavailableDaysFromTimeSlots(
            businessHours,
            slotsConfiguration,
            material.plant.configurableSlots
          )
          allUnavailableDates.push(unavailableDates)
        })
    })

  const unavailableDatesFromSlots = intersection(...allUnavailableDates)

  if (unavailableDatesFromSlots.length === 0) return dateRange

  return {
    ...dateRange,
    exceptions: {
      ...dateRange.exceptions,
      excludedDates: [...(dateRange.exceptions?.excludedDates ?? []), ...unavailableDatesFromSlots]
    }
  }
}

export const mergeDateRanges = (dateRanges: DateRange[]): DateRange => {
  const mergedDateRange: DateRange = dateRanges.reduce((acc, currValue) => {
    if (new Date(currValue.from) < new Date(acc.from)) {
      acc.from = currValue.from
    }
    if (new Date(currValue.to) > new Date(acc.to)) {
      acc.to = currValue.to
    }
    return {
      ...acc,
      exceptions: {
        ...acc.exceptions,
        weekends: [
          ...(acc?.exceptions?.weekends ?? []),
          ...(currValue?.exceptions?.weekends ?? [])
        ],
        publicHolidays: [
          ...(acc?.exceptions?.publicHolidays ?? []),
          ...(currValue?.exceptions?.publicHolidays ?? [])
        ]
      }
    }
  }, dateRanges[0])

  return {
    ...mergedDateRange,
    exceptions: {
      ...mergedDateRange.exceptions,
      weekends: uniq(mergedDateRange.exceptions?.weekends),
      publicHolidays: uniqBy(mergedDateRange.exceptions?.publicHolidays, 'date')
    }
  }
}

export const getUnavailableDaysFromTimeSlots = (
  businessHours: BusinessHours,
  slotsConfiguration?: SlotConfiguration[],
  slots?: ConfigurableTimeSlot[]
) => {
  const minInterval = businessHours?.minInterval ?? BUSINESS_HOURS_MIN_INTERVAL_DEFAULT

  if (slotsConfiguration && slots) {
    const slotsGroupedByDay = Object.values(groupBy(slots, (s) => s.date))

    const fullDays = slotsGroupedByDay
      .filter((slotsByDay) =>
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        slotsByDay.every((s) =>
          onlyFullSlots(
            businessHours.earliestPossible,
            businessHours.latestPossible,
            minInterval,
            slotsConfiguration,
            slotsByDay
          )
        )
      )
      .map((s) => s[0].date)

    return fullDays
  }

  return []
}

export const selectMaterialOption = (
  date: string,
  materialOptions?: OrderIntakeMaterialOptionPayload[],
  selectedMaterialOption?: OrderIntakeMaterialOptionPayload
): OrderIntakeMaterialOptionPayload | undefined => {
  return first(
    materialOptions
      ?.filter((materialOption) =>
        isDateAvailable(date, materialOption.dateRange, materialOption.businessHours.timeZone)
      )
      .filter((materialOption) => {
        if (selectedMaterialOption && !selectedMaterialOption.invalid) {
          if (
            materialOption.material.materialEnteredNumber !==
            selectedMaterialOption.material.materialEnteredNumber
          )
            return true
          return (
            materialOption.material.materialEnteredNumber ===
              selectedMaterialOption.material.materialEnteredNumber &&
            materialOption.plant.plantName === selectedMaterialOption.plant.plantName
          )
        }
        return true
      })
  )
}

export const getDefaultMaterialOptions = (
  materials: Dictionary<OrderIntakeMaterialOptionPayload[]>,
  orderPayload: OrderIntakePayload
) => {
  if (orderPayload?.plantName) {
    const materialByPlant = first(
      materials[orderPayload.materialEnteredNumber]?.filter((materialOption) => {
        return materialOption.plant.plantName === orderPayload.plantName
      })
    )

    if (materialByPlant) return materialByPlant
  }

  return first(materials[orderPayload.materialEnteredNumber])
}

export const isBlank = (input?: string) => trim(input).length === 0

export const groupMaterials = (
  orderRequests: OrderRequest[],
  selectedSite: OrderIntakeOption,
  options: OrderIntakeOptions,
  language: string,
  hideTime: boolean,
  dateTimeSeparator?: string
): GroupedMaterialDeliveries[] => {
  const groups: GroupedMaterialDeliveries[] = []

  orderRequests.forEach((orderRequest: OrderRequest, index: number) => {
    const id = `${orderRequest.payload.materialEnteredNumber}-${index}`
    const materialEnteredNumber = orderRequest.payload.materialEnteredNumber

    const validMaterialOptionsFromMaterialEnteredNumber: OrderIntakeMaterialOptionPayload[] =
      Object.values(selectedSite.materials[orderRequest.payload.materialEnteredNumber])
        .flatMap((item) => item)
        .filter((item) => !item.invalid)

    const materialOption = selectedSite
      ? options
        ? selectMaterialOption(
            orderRequest.payload.deliveryDate,
            validMaterialOptionsFromMaterialEnteredNumber
          )
        : null
      : null

    const description = materialOption?.material.materialDescription
    const capacityUom = materialOption?.truckCapacity.capacityUom
    const plant = orderRequest.payload.plantName.toUpperCase()

    let dateAndTime = dateFormatter(orderRequest.payload.deliveryDate, language)
    if (!hideTime)
      dateAndTime += ` ${dateTimeSeparator} ${
        orderRequest.payload.deliveryTime.earliest === orderRequest.payload.deliveryTime.latest
          ? timeFormatter(moment(orderRequest.payload.deliveryTime.earliest, 'HH:mm:ss'), language)
          : `${timeFormatter(
              moment(orderRequest.payload.deliveryTime.earliest, 'HH:mm:ss'),
              language
            )}-${timeFormatter(
              moment(orderRequest.payload.deliveryTime.latest, 'HH:mm:ss'),
              language
            )}`
      }`

    const quantity = orderRequest.payload.capacity.quantity

    const groupIndex = groups.findIndex(
      (item: GroupedMaterialDeliveries) => item.headline.number === materialEnteredNumber
    )
    if (groupIndex === -1) {
      const headline: MaterialDeliveryDescription = {
        number: materialEnteredNumber,
        quantity: quantity,
        capacityUom: capacityUom,
        description: description
      }

      const summaryItems: MaterialDelivery[] = [
        {id, index, dateAndTime, materialDescription: description, quantity, plant}
      ]
      groups.push({headline, summaryItems})
    } else {
      groups[groupIndex].headline.quantity += quantity

      groups[groupIndex].summaryItems.push({
        id,
        index,
        dateAndTime,
        materialDescription: description,
        quantity,
        plant
      })

      groups[groupIndex].summaryItems = sortBy(
        groups[groupIndex].summaryItems,
        (item) => item.dateAndTime
      )
    }
  })

  return sortBy(groups, (item) => item.headline.description)
}

export const groupPlacedDeliveries = (
  orderRequests: OrderRequest[],
  selectedSite: OrderIntakeOption,
  groupOrderSummaryDataArray?: OrderRequestGroupResponse[]
): GroupedPlacedDeliveries[] => {
  const groups: GroupedPlacedDeliveries[] = []

  orderRequests.forEach((orderRequest: OrderRequest) => {
    const materialEnteredNumber = orderRequest.payload.materialEnteredNumber
    const description = orderRequest.payload.materialDescription
    const quantity = orderRequest.payload.capacity.quantity

    const groupIndex = groups.findIndex(
      (item: GroupedPlacedDeliveries) => item.headline.number === materialEnteredNumber
    )

    if (groupIndex === -1) {
      const validMaterialOptionsFromMaterialEnteredNumber: OrderIntakeMaterialOptionPayload[] =
        Object.values(selectedSite.materials[orderRequest.payload.materialEnteredNumber])
          .flatMap((item) => item)
          .filter((item) => !item.invalid)

      const materialOption = selectedSite
        ? selectMaterialOption(
            orderRequest.payload.deliveryDate,
            validMaterialOptionsFromMaterialEnteredNumber
          )
        : null

      const capacityUom = materialOption?.truckCapacity.capacityUom

      const headline: MaterialDeliveryDescription = {
        number: materialEnteredNumber,
        quantity: quantity,
        capacityUom: capacityUom,
        description: description
      }

      const orders: OrderIntakePayload[] = [orderRequest.payload]

      const groupedOrderSummary =
        groupOrderSummaryDataArray && groupOrderSummaryDataArray.length > 0
          ? groupOrderSummaryDataArray.filter((item) => {
              if ('requestData' in item.result && isTypeof(item.result, 'OrderRequestResponse')) {
                return (
                  item.result.requestData.material.materialEnteredNumber === materialEnteredNumber
                )
              } else {
                return item.delivery.payload.materialEnteredNumber === materialEnteredNumber
              }
            })
          : []

      groups.push({headline, orders, groupedOrderSummary})
    } else {
      groups[groupIndex].headline.quantity += quantity

      groups[groupIndex].orders.push(orderRequest.payload)

      groups[groupIndex].orders = sortBy(groups[groupIndex].orders, (item) => {
        return item.deliveryDate
      })
    }
  })

  return sortBy(groups, (item) => item.headline.description)
}

export const isValidCustomerReference = (inputValue: string | undefined): boolean => {
  if (!inputValue) return false

  const validInputPattern = /["\n?]/g
  const found = inputValue.match(validInputPattern)
  if (found && found.length > 0) return false

  return true
}

export const getCutOffDate = (dateRange: DateRange, timeZone: string | undefined) => {
  return earliestAvailableDate(dateRange, timeZone)
}

export const areDatesSame = (
  date1: string,
  date2: string,
  timezone: string | undefined
): boolean => {
  return (
    moment.tz(date1, timezone ?? '').format('YYYY-MM-DD') ===
    moment.tz(date2, timezone ?? '').format('YYYY-MM-DD')
  )
}

export const getMaterialFormStyleByStatus = (
  status: MaterialFormStatus,
  isSuccess: boolean,
  classes: ReturnType<typeof useOrderIntakeStyles>
) => {
  if (isSuccess) return classes.submittedMaterialForm
  if (MaterialFormStatus.ACTIVE === status) return classes.activeMaterialForm
  if (MaterialFormStatus.DISABLED === status) return classes.disabledMaterialForm
  return ''
}

export const getMaterialTableRowStyleByStatus = (
  status: MaterialFormStatus,
  isSuccess: boolean,
  classes: ReturnType<typeof useOrderIntakeStyles>
) => {
  if (isSuccess) return classes.submittedMaterialForm
  if (MaterialFormStatus.ACTIVE === status) return classes.activeMaterialRow
  if (MaterialFormStatus.DISABLED === status) return classes.disabledMaterialRow
  return classes.materialRow
}

export const checkEmptyTimeZone = (timeZone: string | undefined): string => {
  if (timeZone) return timeZone
  return moment.tz.guess()
}

export const getCurrentTimeStamp = (timeZone: string): string => {
  return moment().tz(timeZone).format('YYYY-MM-DD HH:mm')
}

export const generateGuid = () => {
  return uuidv4()
}

export const isBusinessDay = (dayOfWeek: string, businessDays: OrderCancellationBusinessDay[]) =>
  businessDays.some((businessDay) => businessDay.dayOfWeek === dayOfWeek)

export const subtractBusinessDays = (
  date: moment.Moment,
  numberOfLeadDays: number | undefined,
  businessDays: OrderCancellationBusinessDay[] | undefined
) => {
  if (numberOfLeadDays && numberOfLeadDays < 0) {
    throw new Error('Number of days must be a non-negative number.')
  }
  if (businessDays && businessDays.length === 0) {
    throw new Error('Business days array must be defined and not empty.')
  }
  let daysSubtracted = 0
  while (businessDays && daysSubtracted < (numberOfLeadDays ?? 0)) {
    date = date.subtract(1, 'days')
    const dateDayCode = getDayCode(date.toISOString().split('T')[0])
    if (isBusinessDay(dateDayCode, businessDays)) {
      daysSubtracted++
    }
  }
  return date
}
