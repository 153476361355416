import {
  OrderIntakeActionTypes,
  RESET_ORDER_INTAKE,
  SET_COLLECTIONS,
  SET_DELIVERIES,
  SET_ORDER_SUMMARY,
  TOGGLE_RESET_ORDER_INTAKE
} from '../../OrderIntake/declarations/Action.types'
import {OrderRequest, OrderRequestResponse} from '../../OrderIntake/declarations/types'

export const ORDER_REQUESTS_API_PATH = 'OrderRequests'
export const REQUEST_FOR_QUOTES_API_PATH = 'RequestForQuotes'
export const ORDERS_MANAGEMENT_API_PATH = 'ordermanagement'

export const resetOrderIntake = (): OrderIntakeActionTypes => ({
  type: RESET_ORDER_INTAKE
})

export const toggleResetOrderIntake = (): OrderIntakeActionTypes => ({
  type: TOGGLE_RESET_ORDER_INTAKE
})

export const setDeliveries = (payload: OrderRequest[]): OrderIntakeActionTypes => ({
  type: SET_DELIVERIES,
  payload
})

export const setCollections = (payload: OrderRequest[]): OrderIntakeActionTypes => ({
  type: SET_COLLECTIONS,
  payload
})

export const setOrderSummary = (payload: OrderRequestResponse): OrderIntakeActionTypes => ({
  type: SET_ORDER_SUMMARY,
  payload
})
