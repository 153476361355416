import {Iso4217} from '@hconnect/common/types'

import {InvoiceCategories, LineItemTypes} from '../../Pages/Finance/Invoices/Invoice.types'
import {CreditCardRate} from '../../Pages/Finance/PayNow/PayNow.types'

export interface Payment {
  currency: string
  paymentId: string
  paymentNumber: string
  amount: number
  postingDate: Date | string
  remark: string
  companyCode: string
}

// filters
export interface DateFilterType {
  startDate: any
  endDate: any
}
// Add more interfaces here if it's needed
export type PaymentsFilterType = DateFilterType

export interface FetchPaymentsCustomFilter extends DateFilterType {
  sortedBy: string
  payerId: string
}

export interface PaymentOption {
  payerId: string
  creditCards: DelegoCreditCard[]
  creditCardRates?: CreditCardRate[]
  companies: Company[]
  options: Options
}

export interface Options {
  singlePaymentMaxAmount: number
  clearingLimit: number
}

export interface Company {
  companyCode: string
  companyCity?: string
  defaultCurrency: Iso4217
  companyName: string
  countryCode: string
}

export interface DelegoCreditCard {
  holderName: string
  token: string
  cardType: string
  expiryDate: string
  startDate?: string
  isDefault: boolean
}

export interface AuthorizationResponse {
  requestedAmount: EdAmount
  authorizedAmount: EdAmount
  preAuth: boolean
  payment: PaymentResponse
  address: Address
  recurring: null
  authorized: boolean
  referenceNumber: string
  authorizationNumber: string
  transactionStatus: string
  responseDescription: string
  responseCode: string
  authorizationDate: number
  response: Response
}

export interface Address {
  firstName: string
  lastName: string
  street: string
  city: string
  region: string
  country: string
  postalCode: string
  phoneNumber: string
  emailAddress: string
}

export interface EdAmount {
  value: number
  currency: string
}

export interface PaymentResponse {
  cardType?: string
  cardholderName: string
  cardExpiration: CardExpiration
  cardIssue?: null
  cardIssueNumber?: null
  token?: string
  singleUseToken?: boolean
  fromWallet?: boolean
  source?: null
}

export interface CardExpiration {
  year: number
  month: number
}

export interface Response {
  autra: string
  zzrcode: string
  rtext: string
  zz3Dschal: boolean
  zzrrnum: string
  rccvv: string
  react: string
  rcrsp: string
  zztnum: string
  zzCurrentGUID: string
  fpltr: string | number
  zzldate: string
  zzOrigGUID: string
  zzltime: string
  autwr: number
  zzvcode: string
  rcavr: string
  aunum: string
  vbeln: string
  zzraci: string
}

export interface DelegoPaymentRequestType {
  authorizationDate: number
  delegoAuthorization: ApiPaymentAuthorization
  currency: string
  companyCode: string
  creditCardInput: CreditCardInput & {countryCode: string}
  isInternalUser: boolean
  userEmail: string
  userName: string
  feedbackEmail: string
  analytics: {analyticsId: string}
  locale?: string
  surchargeAmount?: number
  customerReference?: string
}

export interface BofAPaymentRequestType {
  currency: string
  companyCode: string
  isInternalUser: boolean
  userEmail: string
  userName: string
  feedbackEmail: string
  analytics: {analyticsId: string}
  bofAAuthorization: BofAAuthorization
  locale?: string
  countryCode: string
  payerId: string
  selectedInvoicesWithAmount: InvoiceToPay[]
  market?: string | null
}

export interface CreditCardInput {
  holderName: string
  token: string
  cardType: string
  expiryDate: string
  payerId: string
}

export interface ApiPaymentAuthorization {
  arAmount: number
  arRequestedAmount: number
  aunum: string
  autra: string
  fpltr: string | number
  rcrsp: string
  rtext: string
  vbeln: string
  zzldate: string
  zzltime: string
  zzraci: string
  zzrrnum: string
  zztnum: string
  zzvcode: string
}

export interface BofAAuthorization {
  token: string
  digiSign: string
  customerAccountReference: string
  customerReference: string
  amount: number
  date: string
}

export interface InvoiceToPay {
  invoiceId: string
  amount: number
  invoiceCreationDate: Date | string
  invoiceType: InvoiceCategories
  businessLine: string
  orgUnitId: string
  invoiceLineItemNumbers: InvoiceLineItemToPay[]
}

export interface InvoiceLineItemToPay {
  itemNumber: string
  amount: number
  lineItemTypes: LineItemTypes[]
}
